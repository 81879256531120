import React, {useState, useRef, useEffect} from 'react';
import styles from './ChatView.module.css';
import {userProfile} from '../../../../shared/models/UserProfile';
import Message from './Message.react';
import {MESSAGE_STATUSES, shouldShowDateHeader, formatDateHeader, injectAdditionalMessageInfo, createPendingXID} from '../../../../shared/utils/MessagesHelpers';
import Header from '../Header';
import {useHistory} from 'react-router-dom';
import SettingsIcon from '../svgs/SettingsIcon';
import MessageComposer from './MessageComposer.react';
import chatManager from '../../../../shared/models/ChatManager';
import { rwbApi } from '../../../../shared/apis/api';
import Loading from '../Loading';
import errorAlert from '../../../../shared/utils/ErrorAlert';
import { formatReportReason } from '../../../../shared/utils/MessagesHelpers';
import useMessages from '../../../../shared/hooks/useMessages';
import useMessageEditing from '../../../../shared/hooks/useMessageEdititing';
import ReportChatModal from './ReportChatModal.react';
import useMessageReplying from '../../../../shared/hooks/useMessageReplying';
import { blockUserWithAlert } from '../../../../shared/utils/BlockUserWithAlert';
import { logChatMessageSent, logChatConversationOpen, EXECUTION_STATUS, webSectionName, logChatTaggedUser } from '../../../../shared/models/Analytics';
import { messageContainsUserTags } from '../../../../shared/utils/Helpers';

const ChatView = ({}) => {
  const chatContainerRef = useRef(null);
  const curUser = userProfile.getUserProfile();
  const history = useHistory();
  const channelId = parseInt(window.location.href.split('/').pop());

  const [loadingInitialMessages, setLoadingInitialMessages] = useState(true);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);

  const {
    currentMessages,
    setCurrentMessages,
    seekedMessages,
    setSeekedMessages,
    nextPage,
    setNextPage,
    previousPage,
    setPreviousPage,
    loadOlderMessages,
    loadNewerMessages,
    mergeAndSetMessages,
    seekReply,
    viewingSeekedMessages,
  } = useMessages(channelId, setLoadingMoreMessages, curUser);

  const [isGroup, setIsGroup] = useState(false);
  const [displayName, setDisplayName] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showConfirmReportModal, setShowConfirmReportModal] = useState(false);
  const [reportMessageIndex, setReportMessageIndex] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const {editText, editing, startEditing, stopEditing, neededBrokerInfo} = useMessageEditing();
  const { replying, startReplying, stopReplying, replyingInfo, replyingToIndex } = useMessageReplying();

  const taggingMembers = chatManager?.chatChannels?.[channelId]?.taggableMembers;

  const rightHeaderButton = () => {
    return (
      <div style={{display: 'flex', alignItems: 'center'}} onClick={() => history.push(`${history.location.pathname}/settings`)}>
        <SettingsIcon width={25} height={25} tintColor={'var(--white)'} />
      </div>
    );
  };

  const scrollToBottom = (behavior = 'smooth') => {
    if (viewingSeekedMessages) return;
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      if ((scrollTop + clientHeight >= scrollHeight - 110) || behavior === 'instant') {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: behavior,
        });
      }
    }
  };

  const onMessageSent = async (data) => {
    if (replyingInfo?.xid || replyingInfo?.id) {
      data.replyingInfo = replyingInfo;
    }
    // Generate a temporary id for the pending message
    if (!data.xid) {
      data.xid = createPendingXID();
    }
    // Copy and mark media messages as pending and shows "Uploading" text while waiting for onMesssageReceived.
    // Copy is needed so display "Uploading" text while pending, but not send that text.
    const cloneData = { ...data, text: data.images.length > 1 ? "Uploading images" : "Uploading image" , status: MESSAGE_STATUSES.pending };
    if (data.images.length > 0) {
      // Puts pending media messages into UI
      setCurrentMessages(prevMessages => [...prevMessages, cloneData]);
    } 
    // Mark the message as pending
    data.status = MESSAGE_STATUSES.pending;
    if (data.text) {
      // Puts pending text messages into UI
      setCurrentMessages(prevMessages => [...prevMessages, data]);
    }
    if (replying) stopReplying();

    const messageResponseType = await chatManager.sendMessage(channelId, data);
    data.status = messageResponseType;
    if (messageResponseType === MESSAGE_STATUSES.failure) {
      setCurrentMessages([...currentMessages, data]);
    }
    
    const executionStatus = messageResponseType === MESSAGE_STATUSES.failure 
        ? EXECUTION_STATUS.failure 
        : EXECUTION_STATUS.success;

    logChatMessageSent({
      section_name: webSectionName(),
      chat_channel_id: channelId,
      number_of_participants: chatManager?.chatChannels?.[channelId]?.members?.length,
      execution_status: executionStatus,
    });

    if (messageContainsUserTags(data.text)) {
      logChatTaggedUser({
        section_name: webSectionName(),
        chat_channel_id: channelId,
        execution_status: executionStatus,
      });
    }

  };

  const handleEditMessage = async (index) => {
    const messageBeingEdited = currentMessages[index];
    // full message for all information
    startEditing(messageBeingEdited);
  };
  
  const onEditedMessageSent = async (newMessage) => {
    //TODO: Pass previous reply info to the news message.
    newMessage.editInfo = {
      ...neededBrokerInfo,
      edited: true
    };
    // Mark the existing message as pending
    setCurrentMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.xid === newMessage.editInfo.messageId || msg.id === newMessage.editInfo.messageId
          ? { ...msg, status: MESSAGE_STATUSES.pending }
          : msg
      )
    );
    stopEditing(); 
    const messageResponseType = await chatManager.editMessage(channelId, newMessage);
    newMessage.status = messageResponseType;
    setCurrentMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.xid === newMessage.editInfo.messageId || msg.id === newMessage.editInfo.messageId
          ? { ...msg, ...newMessage, status: messageResponseType }
          : msg
      )
    );
  }

  const handleReplyToMessage = (index) => {
    const messageToReplyTo = currentMessages[index];
    startReplying({
      ...messageToReplyTo,
      index,
    });
  }

  const onRepliedMessageSent = async (message) => {

  }

  const onRetryMessageSent = async (data) => {
    const isEdit = !!data.editInfo?.messageId;
    // Mark the message as pending again on retry
    data.status = MESSAGE_STATUSES.pending;
    setCurrentMessages(prevMessages =>
      prevMessages.map(msg => (msg.xid === data.xid ? { ...msg, status: MESSAGE_STATUSES.pending } : msg))
    );
    let messageResponseType;
    if (isEdit) {
      messageResponseType = await chatManager.editMessage(channelId, data);
    } else {
      messageResponseType = await chatManager.sendMessage(channelId, data);
    }
    
    if (messageResponseType !== MESSAGE_STATUSES.failure) {
      setCurrentMessages((prevMessages) => {
        if (isEdit) {
          // Replace the failed edited message with the successful one
          return prevMessages.map((msg) =>
            msg.xid === data.editInfo.messageId || msg.id === data.editInfo.messageId
              ? { ...msg, ...data, status: MESSAGE_STATUSES.success }
              : msg
          );
        } else {
          // Remove the failed new message if the retry was successful
          return prevMessages.filter((msg) => msg !== data);
        }
      });
    } else {
      console.error("Message retry failed");
      setCurrentMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.xid === data.xid ? { ...msg, status: MESSAGE_STATUSES.failure } : msg
        )
      );
    }
  }
  
  const onMessageReceived = async (data) => {
    if (data.channelId !== channelId) return;
    let newMessage = data.message;
    if (userProfile?.blockedMap?.has(newMessage?.sender?.id)) newMessage.blocked = true;
    if (newMessage?.editInfo?.edited || newMessage?.emojiInfo) {
      let hasMessageToUpdate = false;
      const updatedMessages = currentMessages.map((message) => {
        if (message.xid === newMessage.previousMessageId || message.id === newMessage.previousMessageId) {
          hasMessageToUpdate = true;
          return { ...message,
            edited: newMessage?.editInfo?.edited,
            text: newMessage.text,
            open_graph: newMessage.open_graph,
            emojiInfo: newMessage.emojiInfo,
          };
        }
        return message;
      });
      if (hasMessageToUpdate) {
        setCurrentMessages(updatedMessages);
      }
    } else if (newMessage.sender.id === curUser.id && newMessage.attributes.localId) {
      const localId = newMessage.attributes.localId;
      // finds index and matches the localId
      const index = newMessage.images.length > 0 ? currentMessages.findIndex((m) => `image${m.xid}` === localId) : currentMessages.findIndex((m) => m.xid === localId);
      if (index > -1) {
        // Replace the pending message with the official one
        currentMessages[index] = { ...newMessage, status: MESSAGE_STATUSES.success, xid: newMessage.id };
        setCurrentMessages(currentMessages);
      }
    } else {
      setCurrentMessages([...currentMessages, newMessage]);
    }
    scrollToBottom();
  }

  const onMessageDeleted = async (data) => {
    if (data.channelId !== channelId) return;
    const updatedMessages = currentMessages.filter((message) => {
      return !(message?.xid === data.id || message.id === data.id)
    }
    );
    if (updatedMessages.length !== currentMessages.length)
      setCurrentMessages(updatedMessages);
  }

  const handleDeleteMessage = async (index) => {
    const message = currentMessages[index];
    const ivsMessageId = message?.xid || message.id;
    await chatManager.deleteMessage(channelId, ivsMessageId);
  }

  const handleReportMessage = async (index) => {
    setReportMessageIndex(index);
    setShowReportModal(true);
  }

  const handleBlockUser = async (index) => {
    const message = currentMessages[index];
    if (await blockUserWithAlert(message.sender)) {
      if (!isGroup) {
        try {
          await chatManager.leaveRoom(channelId);
          history.push('/messages');
        } catch (error) {
          console.error("Error leaving chat:", error);
          errorAlert("Failed to leave the chat. Please try again.");
        }
      } else {
        let copiedMessages = [...currentMessages].map((mapMessage) => {
          if (mapMessage.sender.id === message.sender.id) mapMessage.blocked = true;
          return mapMessage
        });
        setCurrentMessages(copiedMessages);
      }
    }
  }

  const handleReportSubmit = async (reportReason,reportDetails) => {
    if (!reportReason) return;
        try {   
          const formattedReason = formatReportReason(reportReason);
          const message = currentMessages[reportMessageIndex];
          if(!message || !message.id) return;
          // our db || ivs
          const id = message?.xid || message.id
          await chatManager.reportMessage(id,formattedReason,reportDetails);
      
          setReportMessageIndex(undefined);
          setShowConfirmReportModal(false);
        } catch (error) {
            console.error("Error submitting report:", error);
            errorAlert("Failed to submit the report. Please try again.");
        }
  }

  /**
  Jump to the message at the given index. Handles the scrolling and
  highlighting animations.
  */
  const jumpToMessageAtIndex = (idx) => {
      const elem = chatContainerRef.current.children[idx];
      elem.scrollIntoView({behavior: 'smooth', block: 'center'});
      elem.classList.add(styles.seeked);
      setTimeout(() => elem.classList.remove(styles.seeked), 3000);
  }

  const onSeekReply = async (replyData) => {
    const {messageIdx} = await seekReply(replyData, setLoadingInitialMessages);
    jumpToMessageAtIndex(messageIdx);
  }

  const onTopReached = async () => {
    setLoadingMoreMessages(true);
    try {
      const initialHeight = chatContainerRef.current.scrollHeight;
      await loadOlderMessages();
      requestAnimationFrame(() => {
        // Adjust scrollTop so that the view stays at the same position
        const newHeight = chatContainerRef.current.scrollHeight;
        chatContainerRef.current.scrollTop = newHeight - initialHeight;
        setLoadingMoreMessages(false);
      });
    }
    catch(error) {
      console.warn('top reached error: ', error)
      errorAlert('Unable to load more messages.');
      setLoadingMoreMessages(false);
    }
  }

  const onBottomReached = async () => {
    setLoadingMoreMessages(true);
    try {
      await loadNewerMessages();
    }
    catch(error) {
      errorAlert('Unable to load more messages.');
    }
    finally {
      setLoadingMoreMessages(false);
    }
  }

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (container.scrollTop === 0 && (nextPage !== null) && !loadingMoreMessages && !loadingInitialMessages) {
        onTopReached();
      }

      // See: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
      const scrolledToBottom = Math.abs(container.scrollHeight - container.clientHeight - container.scrollTop) <= 1;
      if (scrolledToBottom && viewingSeekedMessages && (previousPage !== null) && !loadingMoreMessages && !loadingInitialMessages) {
        onBottomReached();
      }
    };

    container.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => container.removeEventListener('scroll', handleScroll);
  }, [loadingInitialMessages, loadingMoreMessages, previousPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const channel = (await chatManager.getChatChannels())[channelId];
        setIsGroup(channel.group_chat);
        if (!channel.displayName) {
          const chatDisplayName = await chatManager.getChannelDisplayName(channelId);
          setDisplayName(chatDisplayName);
        } else {
          setDisplayName(channel.displayName);
        }
        const response = await rwbApi.getChatMessagesFromDB(channelId);
        if (!response?.results) return;
        const messagesWithFullInfo = injectAdditionalMessageInfo(response.results, channelId, curUser);
        setCurrentMessages(messagesWithFullInfo.reverse());
        setNextPage(response.next ?? null);
        scrollToBottom('instant');
        const lastXid = response.results[0]?.xid;
        chatManager.markAsRead(channelId, lastXid);

        logChatConversationOpen({
          chat_id: channelId,
          previous_view: history.location.state?.previous_view,
          section_name: webSectionName(),
        });
      } catch (error) {
        console.error('Error fetching chat data:', error);
      } finally {
        setLoadingInitialMessages(false);
      }
    };
  
    fetchData();
  }, [channelId]);

  useEffect(() => {
    chatManager.setGlobalMessageHandler(onMessageReceived);
    chatManager.setGlobaMessageDeleteHandler(onMessageDeleted);
  }, [currentMessages]);

  useEffect(() => {
    const getStatus = async () => {
      setIsInactive((await chatManager.getChatChannels())[channelId]?.is_disabled);
    }
    getStatus();
  });

  const messages = viewingSeekedMessages ? seekedMessages : currentMessages;

  return (
    <div className={styles.container}>
      <Loading size={100} color={'var(--white)'} right={true} loading={loadingInitialMessages}/>
      <Header
        title={displayName}
        onBack={history.goBack}
        rightButton={rightHeaderButton}
        truncateTitle={true}
      />
      {loadingMoreMessages && <Loading size={60} footerLoading color={'var(--grey20)'} right={true} loading={loadingMoreMessages}/>}
      <div className={styles.messagesContainer}
        ref={chatContainerRef}>
        {messages.map((message, index) => {
          return (
            <div key={message.xid}>
              {shouldShowDateHeader(messages, index) && (
                <div className={styles.dateHeader}>
                  {formatDateHeader(message.created)}
                </div>
              )}

              <Message
                key={message.xid} data={message} curUser={curUser}
                onReport={()=> handleReportMessage(index)}
                onBlock={()=> handleBlockUser(index)}
                onEdit={()=> handleEditMessage(index)}
                onDelete={() => handleDeleteMessage(index)}
                isReplyingTo={index === replyingToIndex}
                onReply={() => handleReplyToMessage(index)}
                onSeekReply={onSeekReply}
                onRetry={onRetryMessageSent}
                chatMembers={taggingMembers}
                isInactive={isInactive}
                channelId={channelId}
              />
            </div>
          );
        })}

      </div>
      {isInactive ?
        <div className={styles.inactiveChat}>
          <p>This chat has been deactivated.</p>
        </div>
        : 
        <MessageComposer
          onMessageSent={onMessageSent}
          curUser={curUser} 
          editing={editing}
          editText={editText}
          onEditedMessageSent={onEditedMessageSent}
          stopEditingAndReplying={() => {
            stopEditing();
            stopReplying();
          }}
          replying={replying}
          onRepliedMessageSent={onRepliedMessageSent}
          replyingToName={`${replyingInfo?.sender?.first_name} ${replyingInfo?.sender?.last_name}`}
          chatMembers={taggingMembers}
        />
      }
      <ReportChatModal
          showReportModal={showReportModal}
          setShowReportModal={setShowReportModal}
          showConfirmReportModal={showConfirmReportModal}
          setShowConfirmReportModal={setShowConfirmReportModal}
          handleReportSubmit={handleReportSubmit}
          messageIndex={reportMessageIndex}
      />
    </div>
    
  );
};

export default ChatView;
