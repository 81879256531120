const CHAT_STATES = {
    active: 'actv',
    archived: 'arch',
    mute: 'mute',
    left: 'left',
    removed: 'rmvd',
}

const CHAT_ROLES = {
    member: 'mmbr',
    leader: 'lead',
};

const CREATE_CHANNEL_MESSAGES = {
    new: 'New channel created.',
    exists: 'Existing channel found, all members set to ACTIVE.',
}

const REACTION_EMOJIS = ['👊','👍','👀','🔥','🎉']

export {
    CHAT_STATES,
    CHAT_ROLES,
    CREATE_CHANNEL_MESSAGES,
    REACTION_EMOJIS,
}
