import * as React from "react"

function FollowIcon(props) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 14v-3h-3V9h3V6h2v3h3v2h-3v3h-2zm-9-2c-1.1 0-2.042-.392-2.825-1.175C5.892 10.042 5.5 9.1 5.5 8s.392-2.042 1.175-2.825C7.458 4.392 8.4 4 9.5 4s2.042.392 2.825 1.175C13.108 5.958 13.5 6.9 13.5 8s-.392 2.042-1.175 2.825C11.542 11.608 10.6 12 9.5 12zm-8 8v-2.8c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 013.15-1.163A13.76 13.76 0 019.5 13c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.476.438.996.438 1.563V20h-16zm2-2h12v-.8a.973.973 0 00-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 00-5.55 0C5.808 15.563 4.9 15.9 4 16.35a.973.973 0 00-.5.85v.8zm6-8c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.02-.588-1.412A1.926 1.926 0 009.5 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 007.5 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z"
        fill="#041E42"
      />
    </svg>
  )
}

export default FollowIcon
