import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Backdrop, Fade} from '@material-ui/core';

import styles from './RWBModal.module.css';

import XIcon from '../svgs/XIcon';
import RWBButton from '../RWBButton';

/**
 * RWBModal - A generic, styled modal container for pop-over components.
 * This component contains no state of its own.
 * 
 * This component replaces src/components/events/CustomModal.js, which is
 * over-specialized into displaying lists of items, as opposed to arbitrary
 * child component trees.
 *
 * @component 
 * @example
 * <RWBModal open={showModal} closeModal={() => setShowModal(false)}>
 *   <SomeChildComponent />
 * </RWBModal>
 *
 * @param {Object} props - Props passed to the component.
 * @param {boolean} props.open - Whether or not the modal is open and displaying.
 * @param {function} props.onClose - A callback that the component calls when closing.
 * @param {function} props.closeModal - A callback called in the close-button's onClick
 *   handler. Should manage the parent component's state and set props.open to false.
 * @param {boolean} props.keepMounted - Whether or not to keep the children mounted.
 *   Good for when the children make expensive network calls to populate themselves,
 *   or have some internal state to maintain.
 * @param {boolean} props.hasCancelButton - Whether or not the x icon is displayed or the cancel button is displayed.
 * @param {string} props.topAction - Passes through whatever the top action label is.
 * @param {boolean} props.bottomSheet - Whether or not the modal acts like a bottom actionsheet on at mobile breakpoint.
 */
const RWBModal = (props) => {
  return (
    <Modal
      className={styles.modal}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      keepMounted={props.keepMounted}
    >
      <Fade in={props.open}>
        <div className={`${styles.paper} ${props.bottomSheet ? styles.bottomSheet : ""}`} style={props.customStyles}>
          <div className={styles.topActionContainer}>
            {!props.hasCancelButton &&
              <div className={styles.xIconContainer} onClick={props.closeModal}>
                <XIcon width={24} height={24} tintColor={'var(--magenta)'} />
              </div>
            }
            {props.topAction &&
              <div className={styles.topAction} >
                {props.topAction}
              </div>
            }
          </div>
          {props.children}
          {props.hasCancelButton && 
            <RWBButton
              label={'Cancel'}
              buttonStyle={'secondary'}
              customStyles={{marginBottom: 0}}
              onClick={props.closeModal}
            />
          }
        </div>
      </Fade>
    </Modal>
  )
}

RWBModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  keepMounted: PropTypes.bool,
  hasCancelButton: PropTypes.bool,
  topAction: PropTypes.string,
  bottomSheet: PropTypes.bool,
}

export default RWBModal;
