import React, {useState, useCallback, useEffect, useRef} from 'react';
import debounce from 'lodash.debounce';
import {rwbApi} from '../apis/api';

const DEBOUNCE_MS = 500;
const useSearchMessages = ({} = {}) => {;
  const [searchInput, setSearchInput] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [names, setNames] = useState([]);

  const debounceRef = useRef();

  const searchForMessage = async (text) => {
    const searchedMessages = await rwbApi.searchMessages(text);
    return searchedMessages;
  }

  const searchForUsers = async (text) => {
    const searchedUsers = await rwbApi.searchChannels(text);
    return searchedUsers;
  }

  const apiSearch = useCallback(async (text) => {
    if (text?.length < 3) {
        // TODO: Improve the loading conditions
        setIsSearchLoading(false);
        if (text.length > 0) setIsSearchLoading(true);
        setMessages([]);
        setNames([]);
        return;
    }
    setIsSearchLoading(true);
    Promise.all([
        searchForMessage(text), searchForUsers(text)
    ]).then(([textMessages, nameMessages]) => {
        setMessages(textMessages);
        setNames(nameMessages);
    }).catch((err) =>{ 
        console.warn('error: ', err);
        // TODO: Error handling? Set empty results with a placeholder?
    })
    .finally(() => {
        setIsSearchLoading(false);
    })
  }, []);

  const debouncedSearch = useCallback(
        debounce((value) => apiSearch(value), DEBOUNCE_MS),
    [],
  );

  useEffect(() => {
    debounceRef.current = debouncedSearch;
    return () => debounceRef.current.cancel();
  }, [debouncedSearch]);

  const searchValueChange = (text) => {
    setSearchInput(text);
    debounceRef.current(text);
  };

  return {
    searchInput,
    searchValueChange,
    messages,
    names,
    isSearchLoading,
  };
};

export default useSearchMessages;
