'use strict';

export const HEADER = `LIABILITY WAIVER & ACKNOWLEDGEMENT`;
export const SUBHEADER = `(THIS DOCUMENT AFFECTS YOUR LEGAL RIGHTS. YOU MUST READ AND UNDERSTAND BEFORE SUBMITTING)`;
export const INTRODUCTION = `This Liability Waiver & Acknowledgement (this “Liability Waiver & Acknowledgement”) governs your participation in Team RWB Activities.`;

export const INCLUSIVITY = `Team Red, White & Blue, Inc., a Michigan nonprofit corporation (“Team RWB”), is an inclusive organization, and attempts to deliver varying events and activities, both at the local and national level, that accommodate all physical abilities and fitness levels. It is the responsibility of individual participants to work with appropriate medical professionals to determine which activities and events best align with their abilities and fitness levels.`;

export const MEDICAL = `\nI certify that I am medically and physically capable to participate in Team RWB events, including any and all associated training and development activities during Team RWB Physical Fitness Events, Team RWB Expeditions and/or Team RWB Social and Community Service Events (collectively, “Team RWB Activities”), and have taken into consideration any potential limitations including those communicated by medical personnel. My medical and/or physical condition will not endanger myself or others if I participate in Team RWB Activities. As stated below, participation in Team RWB Activities presents an inherent risk of injury to person and property. I certify that, to the best of my knowledge, I will take into consideration any known medical or physical conditions before agreeing to participate in Team RWB activities. Additionally, I certify that I have primary medical insurance in place to cover any expenses related to any potential injury that may arise from my participation in Team RWB Activities, and, if applicable, primary medical insurance to cover for any potential injury that may arise for any of my children’s or ward’s participation in the Team RWB Activities. I acknowledge that Team RWB is relying on these statements to allow me to participate in any Team RWB Activity. I acknowledge that Team RWB Activities [frequently] take place on premises that are not owned or controlled by Team RWB, including, but not limited to, public parks, public recreational spaces, private businesses and public streets and sidewalks (any such locations, the “Premises”). I acknowledge that Team RWB is not responsible for the condition of any of the Premises. The only Team RWB Activities are provided on the Team RWB application (the “App”). Any other events with members or volunteers of Team RWB that are not explicitly provided for in the App are not sanctioned or approved by Team RWB.`;

export const INDEMNIFICATION = `\nIn consideration of the services provided by Team RWB or any of its affiliates and the right to engage in Team RWB Activities as a licensee of Team RWB, I acknowledge, agree, promise and covenant with Team RWB, its agents, employees, members, managers, officers, directors, volunteers, sponsors, organizers, independent contractors, affiliates, and any promoting organizations, medical providers, property owners, law enforcement agencies, public entities, and special districts (and their respective shareholders, agents, officials and employees) (collectively, the “RELEASEES”) through or by which the Team RWB Activities will be held, on behalf of myself, my heirs, assigns, legal representatives, executors, administrators, successors in interest, estate, and next of kin (hereinafter collectively my “Successors”) as follows:`;

export const INDEMN_CLAUSE_1 = `1. I HAVE AGREED TO PARTICIPATE IN A STRENUOUS PHYSICAL FITNESS OR COMMUNITY SERVICE EVENT. Team RWB Activities include athletic training and development in specific types of endurance events: long-distance swimming, cycling, hiking, and running, including, but not limited to, cross training such as calisthenics, free-body muscular development, weight training, and flexibility development/stretching. These activities also may include local transportation to and from event sites. I know and understand that there is risk of injury inherent in all Team RWB events, including the potential for physical or mental injury, illness, disease, permanent paralysis, death, and property damage, to others or myself, and while particular safety practices, equipment, and personal discipline may reduce this risk, such risk may not be eliminated; `;
export const INDEMN_CLAUSE_2 = `2. NOTWITHSTANDING THESE RISKS, I ACKNOWLEDGE THAT I AM VOLUNTARILY ACCESSING THE PREMISES AND PARTICIPATING IN THE TEAM RWB ACTIVITIES WITH KNOWLEDGE OF THE DANGERS INVOLVED. I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS FROM TEAM RWB ACTIVITIES, BOTH KNOWN OR UNKNOWN, IDENTIFIED OR UNIDENTIFIED, ANTICIPATED OR UNANTICIPATED, EVEN IF ARISING FROM THE NEGLIGENCE OF ANY RELEASEE OR OTHERS, AND I ASSUME FULL RESPONSIBILITY FOR MY PARTICIPATION; I covenant not to make or bring any such claim against Team RWB or any other Releasee, and forever release and discharge Team RWB and all other Releasees from liability under such claims.`;
export const INDEMN_CLAUSE_3 = `3. I consent to the release (by Team RWB and any third party) to RELEASEES and insurance carriers of my name and medical information that relate solely to any injury or death I may suffer arising from any of Team RWB Activities;`;
export const INDEMN_CLAUSE_4 = `4. I willingly agree to comply with the stated and customary terms and conditions for Team RWB Activities;`;
export const INDEMN_CLAUSE_5 = `5. At all times, I will exercise due caution and abide by the practices and principles of fitness safety;`;
export const INDEMN_CLAUSE_6 = `6. I hereby consent to receive medical treatment, which may be deemed advisable in the event of injury, accident, or illness during my participation in Team RWB Activities;
I understand and agree that I am solely responsible for all costs related to such medical treatment and any related medical transportation and/or evacuation, and I hereby release, forever discharge, and hold harmless Team RWB and all other Releasees from any claim based on such treatment or other medical services;`;
export const INDEMN_CLAUSE_7 = `7. I understand that still and video images of me may be taken in conjunction with Team RWB Activities and agree that they may be used to promote Team RWB;`;
export const INDEMN_CLAUSE_8 = `8. At all times, I will wear proper safety equipment and clothing based on customary practices. If I participate in a cycling event, I will wear a helmet certified by ANSI SNELL, CPSC, or DOT performance tests and that can protect me against serious head injury, and assume all responsibility and liability for the selection of such a helmet; and`;
export const INDEMN_CLAUSE_9 = `9. I accept responsibility for the condition and adequacy of my equipment.`;
export const INDEMN_CLAUSE_10 = `10. In consideration of my use of equipment owned, leased, donated or borrowed by Team RWB or supplied by a Team RWB volunteer, I expressly agree and contract, on behalf of myself and my heirs, that Team RWB shall not be liable for any damages arising from personal injuries (including death) sustained by me, as a result of the use of the equipment.`;

export const RESPONSABILITY = `RELEASE AND ACCEPTANCE OF RISK AND RESPONSIBILITY: I HAVE READ AND FULLY UNDERSTAND THE TERMS OF THIS LIABILITY WAIVER & ACKNOWLEDGEMENT. I, FOR MYSELF AND MY SUCCESSORS, RELEASE AND HOLD HARMLESS THE RELEASEES WITH RESPECT TO, AND VOLUNTARILY AGREE, COVENANT, AND PROMISE TO ACCEPT AND ASSUME ALL RESPONSIBILITIES FOR ANY AND ALL INJURY, DISABILITY, ILLNESS, DISEASE, DEATH, PARALYSIS, LOSS, OR DAMAGE TO PERSON OR PROPERTY, ARISING FROM MY PARTICIPATION IN TEAM RWB ACTIVITIES, WHETHER OR NOT CAUSED BY THE SOLE, CONCURRENT, OR CONTRIBUTORY NEGLIGENCE OF THE RELEASEES OR OTHERWISE. I, FOR MYSELF AND MY SUCCESSORS, HEREBY INDEMNIFY AND HOLD HARMLESS TEAM RWB AND THE RELEASEES FROM any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, fees, the costs of enforcing any right to indemnification under this Liability Waiver & Acknowledgement, and the cost of pursuing any insurance providers, incurred by/awarded against Team RWB or any other Releasees arising out of or resulting from any claim of a third party related to my being on the Premises or participating in any Team RWB Activity, including any claim related to my own negligence or the ordinary negligence of Team RWB. I UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL LEGAL RIGHTS BY SIGNING THIS LIABILITY WAIVER & ACKNOWLEDGEMENT, INCLUDING, BUT NOT LIMITED TO, RIGHTS ARISING FROM OR CLAIMS FOR THE ACTS, OMISSIONS, FAULT, OR NEGLIGENCE IN ANY DEGREE OF THE RELEASEES, AND I SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT OR ANYONE FORCING ME TO PARTICIPATE IN SPITE OF THE RISKS.`;

export const ENTIRETY = `\nI understand that this is the entire agreement between Team RWB and me, that it is for the benefit of all RELEASEES, and that it cannot be modified or changed in any way by the representations of Team RWB or any employee or agent of Team RWB or any of the RELEASEES or by me. This Liability Waiver & Acknowledgment may not be modified orally and any modifications or amendments made by Team RWB will be notified to you in writing. All matters arising out of or relating to this Liability Waiver & Acknowledgment shall be governed by and construed in accordance with the internal laws of the State of Michigan without giving effect to any choice or conflict of law provision or rule (whether of the State of Michigan or any other jurisdiction). Any claim or cause of action arising under this Liability Waiver & Acknowledgment may be brought only in the federal and state courts located in Moore County, North Carolina and I hereby consent to the exclusive jurisdiction of such courts.`;

export const NO_WAIVER = `\nA waiver or modification of any provision shall not be export construed as a modification of any other provision herein or as a consent to any subsequent waiver or modification.`;

export const SEVERABILITY = `\nEvery term and provision of this Liability Waiver & Acknowledgement is severable. If any one or more of them is found to be unenforceable or invalid, that shall not affect the other terms and provisions which shall remain binding and enforceable. By clicking the “Accept” button below, I indicate that I have read this entire document, understand it completely, and agree to be legally bound by its terms.`;

export const MINORITY_CONSENT = `This is to certify that I, as parent/guardian with legal responsibility for this participant of minority age, do consent and agree to my child’s or ward’s participation in Team RWB Activities and further agree individually and on behalf of my child or ward to all of the terms of this Liability Waiver & Acknowledgment as provided above, and, for myself and my Successors, I release and agree to indemnify the RELEASEES from any and all liabilities incident to my minor child’s or ward’s involvement or participation in Team RWB Activities as provided above, EVEN IF ARISING FROM THE NEGLIGENCE OF TEAM RWB, THE RELEASEES OR OTHERWISE. For the avoidance of doubt, this is Liability Waiver & Acknowledgment applies to any child or ward, for which I am legally responsible for, that I bring to participate in or to the Premises of Team RWB Activities, regardless of whether such child or ward is a “Participant” of Team RWB.`;
