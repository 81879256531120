import React, {useState} from 'react';
import moment from 'moment';
import styles from './Message.module.css';
import { messageFormatter, MESSAGE_STATUSES, formatSenderName } from '../../../../shared/utils/MessagesHelpers';
import { CreatedMessageImageDisplay } from './MessageImageDisplay.react';
import { defaultUserPhoto, filterPostMedia, } from '../../../../shared/utils/Helpers';
import MessageOptions from './MessageOptions.react';
import SitePreviewMessageCard from './SitePreviewMessageCard.react';
import ReplyMessage from './ReplyMessage.react';
import {useHistory} from 'react-router-dom';
import { useEffect } from 'react';
import renderMessageText from './renderMessageText.react';
import { useEmojis } from '../../../../shared/hooks/useEmojis';
import EmojiReactionSheet from './EmojiReactionSheet.react';
import LongPressDiv from '../generic/LongPressDiv';

const TopText = ({data, sentByCurrentUser, searchValue = undefined, senderName}) => {
  return (
    <div className={styles.nameAndTimeContainer} style={{flexDirection: sentByCurrentUser ? 'row-reverse' : 'row'}}>
      {data.status !== MESSAGE_STATUSES.failure
        ? !sentByCurrentUser && (
            <p className='namesAndObjects'>
              {senderName}
            </p>
          )
        : null}
      {/* send time vs created */}
      {(data.status !== MESSAGE_STATUSES.failure && !searchValue) && <p className={styles.timestamp} style={{marginLeft: 10}}>{(data?.edited || data?.edit_time_stamp) && 'Edited'} {moment(data?.sendTime || data?.created).format('h:mm a')}</p>}
      {data.status === MESSAGE_STATUSES.failure && <p className='errorMessage'>Message failed to Send</p>}
    </div>
  );
};

// TODO: When loading a specific message we might use the same styling as "isReplyingTo", or we might have the same color and timeout fade it, TBD
const Message = ({data, curUser, onReport, onBlock, onEdit, onDelete, onReply, onSeekReply, isReplyingTo, chatMembers, searchValue = undefined, onRetry, isInactive, channelId}) => {
  const [isBlocked, setIsBlocked] = useState(data.blocked);
  const [isEmojiListShowing, setIsEmojiListShowing] = useState(false);
  const userIsBlocked = data.blocked;
  const history = useHistory();
  const isPendingState = data?.status === MESSAGE_STATUSES.pending;
  const {emojiReactions, toggleEmojiReaction } = useEmojis({data, channelId, curUser, });

  let {sender, text, attributes, reply_message, profile_id} = data;
  const sentByCurrentUser = profile_id === curUser.id || sender?.id === curUser.id;
  // sender is removed from our backend response, so find that user if not a live ivs message
  if (!sender) sender = chatMembers?.find((user) => user.id === profile_id);

  useEffect(() => {
    if (!isBlocked) setIsBlocked(data.blocked);
  }, [data.blocked]);

  const senderIsAnonOrDeleted = !sender || sender.anonymous_profile;

  const senderName = formatSenderName(data, chatMembers, isBlocked);
  
  let imageUrls = [];
  if (attributes?.imageUrls) {
    if (typeof attributes.imageUrls === 'string') imageUrls = JSON.parse(attributes.imageUrls)
    else imageUrls = attributes.imageUrls;
  }
  
  // theoretically, the profile_id would be in a sender object with the rest of the missing user information
  const {imageSource, containerStyling, messageStyling} = messageFormatter(data, styles, sentByCurrentUser);

  return (
    <>
      {(!searchValue && reply_message?.xid) &&
        <ReplyMessage onPress={onSeekReply} messageSenderId={sender?.id} replyInfo={reply_message} chatMembers={chatMembers} />
      }
      <div className={styles.parentContainer} style={{backgroundColor: isReplyingTo && 'var(--grey5)', justifyContent: sentByCurrentUser ? 'flex-end' : 'flex-start'}}>
          {!sentByCurrentUser &&
            <img onClick={senderIsAnonOrDeleted ? null : () => history.push(`/profile/${sender.id}`)}
              src={isBlocked ? defaultUserPhoto : imageSource?.uri || imageSource}
              alt={isBlocked ? 'Blocked profile picture' : `${senderName}'s profile picture`}
              className={`${styles.messageProfileImage}`}
            />}
          <div className={`${styles.messageContainer}`} style={{alignItems: sentByCurrentUser && 'flex-end'}}>
            <TopText data={data} sentByCurrentUser={sentByCurrentUser} searchValue={searchValue} senderName={senderName} />
            {data.open_graph && 
              <SitePreviewMessageCard open_graph={data.open_graph} sentByCurrentUser={sentByCurrentUser} containerStyle={containerStyling} textStyle={messageStyling}/>
            }
            <div className={`${styles.textAndOptionsContainer} ${isBlocked && styles.hoverEffects}`} style={{flexDirection: sentByCurrentUser ? 'row-reverse' : 'row'}}>
              {text &&
                <div onClick={() => setIsBlocked(false)} className={`${styles.textContainer} ${containerStyling}`} style={{backgroundColor: imageUrls?.length > 0 && 'var(--white)'}}>
                  <p className={`${messageStyling}`}>{isBlocked ? '(click to view blocked message)' : renderMessageText({text, users: chatMembers, sentByCurrentUser, searchValue})}</p>
                </div>
              }
              {imageUrls.length > 0 && <div onClick={() => setIsBlocked(false)}>
                {isBlocked
                  ? <p className={`${messageStyling}`}>(tap to view blocked message)</p>
                  : <CreatedMessageImageDisplay messageImages={filterPostMedia(imageUrls, 'image')} sentByCurrentUser={sentByCurrentUser} />
                }
              </div>}
              {!searchValue && !isInactive && !isBlocked && !isPendingState &&
                <MessageOptions className={styles.messageOptionContainer}
                  data={data} sentByCurrentUser={sentByCurrentUser} isImage={imageUrls.length > 0 ? true : false}
                  onReport={onReport}
                  onBlock={onBlock}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onReply={onReply}
                  onRetry={onRetry}
                  isBlockedUser={userIsBlocked}
                  isAnonOrDeleted={senderIsAnonOrDeleted}
                  modifyEmoji={toggleEmojiReaction}
                />
              }
            </div>
            {isEmojiListShowing && <EmojiReactionSheet
              emojiReactions={emojiReactions} onRequestClose={() => setIsEmojiListShowing(false)} channelId={channelId}
              curUser={curUser} sentByCurrentUser={sentByCurrentUser} containerStyling={containerStyling}
            />}
            {(!searchValue && !isBlocked) && 
            <div className={styles.reactionsContainer}>
                {Object.keys(emojiReactions).slice(0,5).map((emoji, index) => {
                  return (
                    <LongPressDiv key={`emoji-${index}`}
                      className={`${styles.reaction} ${containerStyling}`}
                      style={{backgroundColor: emojiReactions?.[emoji]?.includes(curUser.id) ? 'var(--blue)' : !sentByCurrentUser ? 'var(--grey5)' : 'var(--navy)', }}
                      onClick={() => toggleEmojiReaction(emoji)}
                      onLongPress={() => setIsEmojiListShowing(true)}
                    >
                      <div>{emoji}</div>
                      <div className={`formLabel`} style={{color: (sentByCurrentUser || emojiReactions?.[emoji]?.includes(curUser.id)) ? 'var(--grey5)' : 'var(--navy)'}}>{emojiReactions[emoji]?.length}</div>
                    </LongPressDiv>
                  )
                })}
                {Object.keys(emojiReactions).length > 5 && (
                  <LongPressDiv
                    className={`${styles.reaction} ${containerStyling}`}
                    style={{backgroundColor: 'var(--grey5)'}}
                    onClick={() => setIsEmojiListShowing(true)}
                    onLongPress={() => setIsEmojiListShowing(true)}
                  >
                      <p>...</p>
                  </LongPressDiv>
                )}
            </div>}
          </div>
      </div>
    </>
  );
};

export default Message;
