import { useEffect, useState } from 'react';
import { CHAT_STATES } from '../constants/MessagesKeys';
// this can also be where we start requesting all chats to update the list properly, and maybe even re-order them when receiving a new latest message
export const useChatsUpdates = (chatManager, pollInterval = 2000) => {
  const [chats, setChats] = useState([]);
  const [hasAnActiveChat, setHasAnActiveChat] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getChatDisplay = async () => {
      const chatData = await chatManager.getAllLastSentMessages();
      const chats = chatData ? Object.values(chatData) : [];

      const sortedChats =[...chats].sort((a, b) => {
        const dateA = new Date(a.latest_message?.created);
        const dateB = new Date(b.latest_message?.created);
        return dateB - dateA;
      });

      setHasAnActiveChat((prev) => {
        const newActiveChat = chats.find((chat) => chat.state === CHAT_STATES.active || chat.state === CHAT_STATES.mute);
        return prev === newActiveChat ? prev : newActiveChat;
      });

      setChats((prevChats) => {
        // Create a map of chat IDs to latest_message.id for efficient comparison
        const prevChatMap = new Map(prevChats.map(chat => [chat.id, chat.latest_message?.id]));
        const hasChanged = chats.some(chat => prevChatMap.get(chat.id) !== chat.latest_message?.id);
        return hasChanged ? sortedChats : prevChats; // Update state only if messages changed
      });

      setIsLoading(false);
    };

    getChatDisplay(); // Initial fetch

    const interval = setInterval(() => {
      getChatDisplay();
    }, pollInterval);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [chatManager, pollInterval]);

  const updateChats = (newChats) => {
    setChats(newChats);
  }

  return { chats, hasAnActiveChat, isLoading, updateChats };
};
