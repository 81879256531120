import React, { useRef } from 'react';

const LongPressDiv = ({ onLongPress, onClick, className, style, children }) => {
  const timerRef = useRef(null);

  const start = () => {
    timerRef.current = setTimeout(() => {
      onLongPress();
    }, 500); // 500ms for long press
  };

  const clear = () => {
    clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  return (
    <div
      onMouseDown={start}
      onTouchStart={start}
      onMouseUp={clear}
      onMouseLeave={clear}
      onTouchEnd={clear}
      className={className}
      style={style}
      onClick={onClick} // Optional: still allow a regular click
    >
      {children}
    </div>
  );
};

export default LongPressDiv;
