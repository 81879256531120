import React from 'react';
import chatManager from '../../../../shared/models/ChatManager';
import { messageTimeFormatter } from '../../../../shared/utils/MessagesHelpers';
import Loading from '../Loading';
import Message from './Message.react';
import styles from './MessageSearchResults.module.css';
import {
    useHistory,
  } from 'react-router-dom';
import SearchedChatChannel from './SearchedChatChannel.react';

/**
 * 
 * @param {[]} messageResults: Array of messages that the backend found with that text/similar text
 * @param {[]} chatResults: Array of chat channels that the backend found with that text/similar text
 * @param {Object} curUser: The user profile object of the current user
 * @param {Boolean} isLoading: Flag if search is loading results
 * @param {String} searchValue: What the user has searched that should be highlighted
 */

const MessageSearchResultsView = ({messageResults, chatResults, curUser, isLoading, searchValue}) => {
    const history = useHistory();
    const navToAllMessages = () => {
        history.push('/messages/search', {
          results: messageResults.data,
          nextUrl: messageResults.next,
          searchValue,
          searchType: 'Messages',
          curUser
        });
      };
    const navToAllChats = () => {
        history.push('/messages/search', {
            results: chatResults.data,
            nextUrl: chatResults.next,
            searchValue,
            searchType: 'Chats',
            curUser
        })
    }
    // slice 5 off, if there were more than five display show more
    const limitedMessageResults = messageResults?.data?.slice(0, 5);
    const limitedChatResults = chatResults?.data?.slice(0, 5);
    return (
        <div className={styles.container}>
            <Loading right transparent={true} loading={isLoading && searchValue?.length > 2}/>
            {/* inject chatMembers into chat or retrieve on next page */}
            {messageResults?.count > 0 && (
                <>
                    <h4 className={styles.sectionTitle}>MESSAGES</h4>
                    <div>
                        {limitedMessageResults.map((message) => {
                            return (
                                <div className={styles.result} key={message.xid} onClick={() => history.push(`/messages/chats/${message.channel_id}`, {
                                    message_id: message.xid, // not yet implemented, maybe change
                                })}>
                                    <div className={styles.messageTitle}>
                                        <p>{chatManager.chatChannels[message.channel_id]?.displayName}</p> {/* Display chat name instead of member since member name is already displayed in message */}
                                        {/* nothing about edited in design */}
                                        <p>{messageTimeFormatter(message?.edit_time_stamp || message?.created)}</p>
                                    </div>
                                    <Message data={message} curUser={curUser} searchValue={searchValue} chatMembers={chatManager.chatChannels[message.channel_id].allMemberProfiles}/>
                                </div>
                            )
                        })}
                        {limitedMessageResults?.length < messageResults?.count && <h3 className={styles.seeAll} onClick={() => navToAllMessages()}>See All</h3>}
                    </div>
                </>
            )}
            
            {chatResults?.count > 0 && (
                <>
                    <h4 className={styles.sectionTitle}>CHATS</h4>
                    <div>
                        {limitedChatResults.map((chat) => {
                            return (
                                <div key={chat.id}>
                                    <SearchedChatChannel
                                        // pull from chat manager?
                                        formattedTime={messageTimeFormatter(chat?.latest_message?.edit_time_stamp || chat.latest_message?.created)}
                                        chatChannel={chatManager.chatChannels[chat.id]}
                                        searchValue={searchValue}
                                    />
                                </div>
                            )
                        })}
                        {limitedChatResults?.length < chatResults?.count && <h3 onClick={navToAllChats} className={styles.seeAll}>See All</h3>}
                    </div>
                </>
            )}

            {searchValue?.length < 3 &&
                <p className={styles.noResults}>Please enter three or more characters to search</p>
            }
            {!isLoading && messageResults?.count === 0 && chatResults?.count === 0 && searchValue?.length > 2 &&
                <p className={styles.noResults}>{`No messages or chats with the search results "${searchValue}"`}</p>
            }
        </div>
    )
}

export default MessageSearchResultsView;
