import React from 'react';
import BlockedUser from './BlockedUser';

const BlockedUsersList = ({data, onUserRemoved}) => (
  <>
    {[...data].map(([key, value]) => (
      <BlockedUser onUserRemoved={onUserRemoved} key={key} item={value} />
    ))}
  </>
);

export default BlockedUsersList;
