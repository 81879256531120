import React, {useState} from 'react';
import Header from '../Header';
import {useHistory} from 'react-router-dom';
import SearchBar from '../SearchBar';
import styles from './ChatCreation.module.css';
import UsersList from '../feed/UsersList';
import useSearchUsers from '../../../../shared/hooks/useSearchUsers';
import { MAX_ADDED_CHAT_MEMBERS } from '../../../../shared/constants/Restrictions';
import errorAlert from '../../../../shared/utils/ErrorAlert';
import chatManager from '../../../../shared/models/ChatManager';
import ChatXIcon from '../svgs/ChatXIcon';
import { assignChatAnalyticFields } from '../../../../shared/utils/AnalyticsFieldAssigner';
import { logChatInitiated, EXECUTION_STATUS, webSectionName } from '../../../../shared/models/Analytics';

const ChatCreation = ({currentMembers = [], channelId = null, closeModal}) => {
const [addedUsers, setAddedUsers] = useState([]);
const createDisabled = !addedUsers.length;
  const {
    search,
    searchValueChange,
    displayedUsers,
    isSearchLoading,
  } = useSearchUsers({addedUsers, currentMembers});
  const history = useHistory();

  const baseAnalyticsObj = (executionStatus, userIds) => {
    return {
      section_name: webSectionName(),
      number_of_participants: userIds.length + 1, // added users + creator
      execution_status: executionStatus,
    };
  };

  const addUser = (user) => {
    if (addedUsers.length + currentMembers.length === MAX_ADDED_CHAT_MEMBERS) {
      errorAlert('Cannot add more members.');
    } else {
      setAddedUsers([user, ...addedUsers]);
    }
  };

  const removeUser = (userToRemove) => {
    setAddedUsers(addedUsers.filter(user => user.id !== userToRemove.id));
  };

  const createChat = async () => {
    if (createDisabled) return;
    const userIds = addedUsers.map((user) => user.id);
    try {
        const chatRoom = await chatManager.createRoom(userIds);
        logChatInitiated(baseAnalyticsObj(EXECUTION_STATUS.success, userIds));
        history.replace(`/messages/chats/${chatRoom.channelId}`, {previous_view: 'messages_hub'});
    } catch (error) {
        logChatInitiated(baseAnalyticsObj(EXECUTION_STATUS.failure, userIds));
        errorAlert('Unable to create chat.');
    }
  };

  const addToChat = async () => {
    const userIds = addedUsers.map((user) => user.id);
    try {
      await chatManager.addMembers(channelId, userIds);
      closeModal();
    } catch (error) {
      errorAlert('Unable to add members to chat.');
    }
  }

  return (
    <div className={styles.container}>
      {!currentMembers.length ? <Header
        onBack={history.goBack}
        title={'New Chat'}
        rightButton={() => {
          return (
            // TODO: maybe make this global div at least for right button with the onpress and a disabled state
            <div
            className={styles.createHeaderButtonContainer}
              style={{
                cursor: createDisabled ? 'default' : 'pointer',
                opacity: createDisabled ? 0.5 : 1
              }}
              onClick={createChat}>
              <h4 style={{fontSize: 16}}>
                Create
              </h4>
            </div>
          );
        }}
      />
      : <button className={styles.addButton} onClick={addToChat}>
          <h4>Add</h4>
        </button>
      }
      <div style={{marginTop: 10}}>
        <SearchBar
          placeholder="Search for Members"
          onChange={searchValueChange}
          value={search}
          leftElement={<h3>To:</h3>}
        />
      </div>

      <div className={styles.chatCreationContainer}>
        <p className={styles.userCount}>
          {addedUsers.length + currentMembers.length}/{MAX_ADDED_CHAT_MEMBERS}
        </p>
        <div className={styles.userListContainer}>
          {addedUsers.map((user) => (
            <div key={user.id} className={styles.userPill}>
              <span style={{ marginRight: '8px' }}>{user.name}</span>
              <button 
                onClick={() => removeUser(user)} 
                className={styles.removeUserButton}
                >
                <ChatXIcon width={12} height={12} fill="white" />
              </button>
            </div>
          ))}
          {currentMembers.map((user) => (
            <div key={user.id} className={`${styles.userPill} ${styles.disabledUserPill}`}>
              <span style={{ marginRight: '8px' }}>{user.first_name} {user.last_name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.resultWrapper} style={{maxHeight: !currentMembers.length? 'calc(100dvh - 198px)' : 'calc(90dvh - 164px)'}}>
        {search.length > 0 && (
          <UsersList
            usersSuggestions={displayedUsers}
            loadingUsers={isSearchLoading}
            onSelect={addUser}
            canFollow={false}
            canReport={false}
          />
        )}
      </div>
    </div>
  );
};

export default ChatCreation;
