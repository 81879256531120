import * as React from "react"

function ChatXIcon(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.2 12L0 10.8 4.8 6 0 1.2 1.2 0 6 4.8 10.8 0 12 1.2 7.2 6l4.8 4.8-1.2 1.2L6 7.2 1.2 12z"
        fill="#fff"
      />
    </svg>
  )
}

export default ChatXIcon
