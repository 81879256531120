import React from 'react';
import MessagesIcon from '../svgs/MessagesIcon';
import styles from './MessageButton.module.css';
import {useHistory} from 'react-router-dom';
import chatManager from '../../../../shared/models/ChatManager';
import errorAlert from '../../../../shared/utils/ErrorAlert';
import { userProfile } from '../../../../shared/models/UserProfile';
import { ANON_USER_MESSAGE_WARNING } from '../../../../shared/constants/OtherMessages';
import { webSectionName } from '../../../../shared/models/Analytics';

const MessageButton = ({userId}) => {
    const history = useHistory();
    const navigateToChat = async () => {
        if(userProfile.anonymous_profile) {
            alert(ANON_USER_MESSAGE_WARNING);
            return;
        }
        
        try {
            const room = await chatManager.createRoom([userId]);
            history.replace(`/messages/chats/${room.channelId}`, {previous_view: 'profile_view'});   
        } catch (error) {
            console.warn('in catch error for message button: ', error);
            errorAlert('Unable to start messaging');
        }
    }
    return (
        <div className={styles.messageButton} onClick={navigateToChat}>
            <div style={{marginTop: 15, marginRight: 3}}>
                <MessagesIcon filledIcon={false} tintColor={'var(--white)'} strokeColor={'var(--navy)'} height={30} width={30}/>
            </div>
            <h2>MESSAGE</h2>
        </div>
    )
}

export default MessageButton;
