import React, {useState, useCallback, useEffect, useRef} from 'react';
import debounce from 'lodash.debounce';
import {rwbApi} from '../apis/api';
import {userProfile} from '../models/UserProfile';

const DEBOUNCE_MS = 500;
const useSearchUsers = ({addedUsers = [], currentMembers = []} = {}) => {
  const curUser = userProfile.getUserProfile();
  const [search, setSearch] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [originalResults, setOriginalResults] = useState([]); // returned from server to be filtered
  const [displayedUsers, setDisplayedUsers] = useState([]); // returned and filtered
  const blockedUserIdsRef = useRef(new Set());

  const debounceRef = useRef();

  useEffect(() => {
    const blockedUsers = userProfile.getUpdatedBlockedMap();
    const ids = new Set(Array.from(blockedUsers, ([id]) => id));
    blockedUserIdsRef.current = ids;
  }, []);

  const apiSearch = useCallback(async (text) => {
    if (!text) {
      setDisplayedUsers([]);
      return;
    }
    try {
      setIsSearchLoading(true);
      let results = await rwbApi.searchUser(text);
      setOriginalResults(results);
    } catch (error) {
      console.warn('error: ', error);
    } finally {
      setIsSearchLoading(false);
    }
  }, [addedUsers]);

  const debouncedSearch = useCallback(
    debounce((value) => apiSearch(value), DEBOUNCE_MS),
    [],
  );

  useEffect(() => {
    debounceRef.current = debouncedSearch;
    return () => debounceRef.current.cancel();
  }, [debouncedSearch]);

  const searchValueChange = (text) => {
    setSearch(text);
    debounceRef.current(text);
  };

  useEffect(() => {
    const filteredResults = originalResults.filter(
      (user) =>
        !blockedUserIdsRef.current.has(user.id) &&
        !addedUsers.some((addedUser) => addedUser.id === user.id) &&
        !currentMembers.some((currentMember) => currentMember.id === user.id) &&
        user.id !== curUser.id,
    );
    setDisplayedUsers(filteredResults);
  }, [addedUsers, originalResults]);

  return {
    search,
    searchValueChange,
    displayedUsers,
    isSearchLoading,
  };
};

export default useSearchUsers;
