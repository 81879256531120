const MAX_POST_LENGTH = 1500; // get stream limitation/requirement

const MAX_COMMENT_LENGTH = 1500; // get stream limitation/requirement

const MAX_POST_IMAGES = 10; // our limit on maximum images per post, comment, and reply

const MAX_MESSAGE_IMAGES = 5;

const MAX_ADDED_CHAT_MEMBERS = 25;

const MEDIA_UPLOAD_TYPES = ['cover', 'profile', 'event', 'post', 'comment', 'reply', 'message', ];

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

export {
    MAX_POST_LENGTH,
    MAX_COMMENT_LENGTH,
    MAX_POST_IMAGES,
    MAX_MESSAGE_IMAGES,
    MAX_ADDED_CHAT_MEMBERS,
    MEDIA_UPLOAD_TYPES,
    MAX_FILE_SIZE,
    
}