import React from 'react';
import { handleLinkClick } from '../../../../shared/utils/HandleLinkClick';
import LinksIcon from '../svgs/LinksIcon';
import styles from './SitePreviewMessageCard.module.css';
/**
 * 
 * @param {object} open_graph - The stored open graph data
 * @param {string} open_graph.description
 * @param {string} open_graph.image
 * @param {string} open_graph.title
 * @param {string} open_graph.url
 * TODO: htmlinferred here
 * 
 * Styling
 * @param {boolean} sentByCurrentUser - if sent by the current user change text colors and flip image and text
 */


// TODO: Test sites that do not have open graph data in the proper format and conver them, see setOpenGraphData
const SitePreviewMessageCard = ({open_graph, sentByCurrentUser, containerStyle, messageStyle}) => {
    const openLink = () => {
        // evt.stopPropagation();
        // evt.preventDefault();
        handleLinkClick(open_graph.url);
    };

    return (
        <div className={`${styles.container} ${containerStyle}`} style={{color: sentByCurrentUser ? 'var(--white)' : 'var(--grey)'}} onClick={openLink}>
            {!open_graph?.image || !open_graph?.image_url ? 
                <img className={styles.imageContainer} src={open_graph.image} alt={`Preview image of ${open_graph.url}`}/>
                : 
                <div className={styles.fallbackIconWrapper}>
                    <LinksIcon className={styles.fallbackIcon} />
                </div>
            }
            <div className={`${styles.textContainer} ${messageStyle}`}>
                <p className={`${styles.bold} ${styles.textContent}`}>{open_graph.title}</p>
                <p className={styles.textContent}>{open_graph.description}</p>
                <p className={`${styles.url} ${styles.bold} ${styles.textContent}`}>{open_graph.url}</p>
            </div>
        </div>
    )
}

export default SitePreviewMessageCard;
