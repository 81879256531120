import React, {useEffect, useState} from 'react';
import Header from '../Header';
import PostIcon from '../svgs/PostIcon';
import styles from './MessagesTab.module.css';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import ChatView from './ChatView.react';
import ChatListItem from './ChatListItem.react';
import chatManager from '../../../../shared/models/ChatManager';
import {CHAT_STATES} from '../../../../shared/constants/MessagesKeys';
import RWBModal from '../generic/RWBModal';
import RWBButton from '../RWBButton';
import Loading from '../Loading';
import ChatCreation from './ChatCreation.react';
import ChatSettings from './ChatSettings.react';
import { useChatsUpdates } from '../../../../shared/hooks/useChatsUpdate';
import { userProfile } from '../../../../shared/models/UserProfile';
import { ANON_USER_MESSAGE_WARNING } from '../../../../shared/constants/OtherMessages';
import SearchBar from '../SearchBar';
import useSearchMessages from '../../../../shared/hooks/useSearchMessages';
import MessageSearchResultsView from './MessageSearchResultsView.react';
import MessageSearchResultsPaginateView from './MessageSearchResultsPaginateView.react';
import { editMessagesButton } from '../../../../shared/utils/MessagesHelpers';

const MessagesTab = ({}) => {
  const [selectedChats, setSelectedChats] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const { chats, hasAnActiveChat, isLoading, updateChats } = useChatsUpdates(chatManager);
  const { names: nameSearchResults, messages: messageSearchResults, searchInput, searchValueChange, isSearchLoading } = useSearchMessages();
  const curUser = userProfile.getUserProfile();

  // potential TODO: add global listener to update unread to avoid doing it in chatmanager in case the user is on the view
  const history = useHistory();

  const createMessage = () => {
    history.push('messages/chats');
  };

  const editChats = () => {
    setEditMode(!editMode);
    setSelectedChats([]);
  };

  const removeChats = async () => {
    const updatedRooms = await chatManager.removeRooms(selectedChats);
    updateChats(Object.values(updatedRooms));
    setSelectedChats([]);
    setConfirmRemoval(false);
    editChats();
  };

  const onChatSelect = (channelIndex) => {
    setSelectedChats((prevSelected) => {
      if (prevSelected.includes(channelIndex)) {
        return prevSelected.filter((index) => index !== channelIndex);
      } else {
        return [...prevSelected, channelIndex];
      }
    });
  };

  const rightHeaderButton = () => {
    const {text, enabled, onPress} = editMessagesButton({curUser, chats, hasAnActiveChat, isLoading, editMode, selectedChats, setConfirmRemoval, editChats});
    return (
      <div onClick={onPress} className={styles.editHeaderButtonContainer} style={{cursor: enabled ? 'pointer' : 'default', opacity: enabled ? 1 : 0.5}}>
        <h4 style={{fontSize: 16}}>
          {text}
        </h4>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Switch>
      <Route path={'/messages/chats/'} exact>
          <ChatCreation />
        </Route>
        <Route path={'/messages/search'} exact component={MessageSearchResultsPaginateView} />
        <Route path={'/messages/chats/:id'} exact>
          <ChatView />
        </Route>
        <Route path={'/messages/chats/:id/settings'} exact>
          <ChatSettings />
        </Route>
        <Route path={'/'}>
          {/* TODO: Fix header size change issue. Header currently jumps in size when onBack is true. */}
          <Header
            title={'Messages'}
            rightButton={(isSearchLoading || searchInput) ? null : rightHeaderButton}
            onBack={editMode ? editChats : null}
          />
          {isLoading ? (
            <Loading
              right={true}
              size={100}
              color={'var(--white)'}
              loading={true}
            />
          ) : (
            <>
              {curUser.anonymous_profile ? (
                // Display No Access View
                <div className={styles.noChatsContainer}>
                  <p className="namesAndObjects">Messaging Disabled</p>
                  <p className="bodyCopy" style={{ color: 'var(--navy)' }}>
                    {ANON_USER_MESSAGE_WARNING}
                  </p>
                </div>
              ) : (
                <>
                  { !editMode &&
                    <div className={styles.searchBarWrapper}>
                      <SearchBar
                        onSubmit={() => null} onClearSearch={() => searchValueChange('')} 
                        onChange={searchValueChange} value={searchInput}
                        outline={true}
                        placeholder="Search Messsages"
                      />
                    </div>
                  }
                  {(isSearchLoading || searchInput) ?
                    (
                      <MessageSearchResultsView searchValue={searchInput} chatResults={nameSearchResults} messageResults={messageSearchResults} curUser={curUser} isLoading={isSearchLoading} />
                    )
                    : <>
                      {(!chats.length || !hasAnActiveChat) && (
                        <div className={styles.noChatsContainer}>
                          <div
                            onClick={createMessage}
                            className={styles.noChatsPostIconContainer}>
                            <PostIcon height={26} width={26} />
                          </div>
                          <p className="namesAndObjects">Start Messaging</p>
                          <p className="bodyCopy" style={{ color: 'var(--navy)' }}>
                            Your chats will appear here
                          </p>
                        </div>
                      )}

                      {chats.length > 0 && hasAnActiveChat && (
                        <div className={styles.hasChatsContainer}>
                          <div className={styles.chatsContainer}>
                            {chats.map((chat, index) => {
                              return (
                                chat.state !== CHAT_STATES.removed && chat.state !== CHAT_STATES.left && (
                                  <ChatListItem
                                    key={index}
                                    selected={selectedChats.includes(chat.id)}
                                    onSelect={() => onChatSelect(chat.id)}
                                    editMode={editMode}
                                    chat={chat}
                                  />
                                )
                              );
                            })}
                          </div>
                          {!editMode &&
                            <div className="floatingBtnContainer" onClick={createMessage}>
                              <div className={styles.chatPostIconContainer}>
                                <PostIcon height={26} width={26} />
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </>
                  }
                </>
              )}
            </>

          )}
        </Route>
      </Switch>
      <RWBModal
        open={confirmRemoval}
        closeModal={() => {
          setConfirmRemoval(false);
        }}
        hasCancelButton={true}
        bottomSheet={true}>
        {/* TBD: this styling will probably get reused. Might move to somewhere more global or into RWBModal */}
        <div
          style={{
            width: '100%',
            gap: 20,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <p style={{textAlign: 'center'}}>
            Are you sure you want to remove {selectedChats.length > 1 ? `these chats` : `this chat` }?
          </p>
          <RWBButton
            buttonStyle={'primary'}
            label={'Remove From List'}
            onClick={removeChats}
          />
        </div>
      </RWBModal>
    </div>
  );
};

export default MessagesTab;
