import { useState, useEffect } from "react";

const useMessageEditing = () => {
  const [editing, setEditing] = useState(false);
  const [editText, setEditText] = useState("");
  const [neededBrokerInfo, setNeededBrokerInfo] = useState({
    messageId: null, // might change to xid, tbd
    channel_id: null,
    created: null,
  });

  const startEditing = (brokerObj) => {
    setEditing(true);
    setEditText(brokerObj.text);
    setNeededBrokerInfo({
      // our DB || ivs
      messageId: brokerObj?.xid || brokerObj.id,
      created: brokerObj.created || brokerObj.sendTime,
    });
  };

  const resetNeededBrokerInfo = () => {
    setNeededBrokerInfo({
      messageId: null,
      channel_id: null,
      created: null,
    });
  }

  const stopEditing = () => {
    setEditing(false);
    setEditText("");
    resetNeededBrokerInfo();
  };

  return { editing, editText, startEditing, stopEditing, neededBrokerInfo, setNeededBrokerInfo };
};

export default useMessageEditing;
