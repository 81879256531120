import { rwbApi } from '../apis/api';
import { BLOCK_USER_DESCRIPTION } from '../constants/OtherMessages';

export async function blockUserWithAlert(user) {
    if (window.confirm(`Block ${user.first_name} ${user.last_name}?\n\n${BLOCK_USER_DESCRIPTION}`)) {
        try {
            await rwbApi.blockUser(user);
            window.alert('You have successfully blocked this user.');
            return true;
        } catch (error) {
            window.alert(error);
            console.warn('Error', error);
            return false;
        }
    }
    return false;
}
