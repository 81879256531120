import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class DangerIcon extends Component {
  render() {
    return (
      <svg
        className={this.props.className}
        width={this.props.width}
        height={this.props.height}
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#bf0d3e">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
    );
  }
}

DangerIcon.defaultProps = {
  width: 56,
  height: 56,
  tintColor: 'none',
};

DangerIcon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tintColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
