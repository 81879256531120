import { useState, useEffect } from "react";

const useMessageReplying = () => {
  const [replying, setReplying] = useState(false);
  const [replyingToIndex, setReplyingToIndex] = useState(null);
  const [replyingInfo, setReplyingInfo] = useState({
    xid: null, // message being replied to in xid
    message: {}, // the whole message obj with all data from ivs/our db
  });

  const startReplying = (replyMessageObj) => {
    setReplying(true);
    setReplyingToIndex(replyMessageObj.index);
    setReplyingInfo({
      xid: replyMessageObj?.xid || replyMessageObj.id,
        ...replyMessageObj
    });
  };

  const resetReplyingInfo = () => {
    setReplyingToIndex(null);
    setReplyingInfo({
      xid: null,
        channel_id: null,
        user: {},
    });
  }

  const stopReplying = () => {
    setReplying(false);
    resetReplyingInfo();
  };

  return { replying, startReplying, stopReplying, replyingInfo, setReplyingInfo, replyingToIndex };
};

export default useMessageReplying;
