import * as React from "react"

function FollowingIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.414 9.535L15 10.95l1.414 1.414 1.414 1.414 1.415-1.414 3.535-3.536-1.414-1.414-3.536 3.536-1.414-1.415z"
        fill="#00C800"
      />
      <path
        d="M9 12c-1.1 0-2.042-.392-2.825-1.175C5.392 10.042 5 9.1 5 8s.392-2.042 1.175-2.825C6.958 4.392 7.9 4 9 4s2.042.392 2.825 1.175C12.608 5.958 13 6.9 13 8s-.392 2.042-1.175 2.825C11.042 11.608 10.1 12 9 12zm-8 8v-2.8c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 013.15-1.163A13.76 13.76 0 019 13c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.476.438.996.438 1.563V20H1zm2-2h12v-.8a.973.973 0 00-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 00-5.55 0c-.917.225-1.825.562-2.725 1.012a.973.973 0 00-.5.85v.8zm6-8c.55 0 1.02-.196 1.412-.588C10.804 9.021 11 8.55 11 8c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 009 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 007 8c0 .55.196 1.02.588 1.412C7.979 9.804 8.45 10 9 10z"
        fill="#00C800"
      />
    </svg>
  )
}

export default FollowingIcon
