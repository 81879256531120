import React, {useState} from 'react';
import styles from './MessageOptions.module.css';
import { MESSAGE_STATUSES } from '../../../../shared/utils/MessagesHelpers';
import {REACTION_EMOJIS} from '../../../../shared/constants/MessagesKeys';
import EmojiIcon from '../svgs/EmojiIcon';
import PostOptionIcon from '../svgs/PostOptionIcon';
import AttendingIcon from '../svgs/AttendingIcon';
import EditIcon from '../svgs/EditIcon';
import TrashIcon from '../svgs/TrashIcon';
import ReportIcon from '../svgs/ReportIcon';
import BlockIcon from '../svgs/BlockIcon';
import RecurringIcon from '../svgs/RecurringIcon';
import EmojiPicker from 'emoji-picker-react';

const ReactionOptions = ({modifyEmoji}) => {
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    return (
      <div className={styles.reactionOptions}>
        {/* 
          Confirm what the reactions they want here
          Instead of hovering to show the first 5 emoji and ellipses, should we just open up the full emoji list on tap?
        */}
        {!emojiPickerVisible &&
        REACTION_EMOJIS.map((emoji, ind) => 
          <div onClick={() => modifyEmoji(emoji)} key={`predefined-emoji-${ind}`} className={styles.reactionItem}>{emoji}</div>
        )}
        <div className={styles.reactionItem} onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}>
          <PostOptionIcon className={styles.moreEmojiIcon} width={24} height={24} tintColor={'var(--grey)'}/>  
        </div>
        {/* TODO: Revert on hidden again, maybe hide PostOptionIcon when visible? */}
        {emojiPickerVisible && <EmojiPicker
          onEmojiClick={(e) => modifyEmoji(e.emoji)}
          emojiStyle='native'
        />}
      </div>
    )
  }
  
  const YourAdditionalOptions = ({ onEdit, onDelete }) => {
    return (
      <div className={styles.additionalOptions}>
        <div className={styles.additionalOptionItem} onClick={onEdit}>
          <EditIcon width={24} height={24} tintColor={'var(--navy)'}/>
          <h3 style={{color: 'var(--navy)'}}>Edit</h3>
        </div>
        <div className={styles.additionalOptionItem} onClick={onDelete}>
          <TrashIcon width={24} height={24}/>
          <h3 style={{color: 'var(--magenta)'}}>Delete</h3>
        </div>
      </div>
    )
  }
  
  const OtherAdditionalOptions = ({onReport, onBlock, isBlockedUser}) => {
    return (
      <div className={styles.additionalOptions}>
        <div className={styles.additionalOptionItem} onClick={onReport}>
          <ReportIcon width={24} height={24}/>
          <h3 style={{color: 'var(--magenta)'}}>Report</h3>
        </div>
        {!isBlockedUser && <div className={styles.additionalOptionItem} onClick={onBlock}>
          <BlockIcon width={24} height={24}/>
          <h3 style={{color: 'var(--magenta)'}}>Block</h3>
        </div>}
      </div>
    )
  }
  
  const MessageOptions = ({data, sentByCurrentUser, className, isImage, onReport, onBlock, onEdit, onDelete, onReply, onRetry, isBlockedUser, isAnonOrDeleted, modifyEmoji}) => {
    return (
      <div className={`${className} ${styles.messageOptionContainer} ${sentByCurrentUser ? styles.messageOptionContainerRight : styles.messageOptionContainerLeft}`}>
        { data.status !== MESSAGE_STATUSES.failure &&
          <>
            {!isAnonOrDeleted && (
              <>
              <div className={`${styles.messageOptionIconContainer} ${styles.reactionIcon}`}>
                <EmojiIcon width={24} height={24} tintColor={'var(--grey40)'}/>
                <ReactionOptions modifyEmoji={modifyEmoji} />
              </div>
              <div onClick={onReply} className={`${styles.messageOptionIconContainer} ${styles.replyIcon}`}>
                <AttendingIcon width={24} height={24} tintColor={'var(--grey40)'}/>
              </div>
              </>
            )}
            {isImage && sentByCurrentUser ?
              <div className={`${styles.messageOptionIconContainer} ${styles.deleteIcon}`} onClick={onDelete}>
                <TrashIcon width={24} height={24} stroke={'var(--grey40)'}/>
              </div>
              :
              <div className={`${styles.messageOptionIconContainer} ${styles.optionIcon}`}>
                <PostOptionIcon className={styles.reactionIcon} width={24} height={24} tintColor={'var(--grey40)'}/>
                {sentByCurrentUser ? <YourAdditionalOptions onEdit={() => onEdit(data)} onDelete={onDelete}/> : <OtherAdditionalOptions onReport={onReport} onBlock={onBlock} isBlockedUser={isBlockedUser}/>}
              </div>
            }
          </>
        }
        { data.status === MESSAGE_STATUSES.failure && sentByCurrentUser &&
          <>
            <div className={`${styles.messageOptionIconContainer} ${styles.retryIcon}`} onClick={() => onRetry(data)}>
              <RecurringIcon width={18} height={18} tintColor={'var(--magenta'}/>
            </div>
            <div className={`${styles.messageOptionIconContainer} ${styles.deleteIcon}`}>
              <TrashIcon width={24} height={24} stroke={'var(--magenta)'}/>
            </div>
          </>
        }
      </div>
    )
  }

  export default MessageOptions;
