import React, {useState, useEffect} from 'react';
import {Route, Redirect, useHistory, useLocation} from 'react-router-dom';
// import fakeAuth from '../mock/isAuthed';
import {authentication} from '../../../shared/models/Authentication';
import {rwbApi} from '../../../shared/apis/api';
import Loading from './Loading';
import { userProfile } from '../../../shared/models/UserProfile';

export default function PrivateRoute({component: Component, ...rest}) {
  const [isAuth, setisAuth] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();


  useEffect(() => {
    if (!authentication.loaded) {
      authentication
        .getAccessToken()
        .then((accessToken) => {
          if (accessToken) return accessToken;
          return Promise.reject('Missing Credentials');
        })
        .then(rwbApi.getAuthenticatedUser)
        .then((json) => {
          setisAuth(true);
          if (!userProfile.isEmailVerified()) {
            history.push('/registration/confirm_email');
          }
          else if (!userProfile.hasCompletedRegistration()) {
            history.push('/registration/personal_info');
          }
          setisLoading(false);
        })
        .catch(() => {
          setisAuth(false);
          setisLoading(false);
        });
    } else {
      setisAuth(true);
      setisLoading(false);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <Loading size={100} color={"var(--white)"} loading={true} />;
        }

        if (!isAuth) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { referrer: window.location.href },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}
