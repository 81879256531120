import React, {useState, useEffect, useRef} from 'react';
import styles from './MessageComposer.module.css';
import PostIcon from '../svgs/PostIcon';
import MessageImageAdder from './MessageImageAdder.react';
import {CreationMessageImageDisplay} from './MessageImageDisplay.react';
import {MESSAGE_STATUSES} from '../../../../shared/utils/MessagesHelpers';
import {getHyperlinks, replaceNameWithId, validTaggedUserProfiles, validTaggedUsers, validURL} from '../../../../shared/utils/Helpers';
import SitePreviewCard from '../cards/SitePreviewCard';
import useSitePreview from '../../../../shared/hooks/useSitePreview';
import XIcon from '../svgs/XIcon';

const MESSAGE_CHARACTER_LIMIT = 1500;

const MessageComposer = ({onMessageSent, curUser, graphData, editText, editing, onEditedMessageSent, replying, stopEditingAndReplying, replyingToName, chatMembers}) => {
  const [input, setInput] = useState('');
  const [images, setImages] = useState([]);
  const [tagUsersOpen, setTagUsersOpen] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const taggableMembers = chatMembers?.filter((user) => {
    return user?.id !== curUser?.id && !curUser?.blockedMap?.has(user?.id)
  });

  const {
    previewData,
    setPreviewData,
    links,
    setLinks,
    setRemovedOpenGraphLink,
    checkIncludeOpenGraph,
    displayingSitePreview,
    lastLink,
  } = useSitePreview(graphData);
  const textAreaRef = useRef(null);

  const changeTextArea = (e) => {
    const inputValue = e.target.value;
    if (inputValue.slice(-1) === '@') setTagUsersOpen(true);
    else {
      if (tagUsersOpen) setTagUsersOpen(false);
    }
    setInput(inputValue);
    const links = getHyperlinks(inputValue);
    setLinks(links);
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (editing) {
      setInput(editText);
      const foundLinks = getHyperlinks(editText);
      setLinks(foundLinks);
    } else {
      setInput('');
    }
  }, [editing, editText]);

  const resetTextArea = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `30px`;
    }
  };

  const postDisabled =
    (input?.length === 0 && images?.length === 0) ||
    input?.length > MESSAGE_CHARACTER_LIMIT;

  const submitMessage = () => {
    const includesOpenGraph = checkIncludeOpenGraph();
    if (postDisabled) return;
    const validTaggedProfiles = validTaggedUserProfiles(taggedUsers, input);
    let formattedText = input;
    if (validTaggedProfiles?.length > 0) {
      formattedText = replaceNameWithId(validTaggedProfiles, input);
    }
    const message = {
      text: formattedText,
      sender: curUser,
      images,
      open_graph: includesOpenGraph ? previewData : null,
      taggedUsers: validTaggedProfiles,
    };
    if (editing) {
      onEditedMessageSent(message);
    } else {
      onMessageSent(message);
    }
    resetTextArea();
    setInput('');
    setImages([]);
    setLinks([]);

    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const tagUser = (user) => {
    setTagUsersOpen(false);
    setInput(`${input}${user.name} `);
    setTaggedUsers([...taggedUsers, user]);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
    }
  };

  return (
    <>
    {displayingSitePreview() && (
      <div className={styles.sitePreviewContainer}>
        <SitePreviewCard
          key={lastLink}
          link={lastLink}
          cancelable={true}
          onClose={() => {
            setRemovedOpenGraphLink(lastLink);
            setPreviewData(null);
          }}
          storePreviewData={setPreviewData}
          graphData={previewData}
          type={'message'}
        />
      </div>
    )}
    {tagUsersOpen && <div>
      {taggableMembers?.map((user) => {
        return (
          <div key={user.id} onClick={() => tagUser(user)}>
            <p>{user?.name}</p>
          </div>
        )
      })}
    </div>}
    {/* edit/reporting view */}
    {(editing || replying) && 
      <div className={styles.editReplyContainer}>
        <div className={styles.cancelEditReplyButtonContainer} onClick={stopEditingAndReplying}>
          <XIcon tintColor={'white'} width={18} height={18} />
        </div>
        <div className={styles.editReplyTextContainer}>
          <p className={styles.editAndReplyText}>{editing ? 'Editing Message' : 'Replying to '}</p>
          {replying && <h4>{replyingToName}</h4>}
        </div>
      </div>
    }
    <div className={styles.container}>
      <MessageImageAdder
        messageImages={images}
        updateImages={(images) => setImages(images)}></MessageImageAdder>
      <div className={styles.messageContainer}>
        {/* maybe use our text area here, might be more css battling than needed */}
        <div className={styles.textInputWrapper}>
          <CreationMessageImageDisplay
            messageImages={images}
            updateImages={(images) => setImages(images)}
          />
          <textarea
            ref={textAreaRef}
            placeholder="Enter Message"
            className={`formInput ${styles.textInput}`}
            value={input}
            onChange={changeTextArea}
            onKeyDown={handleKeyDown}
            type="text"
            rows={1}
          />
        </div>
        <div className={styles.postIconContainer}>
          {input?.length > 1000 && (
            <div className={styles.textLimitContainer}>
              <p
                style={{
                  color:
                    input?.length > MESSAGE_CHARACTER_LIMIT && 'var(--magenta)',
                }}>
                {input.length}/{MESSAGE_CHARACTER_LIMIT}
              </p>
            </div>
          )}
          <div
            onClick={submitMessage}
            className={`${styles.postIconBackground} ${
              postDisabled && styles.disabledPostIconBackground
            }`}>
            <PostIcon
              height={16}
              width={16}
              tintColor={postDisabled ? 'var(--grey40)' : 'var(--white)'}
            />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MessageComposer;
