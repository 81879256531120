import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import styles from './NavigationColumn.module.css';
import NavigationButton from './NavigationButton';
import RWBLogoHorizontal from '../svgs/RWBLogoHorizontal';
import {logClickToHub} from '../../../../shared/models/Analytics';
import {
  COMMUNITY_GUIDELINES_URL,
  POLICY_TERMS_URL,
} from '../../../../shared/constants/TermURLs';
import ApiInfo from '../ApiInfo';
import { userProfile } from '../../../../shared/models/UserProfile';
import { rwbApi } from '../../../../shared/apis/api';
import chatManager from '../../../../shared/models/ChatManager';
const pJSON = require('../../../package.json');

export default function NavigationColumn() {
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(chatManager?.hasUnreadChats());
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    async function getId() {
      const id = (await userProfile.init())?.id;
      setUserId(id);
    }
    getId();
  }, [userId])

  useEffect(() => {
    if (!userId) return;
    const getUnseenNotifications = async () => {
      const response = await rwbApi.getUnseenNotifications();
      setHasUnreadNotifications(response.data > 0);
    };

    getUnseenNotifications();
    const intervalId = setInterval(getUnseenNotifications, 90 * 1000);
    return () => clearInterval(intervalId);
  }, [userId]);

  useEffect(() => {
    if (!userId) return;
    chatManager.init();
    return () => {}
  }, [userId]);

  useEffect(() => {
    const unsubscribe = chatManager.subscribe(setHasUnreadMessages);
    return () => unsubscribe();
  }, []);

  const location = useLocation();
  function isNavBarDisplayed(location) {
    const {pathname} = location;
    const allowedPaths = [
      '/feed',
      '/groups',
      '/events',
      '/challenges',
      '/profile',
      '/notifications',
      '/messages'
      // '/login',
      // '/registration',
    ];
    for (const allowedPath of allowedPaths) {
      if (pathname.startsWith(allowedPath)) {
        return true;
      }
    }
    return false;
  }
  const showNavBar = isNavBarDisplayed(location);
  return (
    <div className={showNavBar ? styles.container : styles.none}>
      <div className={styles.navLinkContainer}>
        <div className={styles.navLogo}>
          <RWBLogoHorizontal className={styles.navLogo} />
        </div>
        {/* <NavLink to="/">
          <NavigationButton label={'Home'} url={'/'} />
        </NavLink> */}
        <NavLink onClick={() => logClickToHub('Feed')} to="/feed">
          <NavigationButton label={'Feed'} url={'/feed'} />
        </NavLink>
        <NavLink onClick={() => logClickToHub('Groups')} to="/groups">
          <NavigationButton label={'Groups'} url={'/groups'} />
        </NavLink>
        <NavLink onClick={() => logClickToHub('Events')} to="/events">
          <NavigationButton label={'Events'} url={'/events'} />
        </NavLink>
        <NavLink onClick={() => logClickToHub('Challenges')} to="/challenges">
          <NavigationButton label={'Challenges'} url={'/challenges'} />
        </NavLink>
        <NavLink onClick={() => {
          logClickToHub('Notifications');
          setHasUnreadNotifications(false);
        }} to="/notifications">
          <NavigationButton unreadNotifications={hasUnreadNotifications} label={'Notifications'} url={'/notifications'} exact />
        </NavLink>
        <NavLink onClick={() => {
          logClickToHub('Messages');
        }} to="/messages">
          <NavigationButton unreadMessages={hasUnreadMessages} label={'Messages'} url={'/messages'} />
        </NavLink>
      </div>

      <div className={styles.settingsContainer}>
        <div>Team Red, White and Blue</div>
        <div>
          <a
            className="link"
            href={POLICY_TERMS_URL}
            target="_blank"
            rel="noreferrer noopener">
            Privacy Policy & Terms
          </a>
        </div>
        <div>
          <a
            className="link"
            href={COMMUNITY_GUIDELINES_URL}
            target="_blank"
            rel="noreferrer noopener">
            Community Guidelines
          </a>
        </div>
        <div>
          <p style={{fontSize: 12}}>Version: {pJSON.version}</p>
        </div>
        <ApiInfo />
      </div>
    </div>
  );
}
