import { useState, useCallback } from "react";
import { validURL } from "../utils/Helpers";

const useSitePreview = (initialGraphData = null) => {
  const [previewData, setPreviewData] = useState(initialGraphData);
  const [links, setLinks] = useState([]);
  const [removedOpenGraphLink, setRemovedOpenGraphLink] = useState(null);

  const checkIncludeOpenGraph = useCallback(() => {
    return (
      previewData &&
      links.length > 0 &&
      (!removedOpenGraphLink || removedOpenGraphLink !== links[links.length - 1])
    );
  }, [previewData, links, removedOpenGraphLink]);

  const updateLinks = (newLinks) => {
    if (newLinks.length > 0) {
      setLinks(newLinks);
      if (
        removedOpenGraphLink &&
        newLinks[newLinks.length - 1] !== removedOpenGraphLink &&
        validURL(newLinks[newLinks.length - 1])
      ) {
        setRemovedOpenGraphLink(null);
      }
    } else {
      setRemovedOpenGraphLink(null);
      setLinks([]);
    }
  };

  const displayingSitePreview = () => {
    return (
      !removedOpenGraphLink &&
      links.length > 0 &&
      validURL(links[links.length - 1])
    );
  };

  return {
    previewData,
    setPreviewData,
    links,
    setLinks: updateLinks,
    removedOpenGraphLink,
    setRemovedOpenGraphLink,
    checkIncludeOpenGraph,
    displayingSitePreview,
    lastLink: links[links.length - 1] || null,
  };
};

export default useSitePreview;
