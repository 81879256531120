import React, { useState } from 'react';
import styles from './ReportChatModal.module.css';
import RWBModal from '../generic/RWBModal';
import RWBButton from '../RWBButton';

const ReportChatModal = ({ 
    showReportModal, 
    setShowReportModal, 
    showConfirmReportModal, 
    setShowConfirmReportModal, 
    handleReportSubmit,
    messageIndex //optional
}) => {
    const [reportReason, setReportReason] = useState('');
    const [reportDetails, setReportDetails] = useState('');

    const handleReportChat = (reason) => {
        setReportReason(reason);
        setShowReportModal(false);
        setShowConfirmReportModal(true);
    };

    return (
        <>
            {/* Report Chat Modal */}
            <RWBModal
                open={showReportModal}
                closeModal={() => setShowReportModal(false)}
                hasCancelButton={true}
                bottomSheet={true}
            >
                <div className={styles.reportModalContent}>
                    <p className={styles.reportModalText}>Why are you reporting this {messageIndex ? "message?" : "group chat?"}</p>
                    <RWBButton 
                        buttonStyle="stackedTop" 
                        label="Harassment"
                        onClick={() => handleReportChat('Harassment')} 
                    />
                    <RWBButton 
                        buttonStyle="stackedMiddle" 
                        label="Spam"
                        onClick={() => handleReportChat('Spam')} 
                    />
                    <RWBButton 
                        buttonStyle="stackedBottom" 
                        label='Inappropriate Content'
                        onClick={() => handleReportChat('Inappropriate Content')} 
                    />
                </div>
            </RWBModal>

            {/* Confirm Report Modal */}
            <RWBModal
                open={showConfirmReportModal}
                closeModal={() => {
                    setShowConfirmReportModal(false);
                    setReportDetails('');
                }}
                hasCancelButton={true}
                bottomSheet={true}
            >
                <div className={styles.reportModalContent}>
                    <p className={styles.reportModalText}>Report {reportReason}</p>
                    <textarea
                        className={styles.reportTextArea}
                        placeholder="Enter any additional context (optional)"
                        value={reportDetails}
                        onChange={(e) => setReportDetails(e.target.value)}
                    />
                    <RWBButton buttonStyle="primary" label="SEND REPORT" onClick={() => {
                        handleReportSubmit(reportReason, reportDetails);
                        setReportDetails('');}} />
                </div>
            </RWBModal>
        </>
    );
};


export default ReportChatModal;
