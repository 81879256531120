import React, { useState, useEffect } from 'react';
import styles from './ChangeGroupNameModal.module.css';
import RWBModal from '../generic/RWBModal';
import RWBButton from '../RWBButton';

const ChangeGroupNameModal = ({ showModal, setShowModal, currentGroupName, onUpdateGroupName }) => {
    const [newGroupName, setNewGroupName] = useState(currentGroupName);

    useEffect(() => {
        if (showModal) {
            setNewGroupName(currentGroupName || '');
        }
    }, [showModal, currentGroupName]);

    const handleChangeGroupName = () => {
        onUpdateGroupName(newGroupName);
        setShowModal(false);
    };

    return (
        <RWBModal
            open={showModal}
            closeModal={() => setShowModal(false)}
            hasCancelButton={true}
            bottomSheet={true}
        >
            <div className={styles.modalContent}>
                <input
                    type="text"
                    className={styles.groupNameInput}
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                />
                <RWBButton buttonStyle="primary" label="CHANGE GROUP NAME" onClick={handleChangeGroupName} />
            </div>
        </RWBModal>
    );
};

export default ChangeGroupNameModal;
