import React, {useState, useEffect} from 'react';
import chatManager from '../models/ChatManager';
import { logChatMessageReaction, EXECUTION_STATUS } from '../models/Analytics';

const reorderEmojis = (emojisObject) => {
    if (!emojisObject) return {};
    const sortedKeys = Object.keys(emojisObject).sort(
      (a, b) => emojisObject[b].length - emojisObject[a].length
    );
    return sortedKeys.reduce((orderedObj, key) => {
      orderedObj[key] = emojisObject[key];
      return orderedObj;
    }, {});
};

export const useEmojis = ({data, curUser, channelId}) => {

    const [emojiReactions, setEmojiReactions] = useState(reorderEmojis(data?.emoji_reactions) || {});

    const logReaction = (status) => {
        logChatMessageReaction({
            execution_status: status,
            section_name: 'Messages',
            chat_channel_id: channelId,
            message_id: data.id,
        });
    };
    
    // this gets called when the message gets updated
    const adjustEmojis = (emoji, userId, removing) => {
        setEmojiReactions((prevReactions) => {
        let newReactions = { ...prevReactions };
        if (newReactions[emoji]) {
            if (newReactions[emoji]?.includes(userId) && removing) {
                newReactions[emoji] = newReactions[emoji].filter((id) => id !== userId);
            if (newReactions[emoji]?.length === 0) {
                delete newReactions[emoji];
            }
            } else {
                newReactions[emoji] = [...newReactions[emoji], userId];
            }
        } else {
            newReactions[emoji] = [userId];
        }
        return reorderEmojis(newReactions);
        });
    }

    const toggleEmojiReaction = async (emoji) => {
        data.emojiInfo = {emoji, reactorId: curUser.id, messageId: data?.xid || data?.id};
        // deep copy to revert if there is an error
        const reactionsBeforeUpdate = Object.fromEntries(
          Object.entries(emojiReactions).map(([key, ids]) => [key, [...ids]])
        );
        const removal = emojiReactions[emoji]?.includes(curUser.id) || false;
        data.emojiInfo.removal = removal
        adjustEmojis(emoji, curUser.id, removal);
        try {
          await chatManager.modifyEmoji(channelId, data, emoji, removal);
          logReaction(EXECUTION_STATUS.success);
        } catch (error) {
          setEmojiReactions(reactionsBeforeUpdate);
          logReaction(EXECUTION_STATUS.failure);
        }
    };

    useEffect(() => {
        if (!data?.emojiInfo) return;
        const {emoji, reactorId, removal} = data.emojiInfo;
        if (reactorId !== curUser.id) adjustEmojis(emoji, reactorId, removal);
    }, [data.emojiInfo])

    return {emojiReactions, toggleEmojiReaction};
}

