import React from 'react';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import { formatChatPreviewMessage, formatSenderName, messageTimeFormatter } from '../../../../shared/utils/MessagesHelpers';
import {useHistory} from 'react-router-dom';
import styles from './ChatListItem.module.css';
import renderMessageText from './renderMessageText.react';

const ChatListItem = ({chat, selected, editMode, onSelect}) => {
    const lastMessage = chat.latest_message;
    const lastSenderName = formatSenderName(lastMessage,chat.members);
    const chatMembers = chat?.members?.records || chat?.members;
    const userProfiles = chatMembers?.map((user) => {
        return (
            {id: user.profile.id, name: `${user.profile.first_name} ${user.profile.last_name}`}
        )
    })
    const history = useHistory();
    const navigateToChat = () => {
        if (!editMode) history.push(`/messages/chats/${chat.id}`, {displayName: chat.displayName, previous_view: 'messages_hub'});
    }

    const renderMessage = () => {
        // TODO: handle rendering the message if we want to display image previews
        let text = formatChatPreviewMessage(lastMessage, lastSenderName);
        return text;
    }

    return (
        <div onClick={navigateToChat} className={styles.container} style={{backgroundColor: selected && 'var(--grey20)'}}>
            {editMode && <div className={styles.checkBoxContainer}>
                <input className={styles.checkBox} type="checkbox" onClick={onSelect} checked={selected} />
            </div>}
            <img className='profileImage' src={lastMessage?.sender?.profile_photo_url || chat?.allMemberProfiles?.find((user => user.id === lastMessage?.profile_id))?.profile_photo_url || DefaultProfileImg} />
            <div className={styles.nameAndMessageContainer}>
                <h2 className={styles.overflowEllipsis}>{chat.displayName}</h2>
                <p className={styles.overflowEllipsis}>{renderMessageText({text: renderMessage(), users: userProfiles})}</p>
            </div>
            <div className={styles.unreadAndTimeContainer} style={{left: editMode ? 35 : 0}}>
                {chat.hasUnreadMessage && <div style={{width: 25, height: 25, borderRadius: 13, backgroundColor: 'var(--magenta)'}}/>}
                {lastMessage?.text && <p className={styles.timeStamp}>{messageTimeFormatter(lastMessage?.created)}</p>}
            </div>
        </div>
    )
}

export default ChatListItem;
