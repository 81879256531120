import React, {useState, useRef, useEffect} from 'react';
import composerStyles from './MessageComposer.module.css';
import styles from './MessageImageDisplay.module.css';
import XIcon from '../svgs/XIcon';
import {
  MAX_IMAGE_HEIGHTS,
  WEB_MIMES,
} from '../../../../shared/constants/ImageConstants';
import ImageWithAspectRatio from '../feed/ImageWithAspectRatio.react';
import {MAX_MESSAGE_IMAGES} from '../../../../shared/constants/Restrictions';
import PhotoView from '../feed/PhotoView';
import Carousel from '../feed/Carousel';

const CreationMessageImageDisplay = ({messageImages, updateImages}) => {
  const carouselScrollRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
        if (carouselScrollRef.current) {
          event.preventDefault(); // Prevent default vertical scroll
          carouselScrollRef.current.scrollLeft += event.deltaY;
        }
      };
      const divElement = carouselScrollRef.current;
      if (divElement) {
        divElement.addEventListener('wheel', handleWheel);
      }
      return () => {
        if (divElement) {
          divElement.removeEventListener('wheel', handleWheel);
        }
      };
  }, [messageImages]);

  const removeImageHandler = (index) => {
    let images = messageImages;
    images.splice(index, 1);
    updateImages([...images]);
  };

  return (
    messageImages?.length > 0 && (
      <div className={composerStyles.messagePhotosContainers}>
        <div style={{height: MAX_IMAGE_HEIGHTS.web, display: 'flex'}}>
          <div className={composerStyles.imgContainer} ref={carouselScrollRef}>
            {messageImages.map((img, index) => (
              <div
                key={`photo-container-${index}`}
                className={`${composerStyles.photoWrapper}`}>
                <div
                  className={composerStyles.modalXContainer}
                  onClick={() => removeImageHandler(index)}>
                  <XIcon tintColor={'var(--white)'} />
                </div>
                <ImageWithAspectRatio src={img?.image || img} />
              </div>
            ))}
          </div>
        </div>
        <h4 style={{marginTop: 4}}>
          Attached Images {messageImages?.length}/{MAX_MESSAGE_IMAGES}
        </h4>
      </div>
    )
  );
};

const CreatedMessageImageDisplay = ({messageImages, sentByCurrentUser}) => {
  const [isPhotoViewOpen, setIsPhotoViewOpen] = useState(false);
  const [focusedImgIndex, setFocusedImgIndex] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    messageImages.length > 1 && setWrapperHeight(ref.current.clientHeight);
  })

  return (
    messageImages?.length > 0 && (
      // might need some composer styles for adjustments in the imgcontainer or perhaps image with aspect ratio
      // causes a scroll bar to appear, probably because of absolute positioning
      <>
        {isPhotoViewOpen && 
          <PhotoView
          post_images={messageImages}
          index={focusedImgIndex}
          onModalClose={() => {setIsPhotoViewOpen(false); setFocusedImgIndex(0)}}
          setSelectedImage={(i) => setFocusedImgIndex(i)}
        />
        }
        <div onClick={() => setIsPhotoViewOpen(true)}>
          <div className={`${styles.imgContainer}`} style={{paddingRight: (messageImages.length -1) * 20}}>
            {messageImages.map((img, index) => {
              return (
              <div
                key={`photo-container-${index}`}
                ref={ref}
                style={{marginTop: (index > 0 ? -wrapperHeight + 20 : 0), marginLeft: (index * 20), width: '100%',}}
              >
                {messageImages.length > 1 ?
                <img className={styles.images} style={{width: `calc(100% + ${index * 20}px)`, aspectRatio: '16/9'}} src={img}/> :
                <img className={styles.images} style={{width: '100%'}} src={img}/> 
                }
              </div>
            );
            })}
          </div>
        </div>
      </>
    )
  );
};

export {
  CreationMessageImageDisplay,
  CreatedMessageImageDisplay,
};
