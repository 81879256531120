import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './NavigationButton.module.css';
import HomeIcon from '../svgs/HomeIcon';
import GroupsIcon from '../svgs/GroupsIcon';
import EventIcon from '../svgs/EventsIcon';
import ChallengeIcon from '../svgs/ChallengeTabIcon';
import {userProfile} from '../../../../shared/models/UserProfile';
import NotificationTabIcon from '../svgs/NotificationIcon';
import MessagesIcon from '../svgs/MessagesIcon';

const ACTIVE_TAB = '#bf0d3e';
const INACTIVE_TAB = '#828588';

export default class NavigationButton extends Component {
  render() {
    const {pathname} = window.location;
    const {url, exact, label, unreadNotifications, unreadMessages} = this.props;

    return (
      //probably better to create some sort of js event listener? NavLink has isActive as well; could use that
      <div
        className={
          exact
            ? pathname === url ||
              (pathname.includes('profile') &&
                pathname.includes(`/user/${userProfile.getUserProfile().id}`))
              ? styles.active
              : styles.button
            : pathname.startsWith(url)
            ? styles.active
            : styles.button
        }>
        {label === 'Feed' && (
          <HomeIcon
            className={styles.icon}
            tintColor={pathname.startsWith(url) ? ACTIVE_TAB : INACTIVE_TAB}
            filledIcon={pathname.startsWith(url)}
          />
        )}
        {label === 'Groups' && (
          <GroupsIcon
            className={styles.icon}
            tintColor={pathname.startsWith(url) ? ACTIVE_TAB : INACTIVE_TAB}
            filledIcon={pathname.startsWith(url)}
          />
        )}
        {label === 'Events' && (
          <EventIcon
            className={styles.icon}
            tintColor={pathname.startsWith(url) ? ACTIVE_TAB : INACTIVE_TAB}
            filledIcon={pathname.startsWith(url)}
          />
        )}
        {label === 'Challenges' && (
          <ChallengeIcon
            className={styles.icon}
            tintColor={pathname.startsWith(url) ? ACTIVE_TAB : INACTIVE_TAB}
            filledIcon={pathname.startsWith(url)}
          />
        )}
        {label === 'Notifications' && (
          <div className={styles.iconWithNotificationContainer}>
            {unreadNotifications && <div className={styles.notificationDot}/>}
            <NotificationTabIcon
              className={styles.icon}
              tintColor={pathname.startsWith(url) ? ACTIVE_TAB : INACTIVE_TAB}
              filledIcon={pathname.startsWith(url)}
            />
          </div>
        )}
        {label === 'Messages' && (
          <div className={styles.iconWithNotificationContainer}>
            {(unreadMessages && !pathname.startsWith(url)) && <div className={`${styles.notificationDot} ${styles.messagesDot}`}/>}
            <MessagesIcon
              notification={true}
              className={styles.icon}
              tintColor={pathname.startsWith(url) ? ACTIVE_TAB : null}
              strokeColor={pathname.startsWith(url) ? ACTIVE_TAB : null}
            />
          </div>
        )}
        <p className="navItem">{label}</p>
      </div>
    );
  }
}

NavigationButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
};
