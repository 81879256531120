import React from 'react';
import { replaceIdWithName, textRegexPatterns } from "../../../../shared/utils/Helpers";

// separates links and enriched users in tags
const renderMessageText = ({
  text,
  users,
  sentByCurrentUser,
  searchValue,
  isAReply,
}) => {
  text = replaceIdWithName(users, text);
  const {urlPattern, tagPattern, combinedPattern, searchPattern} = textRegexPatterns(searchValue);
  const parts = text?.split(combinedPattern);
  if (!parts) return text;

  return parts.map((part, index) => {
    if (part?.match(urlPattern)) {
      return (
        <a
          key={`anchor-${index}`}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{color: sentByCurrentUser ? 'var(--white)' : 'blue', textDecoration: 'underline'}}
          >
          {part}
        </a>
      );
    } else if (part?.match(tagPattern)) {
        // This regex replaces the token with @ followed by the text inside the brackets
        const cleanedPart = part.replace(/^@\[(.+)\]$/, '@$1');
        return <h3 key={`user-${index}`} style={{ fontSize: 16, color: sentByCurrentUser && !isAReply ? 'var(--white)' : 'var(--black)' }}>{cleanedPart}</h3>;
    } else if (searchValue && part && part?.match(searchPattern)) {
      return <h3 key={`search-${index}`} style={{ fontSize: 16, color: sentByCurrentUser && !isAReply ? 'var(--white)' : 'var(--black)' }}>{part}</h3>;
    } else {
      return <span key={index}>{part}</span>;
    }
  });
};

export default renderMessageText;
