import { useState, useEffect } from "react";

import { rwbApi } from "../apis/api.js"
import * as messageHelpers from "../utils/MessagesHelpers.js"


const useMessages = (channelId, setLoading, currentUser) => {
  // Messages that represent the 'live' view of the chat.
  const [currentMessages, setCurrentMessages] = useState([]);

  // Messages jumped to via replies, notification, navigation, etc.
  const [seekedMessages, setSeekedMessages] = useState([]);

  // Pages returned by API. Trust backend for pagination.
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  // If we have a set of seeked messages, show that.
  // Controller merges it into currentMessages if it has any overlap, zeroing
  // out seekedMessages in that case.
  const viewingSeekedMessages = seekedMessages.length > 0;

  const loadOlderMessages = async () => {
    const response = await rwbApi.getPaginatedMessages(nextPage);
    const messagesWithFullInfo = messageHelpers.injectAdditionalMessageInfo(response.results, channelId, currentUser);
    if (viewingSeekedMessages) {
      const loadedMessages = [...seekedMessages];
      setSeekedMessages([...messagesWithFullInfo.reverse(), ...loadedMessages])
    }
    else {
      const loadedMessages = [...currentMessages];
      setCurrentMessages([...messagesWithFullInfo.reverse(), ...loadedMessages])
    }
    setNextPage(response.next ?? null);
    // After the DOM updates, adjust the scroll position so the user is not at the top
  }

  const loadNewerMessages = async () => {
    // Can only be called when viewing older messages, so we simplify towards
    // only handling the olderMessages case.
    const response = await rwbApi.getPaginatedMessages(previousPage);
    const messagesWithFullInfo = messageHelpers.injectAdditionalMessageInfo(response.results, channelId, currentUser);
    mergeAndSetMessages(messagesWithFullInfo.reverse())
    setPreviousPage(response.previous ?? null);
  };

  /**
  Given a set of fetched messages, figures out if it overlaps the set of newest
  messages. If it does, merge the newest, fetched, and older message sets together.
  If not, prepend the fetched messages to the older messages list.

  Notably, this only ever has to worry about loading from the past into the
  present, so we only have to check fetched against newestMessages
  */
  const mergeAndSetMessages = (fetched) => {
    const lastFetchedXid = fetched[fetched.length - 1].xid;
    const indexInNewest = currentMessages.map(e => e.xid).indexOf(lastFetchedXid);
    
    const hasOverlap = indexInNewest >= 0;
    if (hasOverlap) {
      let messageUnion;
      setCurrentMessages((prev) => {
        const newestSlice = prev.slice(indexInNewest + 1, prev.length);
        messageUnion = seekedMessages.concat(fetched).concat(newestSlice);
        return messageUnion;
      });
      setSeekedMessages([]);
      return messageUnion;
    }
    else {
      // no-overlap path
      const loadedMessages = [...seekedMessages, ...fetched];
      setSeekedMessages(loadedMessages);
      return loadedMessages;
    }
  }

  const seekReply = async (replyData, setLoading) => {
    let messageIdx = currentMessages.findIndex(message => message.xid === replyData.xid);
    let inverseMessageIdx = currentMessages.length - 1 - messageIdx;
    if (messageIdx === -1) {
      setLoading(true);
      const response = await rwbApi.seekMessage(channelId, replyData.xid);
      const messagesWithFullInfo = messageHelpers.injectAdditionalMessageInfo(response.results, channelId, currentUser);
      const mergedMessages = mergeAndSetMessages(messagesWithFullInfo.reverse());
      setPreviousPage(response.previous ?? null);
      setNextPage(response.next ?? null);
      messageIdx = mergedMessages.findIndex(message => message.xid === replyData.xid);
      inverseMessageIdx = mergedMessages.length - 1 - messageIdx;
      setTimeout(() => setLoading(false), 50);
    }
    return {messageIdx, inverseMessageIdx};
  }

  return {
    currentMessages,
    setCurrentMessages,
    seekedMessages,
    setSeekedMessages,
    nextPage,
    setNextPage,
    previousPage,
    setPreviousPage,
    loadOlderMessages,
    loadNewerMessages,
    mergeAndSetMessages,
    seekReply,
    viewingSeekedMessages,
  }
};

export default useMessages;
