import React, {Component} from 'react';
import Header from '../Header';
import SearchBar from '../SearchBar';
import styles from './GroupMembers.module.css';
import GroupMembersList from './GroupMembersList';
import {rwbApi} from '../../../../shared/apis/api';
import Loading from '../Loading';
import {hasReachedBottom} from '../../BrowserUtil';
import debounce from 'lodash.debounce';
import {USER_SEARCH_ERROR} from '../../../../shared/constants/ErrorMessages';
import UsersList from '../feed/UsersList';
import GroupLozenge from './GroupLozenge';
import groupMemberSearchURLFormatter from '../../../../shared/utils/groupMemberSearchURLFormatter';
import { EVENT_CHECK_INS_YTD, JOINED_TEAM_RWB, LAST_CHECK_IN } from '../../../../shared/constants/GroupMemberFilterOptions';
import SearchGooglePlaces from '../SearchGooglePlaces';
import { getGroupRole, isAdminOrModeratorOfGroup } from '../../../../shared/utils/Helpers';
import { userProfile } from '../../../../shared/models/UserProfile';
import { groupMemberFiltersStorage } from '../../../../src/models/GroupMembersFilters';
import { DEFAULT_GROUP_MEMBER_FILTER_OPTIONS } from '../../../../shared/constants/DefaultFilters';
import LocationIcon from '../svgs/LocationIcon';

const DEBOUNCE_MS = 500;

export class GroupMembers extends Component {
  constructor(props) {
    super(props);

    this.isModal = !("location" in props);
    this.isGroupAdmin = props?.location?.state?.isAdmin;

    let _groupId = this.props.groupId;
    if (!_groupId) {
      _groupId = props?.match?.params?.groupId;
    }

    this.groupsJoined = userProfile.getUserProfile().groupsJoined
    //This is used in the lozanges to check if you can use the EL and anchor chapter toggles.
    this.isAdminOrModerator = isAdminOrModeratorOfGroup(this.groupsJoined, _groupId)
    this.state = {
      groupId: _groupId,
      groupName: props?.location?.state?.groupName,
      minifiedCard: props?.location?.state?.minifiedCard,
      eventId: props?.location?.state?.eventId,
      admins: [],
      chapterCaptains: [],
      moderators: [],
      members: [],
      loading: true,
      loadingMore: false,
      // search group members
      memberSearch: '',
      locationSearch: '',
      searchingMembers: false,
      searchingLocations: false,
      searching: false,
      searchResults: [],
      resultsCount: 0,
      searchLoading: false,
      searchLoadingMore: false,
      canLoadMore: true,
      groupMemberFilters: {
        joinedTeamRWB: JOINED_TEAM_RWB['all-time'].slug,
        lastCheckIn: LAST_CHECK_IN['all-time'].slug,
        eventCheckInsYTD: EVENT_CHECK_INS_YTD['any'].slug,
        memberStatus: new Set(),
        militaryBranch: new Set(),
        militaryStatus: new Set(),
        isAnchorMember: false,
        isEagleLeader: false,
      },
      nextFilteredMember: '',
      memberCount: 0,
      page: 1,
    };
  }

  componentDidMount() {
    groupMemberFiltersStorage.getFilters().then((result) => {
      // ensure that admin/moderator filters are not applied if the user is not an admin/moderator of this group
      if (!this.isAdminOrModerator) {
        result.lastCheckIn = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.lastCheckIn;
        result.eventCheckInsYTD = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.eventCheckInsYTD;
        result.memberStatus = new Set();
        result.isAnchorMember = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.isAnchorMember;
        result.isEagleLeader = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.isEagleLeader;
      }
      this.setState({
        groupMemberFilters: result
      });
      this.loadUsers();
      this.updateOptions();
    });
    window.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = (event) => {
    event.preventDefault();
    const wrappedElement = document.getElementById('root');
    if (hasReachedBottom(wrappedElement)) {

      this.loadMoreUsers();
    }
  };

  loadUsers = () => {
    const {groupId, page, eventId} = this.state;
    rwbApi
      .retrieveGroupMembers(groupId, page, eventId)
      .then((result) => this.separateData(result));
  };

  loadMoreUsers = () => {
    const {loadingMore, loading, canLoadMore, groupId, page} = this.state;
    if (!loadingMore && !loading && canLoadMore) {
      this.setState({ loadingMore: true });
      if (this.state.nextFilteredMember) {
        this.apiSearch();
      }
      else {
        rwbApi.retrieveGroupMembers(groupId, page).then((result) => {
          this.separateData(result);
          window.addEventListener('scroll', this.trackScrolling);
        });
      }
    }
  };

  separateData = (users) => {
    const page = this.state.page;
    this.setState({
      admins: users?.admins?.length ? users.admins : this.state.admins,
      chapterCaptains: users?.chapter_captains?.length
        ? users.chapter_captains
        : this.state.chapterCaptains,
      moderators: users?.moderators?.length
        ? users.moderators
        : this.state.moderators,
      members: [...this.state.members, ...users.members],
      loadingMore: false,
      loading: false,
      canLoadMore: users.members.length === 25,
      page: page + 1,
    });
  };

  handleFilterSubmit = (updatedFilters) => {
    this.setState(prevState => ({
      ...prevState,
      groupMemberFilters: {
        ...prevState.groupMemberFilters,
        memberStatus: updatedFilters.memberStatus,
        militaryBranch: updatedFilters.militaryBranch,
        militaryStatus: updatedFilters.militaryStatus,
        isAnchorMember: updatedFilters.isAnchorMember,
        isEagleLeader: updatedFilters.isEagleLeader,
        joinedTeamRWB: updatedFilters.joinedTeamRWB,
        lastCheckIn: updatedFilters.lastCheckIn,
        eventCheckInsYTD: updatedFilters.eventCheckInsYTD,
      },
      searchLoading: true,
      nextFilteredMember: '',
      searchResults: [],
    }));
    this.updateOptions();
  };

  handleUserSelect = (host) => {
    if (this.isModal) {
      this.props.onSelect(host);
      this.props.close();
    }
    else {
      this.props.history.push(`/profile/${host.id}`);
    }
  }

  handleClearMemberSearch = () => {
    this.setState({
      memberSearch: '',
      searchResults: [],
      nextFilteredMember: '',
      searchLoading: false,
      searchLoadingMore: false,
      searchingMembers: false,
    });
    this.updateOptions();
  }

  handleClearLocationSearch = () => {
    this.setState({
      locationSearch: '',
      searchResults: [],
      nextFilteredMember: '',
      searchLoading: false,
      searchLoadingMore: false,
      searchingLocations: false,
    });
    this.updateOptions();
  }

  searchMemberChange = (text) => {
    this.setState({memberSearch: text, searchLoading: true, nextFilteredMember: '', searchResults: []});
    this.updateOptions();
  };

  searchLocationChange = (text) => {
    this.setState({locationSearch: text, searchLoading: true, nextFilteredMember: '', searchResults: []});
    this.updateOptions();
  };

  updateOptions = debounce(() => this.apiSearch(), DEBOUNCE_MS);

  apiSearch = () => {
    let filteredParams = groupMemberSearchURLFormatter(this.state.groupMemberFilters, this.state.groupId);
    groupMemberFiltersStorage.setFilter(this.state.groupMemberFilters)
    if (this.state.memberSearch === '' && this.state.locationSearch === '' && !filteredParams) {
      return this.setState({searching: false});
    }
    this.setState({searching: true});
    rwbApi
      .searchGroupMembers({
        nameInput: this.state.memberSearch, locationInput: this.state.locationSearch,
        groupId: this.state.groupId, eventId: this.state.eventId,
        memberFilters: filteredParams,
        next: this.state.nextFilteredMember,
        returnFullObject: true 
      })
      .then((result) => {
        this.setState({
          resultsCount: result.count,
          searchLoading: false,
          loadingMore: false,
          loading: false,
          memberCount: result.count,
          nextFilteredMember: result.next,
          canLoadMore: result.next !== null,
          searchResults: [...this.state.searchResults, ...result.data]
        })
      })
      .catch((err) => {
        alert(`Team RWB: ${USER_SEARCH_ERROR}`);
        this.setState({searchResults: [], searchLoading: false});
      });
  };

  selectFromDropdown = (value, key) => {
    this.setState(prevState => {
      const newSet = new Set(prevState.groupMemberFilters[key]);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return {
        groupMemberFilters: {
          ...prevState.groupMemberFilters,
          [key]: newSet,
        },
      };
    });
    this.setState({searchLoading: true, nextFilteredMember: '', searchResults: []});
    this.updateOptions();
  };
  
  toggleLozenge = (key) => {
    this.setState(prevState => ({
      groupMemberFilters: {
        ...prevState.groupMemberFilters,
        [key]: !prevState.groupMemberFilters[key],
      },
    }));
    this.setState({searchLoading: true, nextFilteredMember: '', searchResults: []});
    this.updateOptions();
  };

  hideMember = (index, listSection) => {
    if (listSection === 'chapter captain') listSection = 'chapterCaptains';
    let list = this.state[listSection];
    list.splice(index, 1);
    this.setState({[listSection]: list});
  }

  hideSearchMember = (index) => {
    let list = this.state.searchResults;
    list.splice(index, 1);
    this.setState({searchResults: list});
  }

  headerOnBack = () => {
    if (this.isModal) {
      this.props.close();
    }
    else {
      this.props.history.goBack();
    }
  }

  handleUpdatedELInfo = (updatedEL) => {
    const updatedId = updatedEL.id;
    let newAdmins = this.state.admins.map((admin) => {
      if (admin.id === updatedId) return updatedEL;
      return admin;
    });
    let newCaptains = this.state.chapterCaptains.map((captain) => {
      if (captain.id === updatedId) return updatedEL;
      return captain;
    });
    let newModerators = this.state.moderators.map((moderator) => {
      if (moderator.id === updatedId) return updatedEL;
      return moderator;
    });
    let newMembers = this.state.members.map((member) => {
      if (member.id === updatedId) return updatedEL;
      return member;
    });
    this.setState({admins: newAdmins, chapterCaptains: newCaptains, moderators: newModerators, members: newMembers});
  }

  render() {
    const {
      groupName,
      admins,
      chapterCaptains,
      moderators,
      members,
      loading,
      loadingMore,
      memberSearch,
      locationSearch,
      searchingMembers,
      searchingLocations,
      searchResults,
      resultsCount,
      searchLoading,
      minifiedCard,
      eventId,
      groupMemberFilters,
      searching,
    } = this.state;
    const privilegedIds = new Set([...admins, ...chapterCaptains, ...moderators].map(user => user.id));
    return (
      <div id="root">
        {!this.isModal && 
          <Header
            title={minifiedCard ? 'Check In Member' : 'Members'}
            subtitle={groupName}
            onBack={() => this.headerOnBack()}
          />
        }
        {loading ? (
          <Loading size={100} color={'var(--white)'} loading={true} right />
        ) : (
          <>
            <div className={styles.searchContainer}>
              <SearchBar
                value={memberSearch}
                onChange={this.searchMemberChange}
                onSubmit={() => {}}
                onClearSearch={this.handleClearMemberSearch}
                placeholder={'Search for Members'}
                onFocus={() => this.setState({searchingMembers: true})}
                searching={searchingMembers}
              />
              {!this.isModal && 
                <SearchBar
                  value={locationSearch}
                  onChange={this.searchLocationChange}
                  onSubmit={() => {}}
                  onClearSearch={this.handleClearLocationSearch}
                  placeholder={'Search City or Zip'}
                  onFocus={() => this.setState({searchingLocations: true})}
                  searching={searchingLocations}
                  leftElement={<LocationIcon className={styles.locationIcon}/>}
                  noLeftRadius={true}
                />
              }
            </div>
            <GroupLozenge selectFromDropdown={this.selectFromDropdown} toggleLozenge={this.toggleLozenge} groupMemberFilters={groupMemberFilters} onSubmit={this.handleFilterSubmit} isAdminOrModerator={this.isAdminOrModerator}/>
            {searching ? (
              <>
                <p className={`${'titleSubheader'} ${styles.label}`}>{resultsCount} RESULTS</p>
                <UsersList
                  usersSuggestions={searchResults}
                  loadingUsers={searchLoading}
                  onSelect={this.handleUserSelect}
                  search={searching}
                  minifiedCard={minifiedCard}
                  eventId={eventId}
                  onCheckIn={this.hideSearchMember}
                  groupName={groupName}
                  isGroupList={true}
                  privilegedIds={privilegedIds}
                  isGroupAdmin={this.isGroupAdmin}
                  handleUpdatedELInfo={this.handleUpdatedELInfo}
                />
              </>
            ) : (
              <>
                {this.props.self &&
                <GroupMembersList
                  label="self"
                  isSponsor={false}
                  data={[this.props.self]}
                  onSelect={this.handleUserSelect}
                  minifiedCard={minifiedCard}
                  eventId={eventId}
                  isGroupList={true}
                  groupName={groupName}
                  privilegedIds={privilegedIds}
                />}
                {admins?.length > 0 &&
                <GroupMembersList
                  label="admins"
                  isSponsor={this.props.location?.state?.groupType === 'sponsor'}
                  data={admins}
                  privilegedIds={privilegedIds}
                  onSelect={this.handleUserSelect}
                  minifiedCard={minifiedCard}
                  eventId={eventId}
                  onCheckIn={this.hideMember}
                  isGroupList={true}
                  groupName={groupName}
                  isGroupAdmin={this.isGroupAdmin}
                />}
                {/* do not show chapter captain for checking in member list */}
                {!eventId && chapterCaptains?.length > 0 &&
                <GroupMembersList
                  label="chapter captain"
                  data={chapterCaptains}
                  privilegedIds={privilegedIds}
                  onSelect={this.handleUserSelect}
                  minifiedCard={minifiedCard}
                  eventId={eventId}
                  onCheckIn={this.hideMember}
                  isGroupList={true}
                  groupName={groupName}
                  isGroupAdmin={this.isGroupAdmin}
                />}
                {moderators?.length > 0 &&
                <GroupMembersList
                  label="moderators"
                  data={moderators}
                  privilegedIds={privilegedIds}
                  onSelect={this.handleUserSelect}
                  minifiedCard={minifiedCard}
                  eventId={eventId}
                  onCheckIn={this.hideMember}
                  isGroupList={true}
                  groupName={groupName}
                  isGroupAdmin={this.isGroupAdmin}
                />}
                <GroupMembersList
                  label="members"
                  data={members}
                  privilegedIds={privilegedIds}
                  onSelect={this.handleUserSelect}
                  minifiedCard={minifiedCard}
                  eventId={eventId}
                  onCheckIn={this.hideMember}
                  isGroupList={true}
                  groupName={groupName}
                  isGroupAdmin={this.isGroupAdmin}
                />

                {loadingMore && (
                  <Loading
                    size={60}
                    color={'var(--grey20)'}
                    loading={true}
                    footerLoading
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default GroupMembers;
