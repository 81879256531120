import {IconButton, Paper, Toolbar, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {NO_BLOCKED_USERS} from '../../../../shared/constants/OtherMessages';
import { userProfile } from '../../../../shared/models/UserProfile';
import Loading from '../Loading';
import ChevronBackIcon from '../svgs/ChevronBackIcon';
import styles from './BlockedUsers.module.css';
import BlockedUsersList from './BlockedUsersList';

const BlockedUsers = () => {
  const [data, setData] = useState(userProfile.getUpdatedBlockedMap());

  const getUpdatedBlockMap = () => {
    setData(userProfile.getUpdatedBlockedMap());
  }

  const history = useHistory();
  return (
    <div className={styles.container}>
      <Paper className={styles.root}>
        <Toolbar className={styles.toolbar}>
          <IconButton
            edge="start"
            className={styles.menuButton}
            color="inherit"
            onClick={() => history.goBack()}>
            <ChevronBackIcon />
          </IconButton>
          <Typography variant="h6" className={styles.title}>
            Blocked Users
          </Typography>
        </Toolbar>
      </Paper>
      <div className={styles.contentContainer}>
        {data.size > 0 ? (
          <BlockedUsersList onUserRemoved={getUpdatedBlockMap} data={data} />
        ) : (
          NO_BLOCKED_USERS
        )}
        <div className={styles.verticalMargin}></div>
      </div>
    </div>
  );
};

export default BlockedUsers;
