import React, { useState, useEffect } from 'react';
import RWBUserImages from '../RWBUserImages';
import styles from './ProfileDetail.module.css';
import { IconButton, Grid } from '@material-ui/core';
import SettingsIcon from '../svgs/SettingsIcon';
import EditIcon from '../svgs/EditIcon';
import { userProfile } from '../../../../shared/models/UserProfile';
import { rwbApi } from '../../../../shared/apis/api';
import FeedList from '../feed/FeedList';
import { ClipLoader } from 'react-spinners';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import ExpandingText from '../ExpandingText';
import { MILITARY_STATUSES } from '../../../../shared/constants/MilitaryStatusSlugs';
import { urlToParamsObj } from '../../../../shared/utils/Helpers';

import {
  logAccessMyEventsAndWorkoutStats,
  logChangeProfileSettings,
  logSelectProfileTab,
  logTakeELS,
  logELSCompleted,
  logViewFollowRelationship,
  webSectionName,
} from '../../../../shared/models/Analytics';
import { hasReachedBottom } from '../../BrowserUtil';
import Loading from '../Loading';
import RWBMark from '../svgs/RWBMark';
import { PROFILE_TAB_LABELS } from '../../../../shared/constants/Labels';
import ChevronRightIcon from '../svgs/ChevronRightIcon';
import { insertLocaleSeperator } from '../../../../shared/utils/ChallengeHelpers';
import ChallengeBadges from './ChallengeBadges';
import { displayMilitaryInfo } from '../../../../shared/utils/Helpers';
import { MY_PROFILE_TABS } from '../../../../shared/constants/ProflieTabs';
import CustomModal from '../events/CustomModal';
import ReusableTabs from '../ReusableTabs';
import ELSChart from '../els/ELSChart.react';
import ELSLineGraph from '../els/ELSLineGraph.react';
import { ELSScoreBreakdown } from '../els/ELSScoreBreakdown.react';
import StyledText from '../els/SyledText.react';
import { breakdown, filterELSKeys, generateEnrichmentText } from '../../../../shared/utils/ELSHelper';
import { MANAGING_PROFILE_AND_SETTINGS } from '../../../../shared/constants/newAnalytics';
import EagleLeaderInfo from '../../../../shared/models/EagleLeaderInfo';

export default function ProfileDetail() {
  const location = useLocation();
  const [isFollowSummaryLoading, setIsFollowSummaryLoading] = useState(true);
  const [isInitialFeedLoading, setIsInitialFeedloading] = useState(true);
  const [isMoreFeedLoading, setIsMoreFeedLoading] = useState(false);
  const [isBadgesLoading, setIsBadgesLoading] = useState(true);
  const [isMetricsLoading, setIsMetricsLoading] = useState(true);
  const [followingAmount, setFollowingAmount] = useState('-');
  const [followersAmount, setFollowersAmount] = useState('-');
  const [userFeed, setUserFeed] = useState([]);
  const [retrievedLastPost, setRetrievedLastPost] = useState(false);
  const [userBadges, setUserBadges] = useState([]);
  const [userMetrics, setUserMetrics] = useState({});
  const [ELSPending, setELSPending] = useState(false);
  const [showELSModal, setShowELSModal] = useState(false);
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 0); //change to 0 for default, keeping as 2 for testing health tabs
  const [ELSScore, setELSScore] = useState(undefined);
  const [ELSData, setELSData] = useState([]);
  const [ELSHealthInfo, setELSHealthInfo] = useState([]);
  const [ELSStats, setELSStats] = useState({});
  const user = userProfile.getUserProfile();
  const match = useRouteMatch();
  const history = useHistory();
  let eagleLeaderInfo = new EagleLeaderInfo(user.eagle_leader_info);
  useEffect(() => {
    getUsersFollowSummary(user.id);
    getFeed();
    getBadges(userProfile.getUserProfile().id);
    getWorkoutMetrics();
    getELSSurveyResponses();
    getELSStats();
    // TODO: Deep link handling for initial tab
    logSelectProfileTab(MY_PROFILE_TABS[0].name, MANAGING_PROFILE_AND_SETTINGS.events.VIEW_BIO_AND_FEED_TAB);
  }, []);

  useEffect(() => {
    // ensure we only track scrolling to load more posts when on the feed tab
    if (activeTab === 0) {
      window.addEventListener('scroll', trackScrolling);
      return () => {
        window.removeEventListener('scroll', trackScrolling);
      };
    }
  }, [userFeed, activeTab]);

  useEffect(() => {
    const params = urlToParamsObj(window.location.href);
    if (params['initial_tab'] === 'health')
      setActiveTab(2);
    else if (params['initial_tab'] === 'fitness')
      setActiveTab(1);
    else if (params['initial_tab'] === 'bio') // default; here for completeness
      setActiveTab(0);

    const checkSubmission = (event) => {
      if (event.data === 'complete') {
        logELSCompleted({section_name: webSectionName()});
        // give the survey 4 seconds to complete and send the request to ensure
        // the processor api request is not interrupted
        setTimeout(() => {
          setShowELSModal(false);
        }, 4000);
        setELSPending(true);
        rwbApi.submitSurveyCompleted().then((surveyComplete) => {
          setELSPending(false);
          getELSSurveyResponses();
        })
      };
    };

    window.addEventListener('message', checkSubmission);

    return () => {
      window.removeEventListener('message', checkSubmission);
    };
  }, []);

  useEffect(() => {
    // replacement for setState callback
    if (retrievedLastPost) handleLoadMore();
  }, [retrievedLastPost]);

  const trackScrolling = (event) => {
    event.preventDefault();
    const wrappedElement = document.getElementById('root');
    if (hasReachedBottom(wrappedElement)) {
      setRetrievedLastPost(true);
    }
  };

  const getELSStats = () => {
    return rwbApi.getELSStats().then((stats) => {
      setELSStats(stats);
    });
  }

  const getBadges = (id) => {
    return rwbApi
      .getUserBadges(id)
      .then((result) => {
        setUserBadges(result.data.badges);
        setIsBadgesLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        alert("Error retrieving user's badges.");
        setIsBadgesLoading(false);
      });
  };

  const getWorkoutMetrics = () => {
    return rwbApi
      .getUserMetrics()
      .then((result) => {
        setUserMetrics(result.data);
        setIsMetricsLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        alert("Error retrieving user's metrics.");
        setIsMetricsLoading(false);
      });
  };

  const getUsersFollowSummary = (id) => {
    return rwbApi
      .getFollowSummary(id)
      .then((result) => {
        const followingAmount = result.following;
        const followersAmount = result.followers;
        setFollowingAmount(followingAmount);
        setFollowersAmount(followersAmount);
        setIsFollowSummaryLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        alert("Error retrieving user's following and follower count.");
      });
  };
  const getFeed = () => {
    rwbApi
      .getUserFeed(user.id)
      .then((result) => {
        setUserFeed(result.data.results);
        setIsInitialFeedloading(false);
        if (result.data.results.length === 0) setIsMoreFeedLoading(false);
      })
      .catch((error) => {
        console.warn('error getting feed: ', error);
        alert('There was an error retrieving your feed.');
        setIsInitialFeedloading(false);
      });
  };

  const handleLoadMore = () => {
    const lastPost = userFeed[userFeed.length - 1];
    if (retrievedLastPost && lastPost) {
      setIsMoreFeedLoading(true);
      rwbApi.getUserFeed(user.id, lastPost.id).then((result) => {
        if (result.data.results.length > 0) {
          setUserFeed([...userFeed, ...result.data.results]);
          setIsMoreFeedLoading(false);
          setRetrievedLastPost(false);
        } else setIsMoreFeedLoading(false);
      });
    }
  };

  const getELSSurveyResponses = () => {
    rwbApi
      .getELSSurveys()
      .then((result) => {
        const fiveMostRecentSurveys = result?.data?.slice(-5);
        const mostRecent = fiveMostRecentSurveys?.slice(-1)[0];
        setELSData(fiveMostRecentSurveys);
        setELSScore(mostRecent?.total_score);
        setELSHealthInfo(mostRecent);
      });
  };

  const getELSButtonText = () => {
    return ELSPending ? 'ELS SCORE PENDING' : 'TAKE ELS ASSESSMENT';
  };

  const previousScore = ELSData[ELSData.length - 2]?.total_score
  const lastTestDate = ELSData[ELSData.length - 1]?.survey_response_date;

  return (
    <div id={'root'}>
      {!isFollowSummaryLoading &&
        !isInitialFeedLoading &&
        !isBadgesLoading &&
        !isMetricsLoading ? (
        <>
          <div className={styles.userImageContainer}>
            <RWBUserImages
              coverPhoto={user.cover_photo_url}
              profilePhoto={user.profile_photo_url}
            />
            <div className={styles.navigation}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  history.push({ pathname: `${match.path}/edit`, });
                }}>
                <EditIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  history.push(`${match.path}/settings`);
                }}>
                <SettingsIcon />
              </IconButton>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.nameContainer}>
              <h1>{`${user.first_name} ${user.last_name}`}</h1>
              {eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE && (
                <RWBMark tintColor={'var(--navy)'} width={40} height={18} />
              )}
            </div>
            <div className={styles.nameContainer}>
              <h2>
                {(user?.preferred_chapter && user?.preferred_chapter?.name) ||
                  (user?.home_chapter && user?.home_chapter?.name) ||
                  ''}
              </h2>
            </div>
            <div className={styles.nameContainer}>
              <h2>
                {displayMilitaryInfo(user.military_status, user.military_branch)}
              </h2>
            </div>
            <div className={styles.nameContainer}>
              {eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE && (
                <h5 className={styles.eagleLeaderText}>{eagleLeaderInfo.role}</h5>
              )}
            </div>
            <div className={styles.profileActions}>

              <div
                className={styles.followCount}
                onClick={() => {
                  logViewFollowRelationship({
                    section_name: webSectionName(),
                    click_text: 'followers',
                  });
                  history.push({
                    pathname: `${match.path}/followers`,
                    state: { index: 0 },
                  });
                }}>
                <strong>{followersAmount}</strong>
                followers
              </div>
              <div
                className={styles.followCount}
                onClick={() => {
                  logViewFollowRelationship({
                    click_text: 'following',
                    section_name: webSectionName(),
                  });
                  history.push({
                    pathname: `${match.path}/followers`,
                    state: { index: 1 },
                  });
                }}>
                <strong>{followingAmount}</strong>
                following
              </div>
            </div>

            {/* <p>
              {user.military_status === MILITARY_STATUSES.veteran &&
                `ETS: ${moment(user.military_ets).format('Y')}`}
            </p> */}
          </div>
          <ReusableTabs
            selectedValue={activeTab}
            values={MY_PROFILE_TABS.map(({ name }) => name)}
            onChangeHandler={(key) => {
              if (activeTab !== key) {
                let analyticsEventName = MANAGING_PROFILE_AND_SETTINGS.events.VIEW_BIO_AND_FEED_TAB;
                let clickText = MY_PROFILE_TABS[key].name;
                if (key === 1) analyticsEventName = MANAGING_PROFILE_AND_SETTINGS.events.VIEW_FITNESS_TAB;
                if (key === 2) analyticsEventName = MANAGING_PROFILE_AND_SETTINGS.events.VIEW_HEALTH_TAB;
                logSelectProfileTab(clickText, analyticsEventName);
                history.replace({ ...location, state: {activeTab: key} });
                setActiveTab(key)
              }
            }}
          />
          {activeTab === 0 // feed and user summary
            ? <>
              <div className={styles.contentContainer}>
                <p>
                  {user.profile_bio}
                </p>
              </div>
              <FeedList
                userFeed={userFeed}
                origin={'user'}
                mergeNewPost={getFeed}
              />
              <Grid container justifyContent={'center'}>
                <ClipLoader
                  size={60}
                  color={'var(--grey20)'}
                  loading={isMoreFeedLoading}
                />
              </Grid>
            </>
            : activeTab === 1 // Metrics/fitness
              ? <div className={styles.contentContainer}>
                {/* Badges */}
                <ChallengeBadges userBadges={userBadges} myProfile={true} />
                <br />
                <div className={styles.badgeStatLink}>
                  <h3
                    onClick={() => {
                      logAccessMyEventsAndWorkoutStats();
                      history.push({
                        pathname: `${match.path}/workout-log`,
                        state: { userMetrics },
                      });
                    }}
                    className={styles.healthLink}>
                    {PROFILE_TAB_LABELS.EVENT_AND_WORKOUT_STATS}
                  </h3>
                  <ChevronRightIcon />
                </div>
                <div className={styles.workoutContainer}>
                  <div>
                    <h1 className={styles.stat}>
                      {insertLocaleSeperator(
                        parseInt(userMetrics.all_time_challenges_participated),
                      )}
                    </h1>
                    <p className={styles.statName}>
                      {PROFILE_TAB_LABELS.ALL_TIME_CHALLENGES}
                    </p>
                  </div>
                  <div>
                    <h1 className={styles.stat}>
                      {insertLocaleSeperator(
                        parseInt(userMetrics.ytd_events_participated),
                      )}
                    </h1>
                    <p className={styles.statName}>
                      {PROFILE_TAB_LABELS.YTD_EVENTS}
                    </p>
                  </div>
                  <div>
                    <h1 className={styles.stat}>
                      {insertLocaleSeperator(
                        parseInt(userMetrics.mtd_events_participated),
                      )}
                    </h1>
                    <p className={styles.statName}>
                      {PROFILE_TAB_LABELS.MTD_EVENTS}
                    </p>
                  </div>
                </div>
              </div> :
              activeTab === 2 // health/els tab
                ? <div style={{ marginTop: 15 }}>
                  <ELSChart value={ELSScore} average={ELSStats?.total_score || 0} pending={ELSPending} />
                  {ELSData.length >= 2 && <ELSLineGraph data={ELSData} />}
                  <CustomModal
                    items={[]}
                    isOpen={showELSModal}
                    selectedValue={null}
                    placeholder={''}
                    modalHandler={() => setShowELSModal(false)}
                    onSelect={() => null}
                    link={`https://www.tfaforms.com/5069319?pv1=${user.salesforce_contact_id}`}
                  />
                  <div style={{ marginLeft: 40, marginRight: 40 }}>
                    <StyledText rawText={generateEnrichmentText(user.first_name, ELSScore, lastTestDate, ELSStats?.total_score, previousScore, ELSPending)} />
                  </div>
                  <div className={styles.contentContainer} style={{ borderBottom: 0 }}>
                    <div
                      className={styles.editButton}
                      onClick={() => {
                        logTakeELS({
                          section_name: webSectionName,
                          click_text: getELSButtonText(),                    
                        });
                        setShowELSModal(true);
                      }}>
                      {getELSButtonText()}
                    </div>
                  </div>
                  {ELSScore !== undefined && <h3 style={{padding: 10, display: 'block'}}>{breakdown}</h3>}
                  {ELSScore !== undefined && ELSHealthInfo && Object.entries(filterELSKeys(ELSHealthInfo)).map(([key, value]) => {
                    return (
                      <ELSScoreBreakdown
                        title={key}
                        score={value}
                        average={ELSStats[key]}
                        lineGraphScores={ELSData}
                      />
                    )
                  })}
                </div>
                : null
          }
        </>
      ) : (
        <Loading size={100} color={'var(--white)'} loading={true} right />
      )}
    </div>
  );
}
