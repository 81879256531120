import React from 'react';
import { userProfile } from '../../../../shared/models/UserProfile';
import { generateTraceline } from '../../../../shared/utils/MessagesHelpers';
import messageStyles from './Message.module.css';
import styles from './ReplyMessage.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import renderMessageText from './renderMessageText.react';

/**
 * 
 * @param {Object} replyInfo -  Reply info - the entire reply message
 * @param {int} replyInfo.channelId - idea of the chat channel the message is in
 * @param {string} replyInfo.message - the contents of the reply
 * @param {string} replyInfo.reply_message_id - the id of the message being replied to (need to adjust this to ivs id, currently is an int)
 * @param {Object} replyInfo.sender - the sender information with id, name, and profile picture
 * @param {int} messageSenderId - id of the user who sent the message containing the replyInfo
 * @param {array} chatMembers - list of user profile objects with name and id
 * 
 */


const ReplyMessage = ({onPress, replyInfo, messageSenderId, chatMembers}) => {
    const curUserId = userProfile.getUserProfile().id;
    const isOthersMessage = replyInfo.profile_id !== curUserId;
    const currentReplyToSelf = !isOthersMessage && messageSenderId === curUserId;
    const currentReplyToOthers = isOthersMessage && messageSenderId === curUserId;
    const othersReplyToOthers = isOthersMessage && messageSenderId !== curUserId;
    const othersReplyToCurrent = !isOthersMessage && messageSenderId !== curUserId;

    return (
        <div className={styles.container} onClick={() => onPress(replyInfo)}>
            <div className={styles.imgAndTextContainer} style={{justifyContent: isOthersMessage ? 'flex-start' : 'flex-end' }}>
                {isOthersMessage &&
                    <div className={styles.imgWrapper}>
                        <img src={replyInfo?.sender?.profile_photo_url || DefaultProfileImg} />
                    </div>
                }
                <div className={`${messageStyles.messageContainer} ${styles.replyTextContainer}`}>
                    <p className={styles.replyText} style={{color: isOthersMessage ? 'var(--grey)' : 'var(--navy)'}}>{renderMessageText({text: replyInfo.text, users: chatMembers, sentByCurrentUser: curUserId === replyInfo?.sender?.id, isAReply: true})}</p>
                </div>
            </div>
            <div 
                className={`${generateTraceline(curUserId, replyInfo.profile_id, messageSenderId, styles)}`} 
                style={{
                    height: currentReplyToSelf ? 8 : othersReplyToOthers ? 'calc(100% - 4px)' : othersReplyToCurrent ? 'calc(50% + 30px)' : 'calc(100% + 21px)',
                    left: currentReplyToSelf ? 'calc(100% - 20px)' : 29,
                    top: currentReplyToSelf ? 'calc(100% + 4px)' : othersReplyToCurrent ? 'calc(50% - 1px)' : 34,
                }} 
            />
        </div>
    )
}

export default ReplyMessage;
