import React, {useState} from 'react';
import CreatePost from './CreatePost';
import styles from './CreatePostButton.module.css';
import {MdAdd as AddIcon} from 'react-icons/md';
import { toggleOverflow } from '../toggleOverflow.react';

const CreatePostButton = ({
  eventID = null,
  groupID = null,
  challengeID = null,
  activitySubType,
  eventRecordType,
  groupRecordType,
  type,
  mergeNewPost,
  eventHost,
}) => {
  const [createPostModal, setCreatePostModal] = useState(false);

  const openCloseModalHandler = () => {
    setCreatePostModal((prevState) => {
      toggleOverflow(!prevState);
      return !prevState;
    });
  }

  return (
    <>
      <div
        className='floatingBtnContainer'
        onClick={openCloseModalHandler}>
        <AddIcon className={styles.addIcon} />
      </div>
      {createPostModal && (
        <CreatePost
          type={type}
          eventID={eventID}
          groupID={groupID}
          challengeID={challengeID}
          closeModalHandler={openCloseModalHandler}
          mergeNewPost={mergeNewPost}
          activitySubType={activitySubType}
          eventRecordType={eventRecordType}
          groupRecordType={groupRecordType}
          eventHost={eventHost}
        />
      )}
    </>
  );
};

export default CreatePostButton;
