import React from 'react';
import styles from './PhotoView.module.css';
import XIcon from '../svgs/XIcon';
import LikeIcon from '../svgs/LikeIcon';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';


const PhotoView = ({
  media_url,
  post_images,
  index,
  onModalClose,
  liked,
  likeAmount,
  onLikeClicked,
  setSelectedImage,
}) => {

  return (
  <div className={styles.imgModalContainer}>
    <div className={styles.modalXContainer} onClick={onModalClose}>
      <XIcon width={32} height={32} tintColor={'var(--white)'} />
    </div>
    <div className={styles.postImageContainer}>
      {<img className={styles.postImage} src={media_url ? media_url : post_images?.length ? post_images[index] : null} alt="User Post Image" />}
    </div>
    {post_images?.length && index !== 0 ? <MdChevronLeft className={styles.prevButton} onClick={() => setSelectedImage(index - 1)}/> : null}
    {post_images?.length && index !== post_images.length -1 ?<MdChevronRight className={styles.nextButton} onClick={() => setSelectedImage(index + 1)}/> : null}
    {post_images.length !== 1 ?
    <div className={styles.carouselIndicators}>
    {post_images.map((_, i) => (
      <div
        key={i}
        className={`${styles.indicator} ${i === index ? styles.active : null}`}
        onClick={() => setSelectedImage(i)}
      />
    ))}
    </div>: null}
    {onLikeClicked && 
      <div className={styles.likeContainer} onClick={onLikeClicked}>
        <LikeIcon
          className={styles.likeIcon}
          tintColor={liked ? 'var(--magenta)' : null}
        />
        <p style={{color: 'var(--white)'}}>{likeAmount > 0 && likeAmount}</p>
      </div>
    }
  </div>
  )
}

export default PhotoView;
