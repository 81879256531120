import React from 'react';
import PropTypes from 'prop-types';

function MessagesIcon(props) {
  return (
    <>
      <svg
        className={props.className}
        viewBox="0 0 24 24"
        width={props.width}
        height={props.height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1483">
          <path
            id="Vector"
            d="M5.25 3C4.00898 3 3 4.00903 3 5.2501V15.3755C3 16.6166 4.00898 17.6256 5.25 17.6256H8.625V20.4383C8.625 20.6527 8.74453 20.8461 8.93438 20.941C9.12422 21.0359 9.35273 21.0148 9.525 20.8883L13.8738 17.6256H18.75C19.991 17.6256 21 16.6166 21 15.3755V5.2501C21 4.00903 19.991 3 18.75 3H5.25Z"
            stroke={props.strokeColor ? props.strokeColor : '#828588'}
            strokeWidth="2"
            fill={props.tintColor}
          />
        </g>
      </svg>
    </>
  );
}

MessagesIcon.defaultProps = {
  width: 24,
  height: 24,
  tintColor: '#828588',
  filledIcon: true,
};

MessagesIcon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tintColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  filledIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default MessagesIcon;
