import {
  ChatRoom,
  DeleteMessageRequest,
  DisconnectUserRequest,
  SendMessageRequest,
} from 'amazon-ivs-chat-messaging';
import imageHandler from '../image_handling/ImageHandler';
import { rwbApi } from '../apis/api';
import { CHAT_ROLES, CHAT_STATES, CREATE_CHANNEL_MESSAGES } from '../constants/MessagesKeys';
import { MESSAGE_STATUSES } from '../utils/MessagesHelpers';
import { userProfile } from './UserProfile';
import isUserViewingChat from '../utils/isUserViewingChat';

const MAX_RETRIES = 3;
const MEDIA_MESSAGE_FLAG = 'MEDIA-MESSAGE'; // IVS requires content, and since images are separate messages, send this and ignore it

class ChatManager {
  constructor() {
    if (!ChatManager.instance) {
      this.initPromise = null;
      this.pollInterval = null;
      this.isPolling = false;
      this.activeRooms = {}; // real time
      this.chatChannels = {}; // meta data for list (primarily for last read messages, unread count, etc)
      this.onMessageReceived = undefined;
      this.onMessageDeleted = undefined;
      this.sender = null;
      this.hasAnyUnreadMessages = null;
      this.subscribers = new Set();
      ChatManager.instance = this;
    }

    return ChatManager.instance;
  }

  async getAndJoinAllChannels() {
    const channels = await this.getAndStoreChannels();
    await Promise.all(channels.map(channel => {
      if (channel.state !== CHAT_STATES.left)
        this.joinRoom(channel.id)
    }));
  }

  startChannelPolling() {
    if (this.pollInterval) return;
  
    this.pollInterval = setInterval(async () => {
      if (this.isPolling) return;
  
      this.isPolling = true;
  
      try {
        await this.getAndJoinAllChannels();
      } catch (error) {
        console.warn("Error during channel polling:", error);
      } finally {
        this.isPolling = false;
      }
    }, 30000); // every 30 seconds
  }

  stopChannelPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.isPolling = false;
      this.pollInterval = null;
    }
  }  

  async init() {
    if (this.initPromise) {
      return this.initPromise; // Prevent multiple init calls
    }

    this.initPromise = new Promise(async (resolve, reject) => {
      try {
        this.sender = userProfile.getUserProfile();
        await this.getAndJoinAllChannels();
        this.startChannelPolling();
        resolve();
      } catch (error) {
        reject(error);
      }
    });

    return this.initPromise;
  }

  setGlobalMessageHandler(callback) {
    this.onMessageReceived = callback;
  }

  setGlobaMessageDeleteHandler(callback) {
    this.onMessageDeleted = callback;
  }

  async addMembers(channelId, userIds = []) {
    // TODO, might move up this mapper elsewhere to specify a none member roll
    // default active state will be removed
    let userObjs = userIds.map((val) => ({ profile: val, role: CHAT_ROLES.member, state: CHAT_STATES.active }));
    try {
      const memberData = await rwbApi.addMembersToChatChannel(channelId, userObjs);
      if (!this.chatChannels[channelId]) {
        // creator only to match format of other users
        const members = [{
          profile: {
            ...this.sender, profile_id: this.sender.id,
            name: `${this.sender.first_name} ${this.sender.last_name}`
          }
        }];
        this.chatChannels[channelId] = {
          id: channelId,
          state: CHAT_STATES.active,
          displayName: '',
          group_chat: userIds?.length > 1,
          members,
        };
      }
      // regenerate when adding a member or generate initially on new cha
      // other ivs calls return profile_id for the user and name, match the other formats
      const formattedNewMembers = memberData.map((member) => {
        return {
          profile: {
            ...member.user,
            profile_id: member.user.id,
            name: `${member.user.first_name} ${member.user.last_name}`
          }
        };
      });
      
      const currentMembers = this.chatChannels[channelId]?.members || [];
      const {allMemberProfiles, memberProfilesInChat} = this.createActiveAndAllProfileLists([...currentMembers, ...formattedNewMembers]);
      // only update the display name if it is not custom
      if (!this.chatChannels[channelId].hasCustomDisplayName) this.chatChannels[channelId].displayName = this.createChannelDisplayName([...currentMembers, ...formattedNewMembers]);
      this.chatChannels[channelId].members = [...currentMembers, ...formattedNewMembers];
      this.chatChannels[channelId].taggableMembers = memberProfilesInChat;
      this.chatChannels[channelId].allMemberProfiles = allMemberProfiles;
      return;
    } catch (error) {
      console.error('Error adding members to the chat room: ', error);
    }
  }

  async createRoom(userIds = []) {
    try {
      userIds = userIds.map((id) => parseInt(id));
      const isGroupChat = userIds.length > 1;
      const channel = await rwbApi.createChatChannel(userIds, isGroupChat);
      if (channel.message === CREATE_CHANNEL_MESSAGES.new)
        await this.addMembers(channel.channel_id, userIds);
      return await this.joinRoom(channel.channel_id, isGroupChat);
    } catch (error) {
      console.error('Error creating room:', error);
      throw new Error(error);
    }
  }

  messageReceiverFormatter(content) {
    if (!content || content === MEDIA_MESSAGE_FLAG) return '';
    return JSON.parse(content).replace(/\\n/g, '\n'); // match back end and adjust formatting to ensure we get new lines rendered properly
  }

  getSenderInfo(senderId, channelId){
    const members = this.chatChannels[channelId]?.members || [];
    // db method
    const specificMember = members?.find((member) => {
      return member?.profile?.id === senderId;
    });
    return specificMember?.profile;
  }

  subscribe(callback) {
    this.subscribers?.add(callback);
    return () => this.subscribers.delete(callback);
  }

  notifySubscribers() {
    this.hasAnyUnreadMessages = Object.values(this.chatChannels).some(
      (chat) => chat.hasUnreadMessage
    );
    this.subscribers?.forEach((callback) => callback(this.hasAnyUnreadMessages));
  }

  updateUnreadMessages(channelId, message) {
    if (!this.chatChannels[channelId]) return;
    const isViewing = isUserViewingChat(channelId);
    // only count unread messages if it is from a different user, the user is not on the screen, and the message is not an edit/reaction
    if (!isViewing && message.sender.userId !== this.sender.id && !message?.attributes?.previousMessageId) {
      this.chatChannels[channelId].hasUnreadMessage = true;
      this.notifySubscribers();
    }
  }

  markAsRead(channelId, lastSeenId) {
    if (this.chatChannels[channelId]) {
      this.chatChannels[channelId].hasUnreadMessage = false;
      this.notifySubscribers();
      rwbApi.updateMemberChatValue(channelId, this.sender.id, {last_seen_message_xid: lastSeenId});
    }
  }

  hasUnreadChats() {
    return this.hasAnyUnreadMessages;
  }

  // Create or Join a Chat Room
  async joinRoom(channelId, isGroupChat) {
    if (this.activeRooms[channelId]) {
      return this.activeRooms[channelId];
    }
    const room = new ChatRoom({
      regionOrUrl: 'us-east-1',
      tokenProvider: async () => (await rwbApi.getChatChannelToken(channelId)).token,
      maxReconnectAttempts: MAX_RETRIES,

    });
    if (!this.chatChannels[channelId]) {
      this.chatChannels[channelId] = {
        id: channelId,
        state: CHAT_STATES.active,
        group_chat: isGroupChat,
      };
    }
    room.channelId = channelId;

    room.addListener('message', (message) => {
      const formattedText = this.messageReceiverFormatter(message.content);
      message.text = formattedText;
      const imageUrls = message?.attributes?.imageUrls ? JSON.parse(message?.attributes?.imageUrls) : [];
      message.images = imageUrls;
      message.open_graph = message?.attributes?.open_graph ? JSON.parse(message.attributes.open_graph) : null;
      message.editInfo = message?.attributes?.editInfo ? JSON.parse(message.attributes.editInfo) : null;
      let formattedReply = null;
      if (message?.attributes?.replyInfo) {
        formattedReply = JSON.parse(message?.attributes?.replyInfo);
      }
      message.reply_message = formattedReply;
      let formattedEmoji = null;
      if (message?.attributes?.emojiInfo) {
        formattedEmoji = JSON.parse(message?.attributes?.emojiInfo);
      }
      message.emojiInfo = formattedEmoji;
      message.previousMessageId = message.attributes.previousMessageId; // edits and emojis
      this.updateUnreadMessages(channelId, message);
      // edit can have open graph, replace if needed.
      this.updateLatestReceived(channelId, message);
      if (this.onMessageReceived) {
        message.sender = this.getSenderInfo(parseInt(message.sender.userId), channelId);
        message.created = message.sendTime.toISOString();
        this.onMessageReceived({ channelId, message });
      }
      // Handle new message (e.g., update UI or notify user)
    });

    room.addListener('messageDelete', (message) => {
      if (this.onMessageDeleted) {
        this.onMessageDeleted({ channelId, id: message.messageId });
      }
    });

    return new Promise((resolve, reject) => {
      room.addListener('connect', () => {
        this.activeRooms[channelId] = room;
        resolve(room);
      });

      room.addListener('error', (error) => {
        reject(error);
      });

      room.addListener('disconnect', () => {
        // potentially add .clearConnection()
        reject(new Error(`Disconnected from channel: ${channelId}`));
      });

      try {
        room.connect();
      } catch (error) {
        reject(error);
      }
    }).catch((err) => {
      console.warn('err in listener adder: ', err);
    });
  }

  async sendImageMessage(room, channelId, images, localId) {
    const uploadedImages = await imageHandler(images, 'message'); // array of objects {kind: "image", url: "https://s3.amazonaws.com/..."}
    if (uploadedImages.length === 0) return;
    // empty string since we only want the images as it is a separate message
    const request = new SendMessageRequest('MEDIA-MESSAGE',
      {
        imageUrls: JSON.stringify(uploadedImages),
        localId: `image${localId}`,
      }
    );
    try {
      const awsMessage = await room.sendMessage(request);
      rwbApi.submitMessageToDb(awsMessage.id, { open_graph: null, text: '', media: uploadedImages, channel_id: channelId, reply_message_id: null });
      return MESSAGE_STATUSES.success;
    } catch (error) {
      console.warn('error sending message: ', error);
      return MESSAGE_STATUSES.failure;
    }
  }

  formatExtraData(content) {
    const { replyingInfo } = content;
    let cleanedReplyInfo = {};
    // message content (text, mentions, images, urls), id for linking to, replied user id and profile image
    if (replyingInfo?.xid) {
      cleanedReplyInfo = {
        xid: replyingInfo.xid,
        channelId: replyingInfo.channel_id, // might not be needed
        text: replyingInfo?.text || `image${replyingInfo?.images?.length === 1 ? '' : 's'}`, // perhaps set a max character limit and adjust the images to be the has x images message
        profile_id: replyingInfo.sender.id,
        sender: {
          first_name: replyingInfo.sender.first_name,
          last_name: replyingInfo.sender.last_name,
          profile_photo_url: replyingInfo.sender.profile_photo_url,
          id: replyingInfo.sender.id,
        }
      }
    }
    return {
      cleanedReplyInfo,
    }
  }

  // Send a Message
  async sendMessage(channelId, content) {
    const { text, images, open_graph, editInfo, replyingInfo, emojiInfo } = content;
    let room = this.activeRooms[channelId];
    const localId = content.xid || null; // Include the local temporary id if available
    if (!room) {
      try {
        room = await this.joinRoom(channelId);
      } catch (error) {
        console.warn(`Failed to join the room`);
      }
      if (!room) {
        console.warn(`Unable to join channel ${channelId} after ${MAX_RETRIES} attempts.`);
        return MESSAGE_STATUSES.failure;
      }
    }
    if (images?.length > 0)
      await this.sendImageMessage(room, channelId, images, localId);
    if (!text) return; // prevent sending a blank string for image only messages
    // keep the new lines to ensure they can be dispalyed on sent
    const {cleanedReplyInfo} = this.formatExtraData(content);
    let attributes = {
      open_graph: open_graph ? JSON.stringify(open_graph) : null,
      editInfo: editInfo ? JSON.stringify(editInfo) : null,
      replyInfo: cleanedReplyInfo ? JSON.stringify(cleanedReplyInfo) : null,
      emojiInfo: emojiInfo ? JSON.stringify(emojiInfo) : null,
      previousMessageId: editInfo?.messageId || emojiInfo?.messageId, // id of the message being updated
      localId: localId, // pass along the temporary id
    }
    const request = new SendMessageRequest(JSON.stringify(text), attributes);
    try {
      const awsMessage = await room.sendMessage(request);
      if (!editInfo && !emojiInfo) rwbApi.submitMessageToDb(awsMessage.id, { text, open_graph, media: [], channel_id: channelId, reply_message_id: replyingInfo?.xid || null });
      return MESSAGE_STATUSES.success;
    } catch (error) {
      console.warn('error sending message: ', error);
      return MESSAGE_STATUSES.failure;
    }
  }

  // Remove one or multiple rooms so they do not appear
  async removeRooms(channelIds) {
    try {
      rwbApi.deleteChats(channelIds);
      channelIds?.forEach(async (channelId) => {
        if (this.chatChannels[channelId]) {
          this.chatChannels[channelId].state = CHAT_STATES.removed;
        }
      })
      return this.chatChannels;
    } catch (error) {
      console.warn(`Unable to remove chats ${error}`);
      throw Error(error);
    }
  }

  // Leave a Channel
  async leaveRoom(channelId) {
    try {
      await this.updateMemberChatState(channelId, CHAT_STATES.left);
      if (this.activeRooms[channelId]) {
        this.activeRooms[channelId].disconnect();
      }
      return this.chatChannels;
    } catch (error) {
      console.warn(`Unable to leave chat ${error}`);
      throw Error(error);
    }
  }

  async permanentlyLeaveAllRooms() {
    if (!this.chatChannels) {
        return;
    }

    const channelIds = Object.values(this.chatChannels || {}).map(channel => channel.id);

    this.chatChannels = {};

    channelIds.forEach(async (channelId) => {
        if (this.activeRooms?.[channelId]) {
            this.activeRooms[channelId].disconnect();
        }
    });
}


  createChannelDisplayName(channelMembers) {
    if (!channelMembers) return '';
    // TODO: Adjust names when adding a user to have multiple users, when a user leaves, and on a user editing name
    // Perhaps this always expects a unique list of channel members
    let filteredMembers;
    try {
      filteredMembers = channelMembers.filter((user) => user.profile.id !== this.sender.id && user.state !== CHAT_STATES.left);
    }
    catch (error) {
      console.warn(`displayname error: ${error}`);
    }
    const generatedName = filteredMembers.map((user) => `${user.profile.first_name} ${user.profile.last_name}`).join(', ');

    return generatedName;
  }

  async getChannelDisplayName(channelId) {
    // TODO: this needs to be called on joining a room or on room creation
    if (this.chatChannels[channelId]?.displayName) return this.chatChannels[channelId]?.displayName;
    const channelMembers = this.chatChannels[channelId]?.members;
    if (!channelMembers) return '';
    const currentMember = channelMembers.find(member => member.profile === this.sender.id);
    let channelName = currentMember ? currentMember?.channel_display_name : null;
    if (!channelName) {
      channelName = this.createChannelDisplayName(channelMembers);
    }
    // adds it on creation since it is missing and will be retrieved on the chatview
    if (this.chatChannels[channelId]) {
      this.chatChannels[channelId].displayName = channelName;
    }
    return channelName;
  }

  createActiveAndAllProfileLists = (memberList) => {
    const allMemberProfiles = memberList?.map((member) => {
      return {
        ...member.profile,
        state: member.state,
        name: `${member.profile.first_name} ${member.profile.last_name}`
      };
    });
    const memberProfilesInChat = memberList
      ?.filter(member => member.state !== CHAT_STATES.left)
      .map(member => ({
        ...member.profile,
        name: `${member.profile.first_name} ${member.profile.last_name}`
    }));
    
    return {
      allMemberProfiles,
      memberProfilesInChat
    }
  }

  async getAndStoreChannels() {
    let channelInfo;
    try {
      channelInfo = await rwbApi.getUserChannelInfo(this.sender.id);
    } catch (error) {
      // TODO: figure out what to do when failing to retrieve these
      this.chatChannels = [];
      return [];
    }
    let chatChannels = await rwbApi.getAllChatChannels();
    for (const channel of chatChannels || []) {
      if (!this.chatChannels[channel.id]) {
        try {
          // Initialize new channel data
          const chatMembers = channel.members.records;
          // TODO: will want to separate this by active or create a helper function for active members only for tagging/mentions and default group chat name
          let hasCustomDisplayName = false;
          let currentUserChannelDisplayName = (chatMembers.find((user) => user.profile.id === this.sender.id))?.channel_display_name;
          if (!currentUserChannelDisplayName) {
            currentUserChannelDisplayName = this.createChannelDisplayName(chatMembers);
          } else {
            hasCustomDisplayName = true;
          }
          const specificChannelInfo = channelInfo.find((info) => info.channel_id === channel.id);
          channel.members = chatMembers; // members with their state
          const {allMemberProfiles, memberProfilesInChat} = this.createActiveAndAllProfileLists(channel.members);
          channel.taggableMembers = memberProfilesInChat;
          channel.allMemberProfiles = allMemberProfiles;
          channel.displayName = currentUserChannelDisplayName;
          channel.state = specificChannelInfo.state;
          // compare the db message indexes if they exist and then make sure the user has seen any messages and that there is a message in the chat
          channel.hasUnreadMessage = (specificChannelInfo?.last_seen_message?.id < channel?.latest_message?.id) || (specificChannelInfo?.last_seen_message === null && channel?.latest_message?.id); // specifically for our db so compare incremented ids instead of xid
          channel.hasCustomDisplayName = hasCustomDisplayName;
          this.chatChannels[channel.id] = channel;
        } catch (error) {
          console.error(`Failed to fetch members for channel ID: ${channel.id}`, error);
        }
      }
    }
    channelInfo.forEach((channelState) => {
      if (this.chatChannels[channelState.channel_id]) {
        this.chatChannels[channelState.channel_id].state = channelState.state;
      }
      // can add role here later out of mvp
    });

    // inject channel member state into channel.state
    for (const channel of chatChannels || []) {
      if (!channel.state) {
        channel.state = channel.members.records.find((user) => user.profile.id === this.sender.id).state;
      }
    }

    this.notifySubscribers();
    return chatChannels;
  }

  async getChatChannels() {
    await this.initPromise;
    return this.chatChannels;
  }

  async updateLastRead(channelId, message) {
    if (!this.chatChannels[channelId]) {
      console.warn(`Channel ${channelId} not found in stored channels.`);
      return;
    }
    this.chatChannels[channelId].lastSender = {
      name: message.sender?.name || 'Unknown',
      id: message.sender?.id || 0,
      profile_photo_url: message.sender?.profile_photo_url || '',
    };
    this.chatChannels[channelId].message = {
      text: message.content || '',
      media: message.media || [],
      status: message.status || 'delivered',
    };
  }

  async updateLatestReceived(channelId, message) {
    if (!this.chatChannels[channelId]) return;
    if (message?.attributes?.previousMessageId) return; // do not update the last message to be edited messages or reactions
    // TODO: extract id, members, displayName, and name. Maybe remove name as it is not used
    // additionally, update the docs for what all is in the chatChannels. We should not retype this everywhere, the todo is noted here but should be done in all places to avoid issues and better documentation to see what to expect
    const channel = {
      ...this.chatChannels[channelId],
      latest_message: {
        created: message.sendTime,
        sender: { ...message.sender.attributes, id: parseInt(message.sender.userId) },
        text: message.text,
        images: message.images,
        id: message.id, // our server returns id here, not xid
      }
    }
    this.chatChannels[channelId] = channel;
  }

  async getAllLastSentMessages() {
    await this.init();
    return this.chatChannels;
  }

  async logout() {
    try {
      // Leave all active chat rooms
      for (const channelId in this.activeRooms) {
        const channel = this.activeRooms[channelId];
        if (channel && channel.leave) {
          await channel.leave();
        }
      }
      this.stopChannelPolling();
      this.activeRooms = {};
      this.chatChannels = {};
      this.initPromise = null;

      this.sender = null;
    } catch (error) {
      console.error("Error while logging out:", error);
    }
  }

  async editMessage(channelId, newContent) {
    // edit in ivs
    const messageResponseType = await this.sendMessage(channelId, newContent);

    if(messageResponseType !== MESSAGE_STATUSES.failure) {
      rwbApi.updateMessageInDb(newContent.editInfo.messageId, { text: newContent.text, media: [], reply_message_id: null, open_graph: newContent.open_graph });
    }

    return messageResponseType;
  }

  async modifyEmoji(channelId, messageWithEmoji, emoji, isRemovingEmoji) {
    const messageResponseType = await this.sendMessage(channelId, messageWithEmoji);
    if (messageResponseType !== MESSAGE_STATUSES.failure) {
      rwbApi.toggleEmojiToMessage(messageWithEmoji.emojiInfo.messageId, emoji, isRemovingEmoji);
    }

    return messageResponseType;
  }

  async deleteMessage(channelId, messageId) {
    const request = new DeleteMessageRequest(messageId);
    const room = this.activeRooms[channelId];
    // edit in ivs/current user's chat view
    await room.deleteMessage(request);
    rwbApi.removeMessageInDb(messageId);
  }

  async editGroupChatName(channelId, newName) {
    try {
      rwbApi.updateGroupChatName(channelId, this.sender.id, newName);

      if (this.chatChannels[channelId]) {
        this.chatChannels[channelId].displayName = newName;
        this.chatChannels[channelId].hasCustomDisplayName = true;
      }
    } catch (error) {
      console.error("Error updating group chat name:", error);
    }
  }

  async updateMemberChatState(channelId, newState) {
    try {
      rwbApi.updateMemberChatValue(channelId, this.sender.id, {state: newState});

      if (this.chatChannels[channelId]) {
        this.chatChannels[channelId].state = newState;
      }
    } catch (error) {
      console.error("Error updating member chat state:", error);
    }
  }

  // because ivs and our db have different message id schemas, use ivs message id to ensure we have the same id
  async reportMessage(messageId, reportReason, additionalText) {
    rwbApi.reportMessage(messageId, reportReason, additionalText);
  }

  async reportChannel(channelId, reportReason, additionalText) {
    rwbApi.reportChannel(channelId, reportReason, additionalText);
  }

}

const chatManager = new ChatManager();
export default chatManager;
