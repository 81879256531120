import React, {Component} from 'react';
import AggregateFeedList from './AggregateFeedList';
import SearchBar from '../SearchBar';
import styles from './Feed.module.css';
import CreatePostButton from './CreatePostButton';
import {Switch, Route, withRouter} from 'react-router-dom';
import {userProfile} from '../../../../shared/models/UserProfile';
import {rwbApi} from '../../../../shared/apis/api';
import Loading from '../Loading';
import {Grid} from '@material-ui/core';
import PostView from './PostView';
import UsersList from './UsersList';
import debounce from 'lodash.debounce';
import {hasReachedBottom} from '../../BrowserUtil';
import {USER_SEARCH_ERROR} from '../../../../shared/constants/ErrorMessages';
import {FEED_INSTRUCTIONS} from '../../../../shared/constants/OtherMessages';
import AggregateFeedListItem from './AggregateFeedListItem';
import ReactionView from './ReactionView';
import MyProfileIcon from '../svgs/MyProfileIcon';
const pJSON = require('../../../package.json');

const DEBOUNCE_MS = 500;

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingMore: false,
      loadingUsers: false,
      search: '',
      userFeed: [],
      retrievedLastPost: false,
      usersList: [],
      pinnedPost: [],
    };
    this.incompleteSections = [];
    this.user = userProfile.getUserProfile();
  }

  componentDidMount() {

    const newAccount = this.props.location.state?.newAccount;
    this.getFeed();
    // always update web_version on launch
    // only modify saved_version after onboarding
    rwbApi.updateAppVersion(pJSON.version, 'web');
    window.addEventListener('scroll', this.trackScrolling);

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = (event) => {
    event.preventDefault();
    // if (this.props.location.pathname !== '/feed') return;

    const wrappedElement = document.getElementById('root');
    // when switching to the tab the bottom is considered reached, but there are no posts
    // this puts the app trying to load more when it should not be
    // on start loading more when the bottom is reached AND there is some data
    const onFeedTab = window.location.pathname === '/feed'; // this prevents loading more while on a post view and scrolling to the bottom
    if (
      hasReachedBottom(wrappedElement) &&
      this.state.userFeed.length > 0 &&
      onFeedTab
    ) {
      this.setState({retrievedLastPost: true}, this.handleLoadMore);
      window.removeEventListener('scroll', this.trackScrolling);
    }
  };

  getFeed = () => {
    this.setState({isLoading: true});
    // prepending the most recent result mixes up types, text, and reactions
    // to avoid that, clear out everything and reload everything
    const currentFeed = this.state.userFeed;
    this.setState({userFeed: []});
    Promise.all([this.getPinnedPost(), this.getTimeline()])
      .then(([pinnedPost, timeline]) => {
        this.setState({
          pinnedPost: pinnedPost?.data?.results,
          userFeed: timeline.data.results,
          isLoading: false,
        });
      })
      .catch((error) => {
        alert('Team RWB: There was an error retrieving your feed.');
        this.setState({isLoading: false, userFeed: currentFeed});
      });
  };

  getTimeline = () => {
    return rwbApi.getTimelineFeed().then((timeline) => {
      return timeline;
    });
  };

  getPinnedPost = () => {
    return rwbApi.getPinnedPost().then((pinnedPost) => {
      return pinnedPost;
    });
  };

  handleLoadMore = () => {
    // only try to retrieve more from the feed if it is not already retrieving
    const posts = this.state.userFeed;
    const lastPost = posts[posts.length - 1];
    this.setState({isLoadingMore: true});
    //lastPost check needed because window listener is active on all pages
    if (this.state.retrievedLastPost && lastPost) {
      rwbApi.getTimelineFeed(lastPost.id).then((result) => {
        if (result.data.results.length > 0) {
          this.setState({
            userFeed: [...this.state.userFeed, ...result.data.results],
            isLoadingMore: false,
            retrievedLastPost: false,
          });
          window.addEventListener('scroll', this.trackScrolling);
        } else this.setState({isLoadingMore: false});
      });
    }
  };

  searchUsers = (text) => {
    rwbApi
      .searchUser(text)
      .then((result) => {
        this.setState({usersList: result, loadingUsers: false});
      })
      .catch(() => {
        alert(`Team RWB: ${USER_SEARCH_ERROR}`);
        this.setState({usersList: [], loadingUsers: false});
      });
  };

  debouncedSearchUsers = debounce(this.searchUsers, DEBOUNCE_MS);

  // display search text and indicate to the user that data is loading
  searchUserHandler = (text) => {
    this.setState({search: text, loadingUsers: true});
    this.debouncedSearchUsers(text);
  };

  mergeNewPost = () => this.getFeed();

  render() {
    const {
      search,
      loadingUsers,
      isLoading,
      userFeed,
      isLoadingMore,
      usersList,
      pinnedPost,
    } = this.state;

    const {history} = this.props;

    return (
      <div className={styles.container} id={'root'}>
        {isLoading && (
          <Loading size={100} color={'var(--white)'} loading={true} right />
        )}
        <div>
          <Switch>
            <Route path={`${this.props.match.path}/:postId/user/:userId`} exact>
              <PostView />
            </Route>
            <Route
              path={`${this.props.match.path}/:postId/comment/:commentId/:reactionType`}
              exact>
              <ReactionView />
            </Route>
            <Route
              path={`${this.props.match.path}/:postId/:reactionType`}
              exact>
              <ReactionView />
            </Route>
            <Route path={this.props.match.path}>
              <div className={styles.searchContainer}>
                <button
                  style={{marginRight: 15}}
                  onClick={() => {
                    history.push('/profile');
                    // TODO analytics
                  }}
                  className={styles.myProfileButton}>
                  <MyProfileIcon filledIcon={false}/>
                </button>
                <SearchBar
                  placeholder={'Search for People'}
                  value={search}
                  onChange={this.searchUserHandler}
                  onSubmit={() => {}}
                  onClearSearch={() => this.setState({search: ''})}
                />
              </div>

              {search !== '' ? (
                <UsersList
                  usersSuggestions={usersList}
                  loadingUsers={loadingUsers}
                  onSelect={(user) =>
                    this.props.history.push(`/profile/${user.id}`)
                  }
                />
              ) : (
                <>
                  {pinnedPost !== undefined && pinnedPost?.length > 0 ? (
                    <AggregateFeedListItem
                      data={pinnedPost[0]}
                      type={pinnedPost[0].activities[0].object.split(':')[0]}
                      key={'pinned'}
                      origin={origin}
                      mergeNewPost={() => null}
                    />
                  ) : null}
                  {userFeed.length > 0 && (
                    <>
                      {userFeed.length > 0 && (
                        <AggregateFeedList
                          pinnedPost={pinnedPost}
                          userFeed={userFeed}
                          origin={'user'}
                          mergeNewPost={this.mergeNewPost}
                        />
                      )}
                      {isLoadingMore && (
                        <Loading
                          size={60}
                          color={'var(--grey20)'}
                          loading={true}
                          footerLoading
                        />
                      )}
                    </>
                  )}

                  {userFeed.length === 0 &&
                    pinnedPost?.length === 0 &&
                    !isLoading && (
                      <Grid container justifyContent={'center'}>
                        <p className={styles.emptyListText}>
                          {FEED_INSTRUCTIONS}
                        </p>
                      </Grid>
                    )}

                  {!this.user.anonymous_profile && (
                    <CreatePostButton
                      type={'user'}
                      mergeNewPost={this.mergeNewPost}
                    />
                  )}
                </>
              )}
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Feed);
