import DefaultProfileImg from '../images/DefaultProfileImg.png';
import moment from 'moment';
import chatManager from '../models/ChatManager';
import { userProfile } from '../models/UserProfile';

export const messageFormatter = (data, styles, sentByCurrentUser) => {
    const {sender, status} = data;
    const imageSource = sender?.profile_photo_url
      ? {uri: sender?.profile_photo_url}
      : DefaultProfileImg;
  
    const containerStyling = sentByCurrentUser
      ? status === MESSAGE_STATUSES.failure
        ? styles.failedSendContainer
        : status === MESSAGE_STATUSES.pending
          ? styles.pendingContainer
          : styles.sentContainer
      : styles.receivedContainer;
    const messageStyling = sentByCurrentUser
      ? status === MESSAGE_STATUSES.failure
        ? styles.failedSendText
        : styles.sentText
      : styles.receivedText;

    return {
        imageSource,
        containerStyling,
        messageStyling,
    }
}

export const formatReportReason = (reason) => {
    return {
        "Spam": "spam",
        "Harassment": "harassment",
        "Inappropriate Content": "inappropriate_content"
    }[reason] || reason;
}

// TODO: Unsure if there will be a success case or what the fail to send from server will look like
// might change with read receipts
export const MESSAGE_STATUSES = {
    success: 'success',
    failure: 'failure',
    pending: 'pending'
}

export const createPendingXID = () => {
    return `temp-${Date.now()}`;
}

export const messageTimeFormatter = (time) => {
    time = moment(time);
    const now = moment();

    if (time.isSame(now, 'day')) {
        return time.format('h:mm a');
    }
    else if (time.isSame(now, 'week')) {
        return time.format('dddd');
    }
    else {
        return time.format('MM/DD/YYYY');
    }
}

export const formatChatPreviewMessage = (lastMessage, lastSenderName) => {
    // NOTE: If we are previewing the image in the chat list item, that component will handle images instead and this will just be preview message/blank message
    let text = 'This chat is empty';
    const imageArray = lastMessage?.images || lastMessage?.media;

    if(lastSenderName === "Blocked") text = `${lastSenderName}: (Blocked Message)`;
    else if (lastMessage?.text) text = `${lastSenderName}: ${lastMessage?.text}`;
    else if (imageArray?.length > 0) {
        text = `${lastSenderName}: ${imageArray?.length} image${imageArray?.length === 1 ? '' : 's'}`
    }
    return text;
}

export const formatSenderName = (message, members, isBlockedMessage = false) => {
    const sender = message?.sender || members.find((user) => {
        return user?.profile?.id === message?.profile_id;
    })?.profile;

    const senderName = !sender || sender.anonymous_profile
        ? "Deleted"
        : userProfile?.blockedMap?.has(sender.id) && isBlockedMessage
            ? "Blocked"
            : `${sender.first_name} ${sender.last_name}`;

    return senderName;
}

export const generateTraceline = (currentId, replySenderId, messageSenderId, styles) => {
    // vertical lines should be the same, text direction is handled by parent
    if (replySenderId === messageSenderId && replySenderId === currentId || messageSenderId !== currentId && replySenderId !== currentId)
        return styles.vertical;
    else if (currentId === messageSenderId)
        return styles.downRight;
    else
        return styles.leftUp;
}

export const shouldShowDateHeader = (messages, index, inverted=false) => {
    
    if ((!inverted && index === 0) || (inverted && index === messages.length - 1)) {
        return true;
      }
    
      const adjacentIndex = inverted ? index + 1 : index - 1;
      const currentDate = moment(messages[index].created);
      const adjacentDate = moment(messages[adjacentIndex].created);
    
      return !currentDate.isSame(adjacentDate, 'day');
}

export const formatDateHeader = (date) => {
    return moment(date).format('ddd, MMM D');
}

// adds sender information from member list and if the message is blocked based off the user list
export const injectAdditionalMessageInfo = (messages, channelId, curUser) => {
    const messagesWithSenders = messages.map((message) => {
        // TODO: backend change not yet made
        if (!message.sender) {
          message.sender = chatManager.getSenderInfo(message.profile_id, channelId);
        }
        if (message?.reply_message && !message.reply_message?.sender?.profile_id) {
          message.reply_message.sender = chatManager.getSenderInfo(message.reply_message.profile_id, channelId);
        }
        message.blocked = curUser?.blockedMap?.has(message?.profile_id || message?.sender?.id);
        return message;
    });
    return messagesWithSenders;
}

// remove the users whose names match part of the filter and highlight the match(s) and display them
export const extractMatchingMembersAsString = (users, matchingText) => {
    if (!users) return '';
    let allNamesString = '';
    users.forEach((user) => {
        if (user?.first_name?.toLowerCase()?.includes(matchingText?.toLowerCase()) || user?.last_name?.toLowerCase()?.includes(matchingText?.toLowerCase())) {
            allNamesString += `${user.first_name} ${user.last_name}, `;
        }
    });
    if (allNamesString.slice(-2) === ', ') {
        allNamesString = allNamesString.slice(0, -2);
    }
    return allNamesString;
}

// moves blocked members to the end of the array
export const reorderBlockedMembers = (membersArray) => {
    const blockedMap = userProfile?.blockedMap ?? new Set();
    const nonBlocked = membersArray.filter(m => !blockedMap.has(m.id));
    const blocked = membersArray.filter(m => blockedMap.has(m.id));
    return [...nonBlocked, ...blocked];
};

export const generateEmojiDisplayNames = (chatMembers, emojiMap) => {
    let emojiMessages = {};

    Object.keys(emojiMap).forEach((emoji) => {
        emojiMap[emoji].forEach((userId) => {
            let foundUser = chatMembers?.find((user) => user.id === userId);
            if (!foundUser) foundUser = {name: 'Deleted'};
            else if (foundUser && userProfile?.blockedMap?.has(foundUser?.id)) foundUser = {name: 'Blocked'}
            if (!emojiMessages[emoji]) emojiMessages[emoji] = {message: ''};
            if (emojiMessages[emoji].message) emojiMessages[emoji].message = `${emojiMessages[emoji].message}, ${foundUser.name}`;
            else emojiMessages[emoji].message = foundUser.name;
        });
    });
    return emojiMessages;
}

export const editMessagesButton = ({curUser, chats, hasAnActiveChat, isLoading, editMode, selectedChats, setConfirmRemoval, editChats}) => {
    if(curUser.anonymous_profile)
      return null;

    let text;
    let onPress;
    let enabled;
    if (!chats.length || !hasAnActiveChat || isLoading) {
      text = 'Edit';
      enabled = false;
      onPress = () => {};
    } else if (editMode && selectedChats.length) {
      text = 'Remove';
      enabled = true;
      onPress = () => {
        setConfirmRemoval(true);
      };
    } else if (editMode && selectedChats.length === 0) {
      text = 'Remove';
      enabled = false;
      onPress = () => {};
    } else {
      text = 'Edit';
      enabled = true;
      onPress = editChats;
    }

    return {text, enabled, onPress};
}
