import React from 'react';
import {getHighlightedTokens} from '../../../../shared/utils/Helpers';
import {extractMatchingMembersAsString} from '../../../../shared/utils/MessagesHelpers';
import styles from './MessageSearchResults.module.css';
import {
  useHistory,
} from 'react-router-dom';

const renderSearchedText = (text, searchValue) => {
  const tokens = getHighlightedTokens(text, searchValue);
  return tokens?.map((token) =>
    token?.highlight ? (
      <h3 key={token.key} style={{margin: 0}}>
        {token.text}
      </h3>
    ) : (
      <span key={token.key}>{token.text}</span>
    ),
  );
};

// Usage in your render function
const SearchedChatChannel = ({chatChannel, searchValue, formattedTime,}) => {
  const history = useHistory();
  return (
  <div onClick={() => history.push(`/messages/chats/${chatChannel.id}`)} className={styles.searchedChatContainer}>
    {/* display is returned from the server, is that even needed? */}
    <div className={styles.nameAndTime}>
      <div className={styles.searchedChatNameContainer}>
        <p>{chatChannel?.displayName}</p>
        
      </div>
      <div className={styles.searchedChatTime}>
        <p>{formattedTime}</p>
      </div>
    </div>
    {/* only search for users who have not left and are not the current user */}
    <p>
      {renderSearchedText(
        extractMatchingMembersAsString(
          chatChannel?.taggableMembers,
          searchValue,
        ),
        searchValue,
      )}
    </p>
  </div>
  )
}

export default SearchedChatChannel;
