import React, { useState, useEffect } from 'react';
import styles from './FollowButton.module.css';
import FollowingIcon from './svgs/FollowingIcon';
import FollowIcon from './svgs/FollowIcon';
import Loading from './Loading';
import { userProfile } from '../../../shared/models/UserProfile';
import { rwbApi } from '../../../shared/apis/api';
import { EXECUTION_STATUS, logFollow, logUnfollow, webSectionName } from '../../../shared/models/Analytics';


const FollowButton = ({ user, followersAmount, setFollowersAmount, type }) => {
    const [followingSet, setFollowingSet] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
    setFollowingSet(new Set(userProfile.getUserProfile().followingSet));
    }, [userProfile]);

  const updateFollowState = () => {
    setIsLoading(true);
    const newFollowingSet = new Set(followingSet);
    let analyticsObj = {
      profile_id: `${user.id}`,
      follow_location: type,
      click_text: isFollowing ? 'Unfollow' : 'Follow',
      section_name: webSectionName(),
    };
    if (newFollowingSet.has(user.id)) {
      rwbApi
        .unfollowUser(user.id)
        .then((result) => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          newFollowingSet.delete(user.id);
          setFollowingSet(new Set(newFollowingSet));
          userProfile.removeFollowingSet(user.id);
          if (setFollowersAmount) {
            setFollowersAmount(followersAmount - 1);
          }
        })
        .catch((error) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
          logUnfollow(analyticsObj);
        });
    } else {
      rwbApi
        .followUser(user.id)
        .then((result) => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          newFollowingSet.add(result.followed_id);
          setFollowingSet(new Set(newFollowingSet));
          userProfile.addFollowingSet(result.followed_id);
          if (setFollowersAmount) {
            setFollowersAmount(followersAmount + 1);
          }
        })
        .catch((error) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          console.error(error);
        })
        .finally(() => {
          logFollow(analyticsObj);
          setIsLoading(false);
        });
    }
  };

  if (user?.anonymous_profile || userProfile.getUserProfile().id === user.id) {
    return null;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    updateFollowState(); // Called with no need for user as it's already scoped to the component
  };

    const isFollowing = followingSet.has(user.id);

return (
    <div
        className={`${type === 'userCard' ? styles.smallFollowButton : styles.followButton}`}
        onClick={handleClick}>
        <div>{isLoading ? (
            <Loading
                size={type === 'userCard' ? 30 : 60}
                color={'var(--white)'}
                loading={true}
                footerLoading
            />
            ) : (
                <div className={styles.followIconContainer}>
                  {isFollowing ? (
                        <>
                          <FollowingIcon />
                          <h4 style={{color: 'var(--navy)'}}>UNFOLLOW</h4>
                        </>
                    ) : (
                        <>
                          <FollowIcon />
                          <h4 style={{color: 'var(--navy)'}}>FOLLOW</h4>
                        </>
                    )}
                </div>
            )}
        </div>
    </div>
);
};

export default FollowButton;
