import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { useHistory } from 'react-router-dom';
import styles from './ChatSettings.module.css';
import UsersList from '../feed/UsersList';
import chatManager from '../../../../shared/models/ChatManager';
import { MAX_ADDED_CHAT_MEMBERS } from '../../../../shared/constants/Restrictions';
import LeaveChatIcon from '../svgs/LeaveChatIcon';
import ReportChatIcon from '../svgs/ReportChatIcon';
import EditIcon from '../svgs/EditIcon';
import errorAlert from '../../../../shared/utils/ErrorAlert';
import { formatReportReason } from '../../../../shared/utils/MessagesHelpers';
import RWBButton from '../RWBButton';
import ReportChatModal from './ReportChatModal.react.js';
import ChangeGroupNameModal from './ChangeGroupNameModal.react.js';
import RWBModal from '../generic/RWBModal';
import ChatCreation from './ChatCreation.react';
import { userProfile } from '../../../../shared/models/UserProfile';
import Loading from '../Loading';
import { rwbApi } from '../../../../shared/apis/api.js';
import { CHAT_STATES } from '../../../../shared/constants/MessagesKeys.js';

const ChatSettings = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [displayName, setDisplayName] = useState(history?.location?.state?.displayName || null);
    const [groupMemberProfiles, setGroupMemberProfiles] = useState([]);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showConfirmReportModal, setShowConfirmReportModal] = useState(false);
    const [showChangeGroupNameModal, setShowChangeGroupNameModal] = useState(false);
    const [showAddMembers, setShowAddMembers] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const [chatChannel, setChatChannel] = useState({});
    const curUser = userProfile.getUserProfile();

    const match = window.location.href.match(/\/chats\/(\d+)\/settings/);
    const channelId = match ? match[1] : null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const chatChannel = (await chatManager.getChatChannels())[channelId];
                setChatChannel(chatChannel);
                const groupMembers = chatChannel?.members
                    ? chatChannel.members.filter(member => member.state !== CHAT_STATES.left).map(member => member.profile)
                    : [];
                
                setIsGroup(chatChannel.group_chat);
                setGroupMemberProfiles(groupMembers);
                // could improve this by finding the current user in the group members and checking there channel_display_name
                if (!displayName) {
                    const chatDisplayName = await chatManager.getChannelDisplayName(channelId);
                    setDisplayName(chatDisplayName);
                }

            } catch (error) {
                console.error("Error fetching chat data:", error);
                errorAlert("Failed to load the chat. Please try again.");
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [channelId]);

    const handleLeaveGroup = async () => {
        const confirmLeave = window.confirm("Are you sure you want to leave this chat?");
        if (confirmLeave) {
            try {
                await chatManager.leaveRoom(channelId);
                history.push('/messages');
            } catch (error) {
                console.error("Error leaving chat:", error);
                errorAlert("Failed to leave the chat. Please try again.");
            }
        }
    };

    const handleUpdateGroupName = async (newName) => {
        if (!newName.trim()) return;
    
        try {
            await chatManager.editGroupChatName(channelId, newName);
            setDisplayName(newName);
            setShowChangeGroupNameModal(false);
        } catch (error) {
            console.error("Error updating group name:", error);
            errorAlert("Failed to update the group name. Please try again.");
        }
    };

    const handleReportSubmit = async (reportReason, reportDetails) => {
        if (!reportReason) return;
    
        try {    
            if (!channelId) {
                errorAlert("Failed to retrieve channel ID.");
                return;
            }
            const formattedReason = formatReportReason(reportReason);
            await chatManager.reportChannel(channelId, formattedReason, reportDetails);
            setShowConfirmReportModal(false);
        } catch (error) {
            console.error("Error submitting report:", error);
            errorAlert("Failed to submit the report. Please try again.");
        }
    };

    // always user what is in this chat channel for consistent display
    const handleAddUsers = () => {
        if (!chatChannel.hasCustomDisplayName) {
            setDisplayName(chatChannel?.displayName);
        }
        setGroupMemberProfiles(chatChannel.members.map((user) => user.profile));
    }

    const unBlockUser = async (targetUserId) => {
        try {
            await rwbApi.unblockUser(targetUserId);
            userProfile.removeBlockingMapUser(targetUserId);
            setGroupMemberProfiles((prevMembers) => [...prevMembers]);

        } catch (error) {
            console.error('unblock error:', error);
            errorAlert("There was a problem unblocking the user. Please try again.");
        }
    };
    

    return (
        <div>
            <Loading size={100} color={'var(--white)'} right={true} loading={isLoading}/>
            <Header onBack={history.goBack} title="Chat Settings" />
            <div className={styles.contentContainer}>
                {isGroup && (
                    <div className={styles.groupTextContainer}>
                        <span className={styles.groupNameText}>{displayName}</span>
                        <div className={styles.changeGroupNameContainer} onClick={() => setShowChangeGroupNameModal(true)}>
                            <EditIcon />
                            <span className={styles.changeGroupNameText}>Change Group Name</span>
                        </div>
                    </div>
                )}

                <div className={styles.buttonContainer}>
                    {isGroup && (
                        <RWBButton
                            buttonStyle="secondary"
                            label={
                                <div className={styles.iconButtonContent}>
                                    <LeaveChatIcon filledIcon={false} height={24} width={24} />
                                    <span className={styles.buttonText}>Leave Group Chat</span>
                                </div>
                            }
                            onClick={handleLeaveGroup}
                            customStyles={{ width: '100%' }}
                        />
                    )}
                    <RWBButton
                        buttonStyle="secondary"
                        label={
                            <div className={styles.iconButtonContent}>
                                <ReportChatIcon filledIcon={false} height={24} width={24} />
                                <span className={styles.buttonText}>{`Report ${isGroup ? 'Group ' : ''}Chat`}</span>
                            </div>
                        }
                        onClick={() => setShowReportModal(true)}
                        customStyles={{ width: '100%' }}
                    />
                </div>

                {isGroup && (
                    <div>
                        <p className={styles.chatMemberLabel}>
                            CHAT MEMBERS ({groupMemberProfiles.length}/{MAX_ADDED_CHAT_MEMBERS + 1})
                        </p>
                        <RWBButton
                            buttonStyle="primary"
                            label="+ ADD MEMBER"
                            onClick={() => setShowAddMembers(true)}
                        />
                        <UsersList
                            usersSuggestions={groupMemberProfiles}
                            loadingUsers={false}
                            canFollow={true}
                            canReport={true}
                            canBlock={true}
                            onUnblock={unBlockUser}
                        />
                    </div>
                )}
            </div>

            <ReportChatModal
                showReportModal={showReportModal}
                setShowReportModal={setShowReportModal}
                showConfirmReportModal={showConfirmReportModal}
                setShowConfirmReportModal={setShowConfirmReportModal}
                handleReportSubmit={handleReportSubmit}
            />

            <ChangeGroupNameModal
                showModal={showChangeGroupNameModal}
                setShowModal={setShowChangeGroupNameModal}
                currentGroupName={displayName}
                onUpdateGroupName={handleUpdateGroupName}
            />

            <RWBModal
                open={showAddMembers}
                closeModal={() => setShowAddMembers(false)}
                hasCancelButton={false}
                bottomSheet={true}
                customStyles={{height: '90dvh'}}
            >
                <ChatCreation
                    closeModal={() => {
                        setShowAddMembers(false);
                        handleAddUsers();
                    }}
                    currentMembers={groupMemberProfiles.filter(user => user.id !== curUser.id)}
                    channelId={channelId}
                />
            </RWBModal>
        </div>
    );
};

export default ChatSettings;
