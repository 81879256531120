import React, {useEffect, useState} from 'react';
import opengraphAPI from '../../../../shared/apis/opengraph-api-web';
import styles from './SitePreviewCard.module.css';
import messageStyles from '../messages/SitePreviewMessageCard.module.css';
import XIcon from '../svgs/XIcon';
import debounce from 'lodash.debounce';

import {Card, CardMedia, CardContent, IconButton} from '@material-ui/core';
import {
  getDomain,
  validURL,
  setOpenGraphData,
  isNewLink,
} from '../../../../shared/utils/Helpers';
import {handleLinkClick} from '../../../../shared/utils/HandleLinkClick';
import LinksIcon from '../svgs/LinksIcon';

const DEBOUNCE_MS = 1000;
const SitePreviewCard = (props) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const {link, onClose, cancelable, storePreviewData, graphData, type} = props;
  const newLink = isNewLink(graphData, link );
  useEffect(() => {
    if (type === 'message') {
      setIsMessage(true);
    };
    setIsLoading(true);
    if ((!graphData || newLink) && validURL(link)) {
      const debouncedOpenGraph = debounce(() => {
        opengraphAPI
          .get(link)
          .then((data) => {
            // if the site was unreachable for any reason the placeholder open graph data will be used which is missing the timestamp and all other information
            const validOpenGraphData = data.timestamp && (
              !data?.openGraph?.error ||
              !data?.hybridGraph?.error ||
              !data?.htmlInferred?.error
            );
            if (validOpenGraphData) {
              const openGraphData = setOpenGraphData(
                data?.openGraph,
                data?.hybridGraph,
                data?.htmlInferred,
                data?.url,
              );
              setData(openGraphData);
              if (storePreviewData) storePreviewData(openGraphData);
            } else {
              // fallback to default for rate limiting/site issues
              setData(data);
              if (storePreviewData) storePreviewData(data);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.warn('Error fetching site data', err);
          });
      }, DEBOUNCE_MS);
      debouncedOpenGraph();
      return () => debouncedOpenGraph.cancel();
    } else {
      setData(graphData);
      setIsLoading(false);
    }
  }, [link]);

  const openLink = (evt, url) => {
    evt.stopPropagation();
    evt.preventDefault();
    handleLinkClick(url);
  };

  return (
    <>
    {isLoading ?
    (
      <div>
        <h6>loading preview...</h6>
      </div>
    ) :
      data ? (
        <Card
          style={{backgroundColor: isMessage && 'var(--navy)'}}
          className={isMessage ? messageStyles.container : styles.cardContainer}
          onClick={(e) => openLink(e, link)}>
          {/* image_url used for backend stored image url */}
          {(data?.image || data?.image_url) ? <CardMedia
            component="img"
            className={isMessage ? messageStyles.imageContainer : styles.cardCover}
            src={data.image || data.image_url}
            alt="Page preview"
          /> : <LinksIcon className={styles.fallbackIcon} />
          }
          <CardContent style={{color: isMessage && 'var(--white)', flex: 1}} className={isMessage ? messageStyles.textContainer : styles.cardContent}>
            <div>
              <div className={isMessage ? `${messageStyles.textContent} ${messageStyles.bold}` : styles.siteTitle}>{data.title}</div>
              {data.description && (
                <p style={{color: isMessage && 'var(--white)'}} className={isMessage ? messageStyles.textContent : styles.siteDescription}>{data.description}</p>
              )}
            </div>
            <div className={isMessage ? `${messageStyles.textContent} ${messageStyles.bold} ${messageStyles.url}` : styles.siteLink}>{getDomain(data.url)}</div>
          </CardContent>
          {cancelable ? (
            <div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                className={styles.cancelButton}
                color="inherit">
                <XIcon tintColor="var(--magenta)" />
              </IconButton>
            </div>
          ) : null}
        </Card>
      ) : null}
    </>
  );
};

export default SitePreviewCard;
