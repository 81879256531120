import React, {useEffect, useState} from 'react';
import chatManager from '../../../../shared/models/ChatManager';
import {useLocation, useHistory} from 'react-router-dom';
import Message from './Message.react';
import {rwbApi} from '../../../../shared/apis/api';
import styles from './MessageSearchResults.module.css';
import {messageTimeFormatter} from '../../../../shared/utils/MessagesHelpers';
import Loading from '../Loading';
import Header from '../Header';
import SearchedChatChannel from './SearchedChatChannel.react';
import debounce from 'lodash.debounce';

const MessageSearchResultsPaginateView = () => {
  const {state} = useLocation();
  const history = useHistory();
  const {
    results: initialResults,
    nextUrl: initialNextUrl,
    searchValue,
    searchType,
    curUser,
  } = state || {};

  // Set up state using the initial data
  const [results, setResults] = useState(initialResults);
  const [nextUrl, setNextUrl] = useState(initialNextUrl);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Function to trigger loading more results
  const handleLoadMore = () => {
    if (!isLoadingMore && nextUrl) {
      setIsLoadingMore(true);
      const searchApiCall = searchType === 'Messages' ? rwbApi.searchMessages : rwbApi.searchChannels;
      searchApiCall(null, nextUrl)
        .then((results) => {
          setResults((prevResults) => [...prevResults, ...results.data]);
          setNextUrl(results.next);
        })
        .catch((err) => {
          console.warn('Error fetching more results: ', err);
        })
        .finally(() => {
          setIsLoadingMore(false);
        });
    }
  };

  // Set up an infinite scroll: call handleLoadMore when near bottom of page
  useEffect(() => {
    const debouncedOnScroll = debounce(() => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 50
      ) {
        handleLoadMore();
      }
    }, 500); // slight delay to prevent this firing every time, preventing rapid firing of handle load more
  
    window.addEventListener('scroll', debouncedOnScroll);
    return () => window.removeEventListener('scroll', debouncedOnScroll);
  }, [nextUrl, isLoadingMore]);

  return (
    <>
      <Header title={'Message'} onBack={history.goBack} />
      <div className={styles.container}>
        <h3 className={styles.sectionTitle}>{`Search for "${searchValue}" in ${searchType}`}</h3>
        <div>
          {results?.map((result) => {
            return (
              <div key={result.id}>
                {searchType === 'Messages' && (
                  <div className={styles.result} onClick={() => history.push(`/messages/chats/${result.channel_id}`, {
                    messageId: result?.xid, // not used right now, might need to be adjusted
                  })}>
                    <div className={styles.messageTitle}>
                      <p>
                        {
                          chatManager.chatChannels[
                            result.channel_id
                          ]?.displayName // Display chat name instead of member since member name is already displayed in message
                        }
                      </p>
                      {/* nothing about edited in design */}
                      <p>
                        {messageTimeFormatter(
                          result?.edit_time_stamp || result?.created,
                        )}
                      </p>
                    </div>
                    <Message
                      data={result}
                      curUser={curUser}
                      searchValue={searchValue}
                      chatMembers={
                        chatManager.chatChannels?.[result.channel_id]
                          ?.allMemberProfiles
                      }
                    />
                  </div>
                )}

                {searchType === 'Chats' && (
                  <SearchedChatChannel 
                    chatChannel={chatManager.chatChannels?.[result?.id]}
                    searchValue={searchValue}
                    formattedTime={messageTimeFormatter(result?.latest_message?.edited || result?.latest_message?.created)}
                  />
                )}
              </div>
            );
          })}
        </div>
        {isLoadingMore && <Loading footerLoading={true} />}
      </div>
    </>
  );
};

export default MessageSearchResultsPaginateView;
