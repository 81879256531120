import React from 'react';
import chatManager from '../../../../shared/models/ChatManager';
import { generateEmojiDisplayNames } from '../../../../shared/utils/MessagesHelpers';
import RWBModal from '../generic/RWBModal';
import styles from './Message.module.css';

const EmojiReactionSheet = ({emojiReactions, onRequestClose, channelId, curUser, sentByCurrentUser, containerStyling}) => {
    const allMembers = chatManager.chatChannels[channelId]?.allMemberProfiles;
    const emojiNameMap = generateEmojiDisplayNames(allMembers, emojiReactions);
    return (
        <RWBModal
            open={true}
            closeModal={onRequestClose}
            hasCancelButton={false}
            bottomSheet={true}
        >
            {Object.keys(emojiReactions)?.map((emoji) => {
                return (
                    <div key={`emoji-${emoji}`} style={{display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center', marginTop: 4}}>
                        <div className={`${styles.reaction} ${containerStyling}`}
                            style={{
                                backgroundColor: emojiReactions?.[emoji]?.includes(curUser.id) ? 'var(--blue)' : !sentByCurrentUser ? 'var(--grey5)' : 'var(--navy)',
                                height: 40,
                            }}
                        >
                            <p>{emoji}</p>
                            <p className={`formLabel`} style={{color: (sentByCurrentUser || emojiReactions?.[emoji]?.includes(curUser.id)) ? 'var(--grey5)' : 'var(--navy)'}}>
                                {emojiReactions[emoji]?.length}
                            </p>
                        </div>
                        <p>{emojiNameMap[emoji].message}</p>
                    </div>
                )
            })}
        </RWBModal>
    )
}

export default EmojiReactionSheet;
