import React from 'react';
import styles from './MessageComposer.module.css';
import GalleryIcon from '../svgs/GalleryIcon';
import { MAX_IMAGE_HEIGHTS, WEB_MIMES } from '../../../../shared/constants/ImageConstants';
import { MAX_MESSAGE_IMAGES } from '../../../../shared/constants/Restrictions';
import errorAlert from '../../../../shared/utils/ErrorAlert';

/**
 * 
 * @param {array[string]} messageImages - array of image uris to display and update
 * @param {function} updateImages - updates the parents list of images to display
 * @returns 
 */

const MessageImageAdder = ({messageImages, updateImages}) => {

    const addImageHandler = (e) => {
        const files = Array.from(e.target.files);
        const BASE_ALERT_MESSAGE = 'Maximum numbers of images for this message has been reached.';
        if (files?.length > MAX_MESSAGE_IMAGES) {
          errorAlert(`${BASE_ALERT_MESSAGE} Please ensure you have no more than ${MAX_MESSAGE_IMAGES} images selected.`);
          return false;
        } else if (files?.length + (messageImages?.length || 0) > MAX_MESSAGE_IMAGES) {
          errorAlert(BASE_ALERT_MESSAGE);
          return false;
        }
        let images = [];
        let imagesProcessed = 0;
        Array.from(files).forEach((file, index) => {
            const reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    images[index] = { image: reader.result, name: file.name, file };
                    imagesProcessed++;
                    if (imagesProcessed === files.length) updateImages([...messageImages, ...images]);
                };
                // reader.onloadstart = () => setIsLoadingImage(true);
                // reader.onloadend = () => {
                //   setIsLoadingImage(false);
                // };
                reader.onerror = (e) => {
                    imagesProcessed++;
                    if (imagesProcessed === files.length) updateImages([...messageImages, ...images]);
                    errorAlert(`One or more of the images selected could not be added.`);
                }
            }
        });
    };
    
     

    return (
        <label className={styles.attachPhotoButton} style={{ cursor: messageImages?.length >= MAX_MESSAGE_IMAGES ? 'default' : 'pointer' }}>
        {messageImages.length >= MAX_MESSAGE_IMAGES ? <GalleryIcon tintColor="var(--grey40)" height={26} width={26} /> : <GalleryIcon tintColor="var(--magenta)" height={26} width={26} /> }
            <input
                type="file"
                multiple="multiple" // this adds support for control/command click to add more
                files={messageImages?.map(img => img.file) || []}
                disabled={messageImages.length >= MAX_MESSAGE_IMAGES}
                accept={WEB_MIMES}
                className={styles.hideDefaultUpload}
                onChange={addImageHandler}
                onClick={(event) => {
                event.target.value = null; // this allows the same image to be selected if the user clicks the "X"
                }}
            />
        </label>
    )
}

export default MessageImageAdder;
